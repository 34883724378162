import {
  getEanCodes,
  removeEanCode,
  removeCoatingLayers,
} from '../../../../Api'

export const INITIAL_BLADE_FORM_START = 'INITIAL_BLADE_FORM_START'
export const INITIAL_BLADE_FORM_SUCCESS = 'INITIAL_BLADE_FORM_SUCCESS'
export const INITIAL_BLADE_FORM_FAIL = 'INITIAL_BLADE_FORM_FAIL'

export const FETCH_EANCODES_START = 'FETCH_EANCODES_START'
export const FETCH_EANCODES_SUCCESS = 'FETCH_EANCODES_SUCCESS'
export const FETCH_EANCODES_FAIL = 'FETCH_EANCODES_FAIL'

export const REMOVE_EANCODES_START = 'REMOVE_EANCODES_START'
export const REMOVE_EANCODES_SUCCESS = 'REMOVE_EANCODES_SUCCESS'
export const REMOVE_EANCODES_FAIL = 'REMOVE_EANCODES_FAIL'

export const REMOVE_LAYERS_START = 'REMOVE_LAYERS_START'
export const REMOVE_LAYERS_SUCCESS = 'REMOVE_LAYERS_SUCCESS'
export const REMOVE_LAYERS_FAIL = 'REMOVE_LAYERS_FAIL'

export const updateBlade = obj => dispatch => {
  if (!obj.netWidth) {
    obj.netWidth = ''
  }
  const modifiedArray = []
  if (obj.machineMaterials[0] && !isNaN(obj.machineMaterials[0].material)) {
    // isNaN is used, because if without it doesnt work
    obj.machineMaterials.forEach(element => {
      modifiedArray.push(element.material)
    })
    obj.machineMaterials = modifiedArray
  }
  dispatch({
    type: INITIAL_BLADE_FORM_SUCCESS,
    data: obj,
  })
}

export const fetchEanCodes = id => async dispatch => {
  dispatch({
    type: FETCH_EANCODES_START,
  })
  try {
    const result = await getEanCodes(id)
    return dispatch({
      type: FETCH_EANCODES_SUCCESS,
      payload: result,
    })
  } catch (error) {
    return dispatch({
      type: FETCH_EANCODES_FAIL,
      payload: error,
    })
  }
}

export const removeEan = id => async dispatch => {
  dispatch({
    type: REMOVE_EANCODES_START,
  })
  try {
    const result = await removeEanCode(id)
    return dispatch({
      type: REMOVE_EANCODES_SUCCESS,
      payload: result,
    })
  } catch (error) {
    return dispatch({
      type: REMOVE_EANCODES_FAIL,
      payload: error,
    })
  }
}

export const removeLayers = values => async dispatch => {
  dispatch({
    type: REMOVE_LAYERS_START,
  })
  try {
    const result = await removeCoatingLayers(values)
    return dispatch({
      type: REMOVE_LAYERS_SUCCESS,
      payload: result,
    })
  } catch (error) {
    return dispatch({
      type: REMOVE_LAYERS_FAIL,
      payload: error,
    })
  }
}
