import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Item from './CoilItem'
import ValueGrid from './valueGrid'
import BladeVisualisationLegend from './legend/BladeVisualisationLegend'
import { convertToInches, convertToMm } from '../../../utils/convertUoM'

function getSum(total, num) {
  return total + Number(num)
}
const BladeVisulation = props => {
  const {
    coilData,
    changeStripes,
    netWidth,
    uomPreview,
    machineUoM,
    isModalOpen,
  } = props
  const [stateCoilData, setStateCoilData] = useState({})

  const usePrevious = val => {
    const ref = useRef()
    useEffect(() => {
      ref.current = val
    })
    return ref.current
  }

  const coilId =
    coilData &&
    coilData.coilWithStripes &&
    coilData.coilWithStripes[0] &&
    coilData.coilWithStripes[0].coil.skid

  const prevProps = usePrevious({
    uomPreview,
    machineUoM,
    coilId,
    coilData,
  })

  useEffect(() => {
    if (prevProps && prevProps.coilId !== coilId) {
      setStateCoilData(coilData)
    }
  }, [coilData])

  const coilDataCopy = { ...coilData }

  useEffect(() => {
    if (
      (prevProps &&
        prevProps.uomPreview !== null &&
        prevProps.uomPreview !== uomPreview &&
        prevProps.machineUoM === machineUoM) ||
      (prevProps &&
        prevProps.coilId !== null &&
        prevProps.coilId !== coilId &&
        uomPreview !== machineUoM) ||
      isModalOpen === true
    ) {
      const convertedCoilData = {
        coilWithStripes:
          uomPreview !== machineUoM
            ? coilDataCopy &&
              coilDataCopy.coilWithStripes &&
              coilDataCopy.coilWithStripes.map(coil => {
                return {
                  ...coil,
                  coilStripes: coil.coilStripes.map(stripe => {
                    return {
                      ...stripe,
                      width:
                        uomPreview === 1
                          ? convertToInches(stripe.width)
                          : convertToMm(stripe.width),
                    }
                  }),
                }
              })
            : stateCoilData &&
              stateCoilData.coilWithStripes &&
              stateCoilData.coilWithStripes.map(coil => {
                return {
                  ...coil,
                  coilStripes: coil.coilStripes.map(stripe => {
                    return {
                      ...stripe,
                      width:
                        uomPreview === 1
                          ? convertToInches(stripe.width)
                          : convertToMm(stripe.width),
                    }
                  }),
                }
              }),
      }
      setStateCoilData(convertedCoilData)
    }
  }, [uomPreview, machineUoM, coilData])

  const finalCoilData = uomPreview !== machineUoM ? stateCoilData : coilData

  const array = []
  if (finalCoilData && finalCoilData.coilWithStripes) {
    const coilsIfObg = finalCoilData.coilWithStripes
    // repeats stripes x amount.
    coilsIfObg.forEach(item => {
      for (let i = 0; i < item.coil.amount; i++) {
        item.coilStripes.forEach(itemChild => {
          array.push(itemChild)
        })
      }
    })

    const totalArray = array.map(item => item.width)
    // Gets total width of blade
    const totalWidth = totalArray.reduce(getSum, 0)

    return (
      <div>
        <BladeVisualisationLegend netWidth={netWidth} />
        <div className="coilWprOuter">
          <div className="aSide">A</div>
          <div className="coilWpr dis-flex">
            <Item
              allStripesArray={array}
              totalWidth={totalWidth}
              changeStripes={changeStripes}
              uomPreview={uomPreview}
            />
          </div>
          <ValueGrid totalWidth={totalWidth} coilsIfObg={coilsIfObg} />
          <div className="bSide">B</div>
        </div>
      </div>
    )
  }
  return (
    <div className="coilWprOuter">
      <div className="aSide">A</div>
      <div className="coilWpr" />
      <div className="bSide">B</div>
    </div>
  )
}
BladeVisulation.defaultProps = {
  coilData: null,
  uomPreview: null,
  machineUoM: null,
  netWidth: null,
  isModalOpen: null,
}
BladeVisulation.propTypes = {
  coilData: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  netWidth: PropTypes.number,
  changeStripes: PropTypes.func.isRequired,
  uomPreview: PropTypes.number,
  machineUoM: PropTypes.number,
  isModalOpen: PropTypes.bool,
}
export default BladeVisulation
