import React from 'react'
import PropTypes from 'prop-types'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Checkbox extends React.Component {
  handleChange = () => {
    const { input } = this.props
    input.onChange(!input.value)
  }

  render() {
    const { input, disabled, type, label, onChange, name, checked } = this.props
    return (
      <div className="custom-checkboxContainer">
        <input
          {...input}
          type={type}
          name={name || (input && input.name)}
          checked={!!checked || !!(input && input.value)}
          onChange={onChange || this.handleChange}
          disabled={disabled}
        />
        <span className="custom-checkbox">
          {(checked || (input && input.value)) && (
            <FontAwesomeIcon
              icon={faCheck}
              size="xs"
              style={{ color: '#ffffff', padding: '0.1rem' }}
            />
          )}
        </span>
        <span className="custom-checkboxText">{label}</span>
      </div>
    )
  }
}

Checkbox.defaultProps = {
  checked: false,
  name: '',
  onChange: null,
  type: 'checkbox',
  input: null,
  label: '',
  disabled: false,
}

Checkbox.propTypes = {
  input: PropTypes.shape({}),
  type: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Checkbox
