/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AuthProvider from './AuthProvider'
import BladeTableAndNewBlade from './views/BladeTableAndNewBlade/BladeTableAndNewBlade'
import MachineManagement from './views/MachineManagement/MachineManagement'
import HistoryTabView from './features/HistoryTabView/containers'

import Footer from './features/footer'
import Header from './features/header/components'
import Can from './CanAccess'
import './styles/main.scss'

import {
  fetchUserRole as fetchUserRoleAction,
  dispatchUserAccess as dispatchUserAccessAction,
} from './AppAction'
import AppWideSpinner from './features/common/components/spinner/appWideSpinner/AppWideSpinner'

class App extends React.Component {
  componentDidMount() {
    const { accountInfo } = this.props
    const { dispatchUserAccess } = this.props
    if (accountInfo) {
      dispatchUserAccess(accountInfo)
    }
  }

  componentDidUpdate(prevProps) {
    const { accountInfo } = this.props
    const { dispatchUserAccess, fetchUserRole } = this.props
    if (accountInfo !== prevProps.accountInfo) {
      dispatchUserAccess(accountInfo)
      fetchUserRole()
    }
  }

  static getQueryParams(qs) {
    const qsVal = qs.split('+').join(' ')
    const params = {}
    let tokens
    const re = /[?&]?([^=]+)=([^&]*)/g
    while ((tokens = re.exec(qsVal))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
    }
    return params
  }

  render() {
    const {
      userLevel,
      showLoading,
      showMachineManagement,
      onSignOut,
    } = this.props

    let RenderApp
    if (showLoading) {
      RenderApp = <AppWideSpinner />
    } else {
      RenderApp = (
        <Can
          // eslint-disable-next-line jsx-a11y/aria-role
          role={userLevel}
          perform="home-page:visit"
          yes={() => (
            <React.Fragment>
              <div>
                <Header onSignOut={onSignOut} />
                <div className="appOuterWpr">
                  <div className="appInnerWpr">
                    <div className="appContent">
                      <ToastContainer />
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={() =>
                            showMachineManagement ? (
                              <Can
                                role={userLevel}
                                perform="machine-management:visit"
                                yes={() => <MachineManagement />}
                                no={() => (
                                  <div>Please go back to PG Blades View</div>
                                )}
                              />
                            ) : (
                              <div>
                                <BladeTableAndNewBlade />
                              </div>
                            )
                          }
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </React.Fragment>
          )}
        />
      )
    }
    const query = App.getQueryParams(document.location.search)
    return !query.history ? (
      RenderApp
    ) : (
      <HistoryTabView historyId={query.history} />
    )
  }
}

App.defaultProps = {
  userLevel: 0,
  accountInfo: null,
}

App.propTypes = {
  userLevel: PropTypes.number,
  fetchUserRole: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
  dispatchUserAccess: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  accountInfo: PropTypes.shape({
    accountIndentifier: PropTypes.string,
    environment: PropTypes.string,
    homeAccountIdentifier: PropTypes.string,
    idToken: PropTypes.shape({
      aio: PropTypes.string,
      aud: PropTypes.string,
      exp: PropTypes.number,
      iat: PropTypes.number,
      iss: PropTypes.string,
      name: PropTypes.string,
      nbf: PropTypes.number,
      nonce: PropTypes.string,
      oid: PropTypes.string,
      preferred_username: PropTypes.string,
      sub: PropTypes.string,
      tid: PropTypes.string,
      uti: PropTypes.string,
      ver: PropTypes.string,
    }),
    name: PropTypes.string,
    sid: PropTypes.oneOf([undefined, PropTypes.string]),
    userName: PropTypes.string,
  }),
}

const mapStateToProps = state => ({
  coil: state.coil,
  userName: state.AppUser.userName,
  userLevel: state.AppUser.userLevel,
  showLoading: state.AppUser.showLoading,
  showMachineManagement: state.MachineManagement.showMachineManagement,
  userRole:
    state.MachineManagement.roleLevel && state.MachineManagement.roleLevel,
  languageToSave: state.MachineManagement.language,
})
export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchUserRole: fetchUserRoleAction,
      dispatchUserAccess: dispatchUserAccessAction,
    }
  )(AuthProvider(App))
)
