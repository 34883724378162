import {
  CALCULATE_ADHESIVE_PERCENTAGE,
  CALCULATE_OVERALL_WIDTH,
} from './BladeVisualisationLegendAction'
import { CLEAR_DATA_TRUE } from '../../../machineManagmentForm/MachineManagementFormAction'

const initialState = {
  adhesivePercentage: null,
  overallWidth: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_ADHESIVE_PERCENTAGE:
      return {
        ...state,
        adhesivePercentage: action.payload,
      }
    case CALCULATE_OVERALL_WIDTH:
      return {
        ...state,
        overallWidth: action.payload,
      }
    case CLEAR_DATA_TRUE:
      return {
        ...state,
        adhesivePercentage: null,
        overallWidth: null,
      }
    default:
      return state
  }
}
