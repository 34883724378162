import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import WrapperModal from '../modal/WrapperModal'
import InnerModal from '../modal/innerModal/InnerModal'
import ModalBody from '../modal/modalBody/ModalBody'
import ModalHeader from '../modal/modalHeader/ModalHeader'
import ButtonWrapper from '../modal/buttonWrapper/ButtonWrapper'
import Button from '../button/Button'

const LogOutModal = props => {
  const { handleshowNewModal, onSignOut, t } = props
  return (
    <div>
      <WrapperModal>
        <InnerModal>
          <ModalHeader>Log out</ModalHeader>
          <ModalBody>Are you sure you want to log out of application</ModalBody>
          <ButtonWrapper>
            <Button
              onClick={() => onSignOut()}
              label="Log out"
              type="button"
              size="md"
              variant="outlined--primary"
            />
            <Button
              onClick={handleshowNewModal}
              label="Cancel"
              type="button"
              size="md"
              variant="outlined--tertiary"
            />
          </ButtonWrapper>
        </InnerModal>
      </WrapperModal>
    </div>
  )
}

LogOutModal.propTypes = {
  handleshowNewModal: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  //   t: PropTypes.func.isRequired,
}

export default LogOutModal
