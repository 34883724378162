/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import { withTranslation } from 'react-i18next'
import { formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import Checkbox from '../../checkbox/CheckBox'
import totalWidthWithSlittingAction from '../../../../bladeDrawing/bladeDrawingAction'

class PgBladeModalContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedItems: new Map(),
    }
  }

  componentDidMount() {
    const { polarity } = this.props
    const isChecked = polarity === 1
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set('horizontal', isChecked),
    }))
  }

  handleCheckbox = e => {
    const { change } = this.props

    const item = e.target.name
    const isChecked = e.target.checked ? 1 : 0
    change('blade.polarity', isChecked)
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
  }

  render() {
    const { checkedItems } = this.state
    const {
      bladeWithMargins,
      coatingMachine,
      creationDate,
      bladeId,
      t,
      userLevel,
      totalWidthWithSlitting,
      fabricationNotes,
      material,
      factory,
      previewUoM,
    } = this.props
    let materialTitle = ''
    switch (material) {
      case 0:
        materialTitle = t('common:greenPe')
        break
      case 1:
        materialTitle = t('common:whiteTeflon')
        break
      case 2:
        materialTitle = t('common:metalFoil')
        break
      case 3:
        materialTitle = t('common:pgSleeve')
        break
      case 4:
        materialTitle = t('common:metalShim')
        break
      default:
        materialTitle = ''
    }
    return (
      <div className="bladeModalContent">
        <div className="bladeModalContent__header">
          <div className="bladeModalContent__header__title">
            {t('bladeImageModal.bladeDrawing', { bladeId })}
          </div>
          {creationDate && (
            <div>
              <div className="bladeModalContent__data__label">
                {t('bladeImageModal.creationDate')}
              </div>
              <p>
                {previewUoM === 1 ? (
                  <Moment format="MM.DD.YYYY">{creationDate}</Moment>
                ) : (
                  <Moment format="DD.MM.YYYY">{creationDate}</Moment>
                )}
              </p>
            </div>
          )}
        </div>
        <div className="bladeModalContent__data">
          <div className="bladeModalContent__data__col">
            <div className="bladeModalContent__data__col__1">
              <div className="bladeModalContent__data__label">
                {t('common:factory')}
              </div>
              <p>{factory}</p>

              <div className="bladeModalContent__data__label">
                {t('common:machine')}
              </div>
              <p>{coatingMachine}</p>
            </div>
            <div className="bladeModalContent__data__col__2">
              <div className="bladeModalContent__data__label">
                {t('common:width')}
              </div>
              <p>{bladeWithMargins}</p>
              <div className="bladeModalContent__data__label">
                {t('bladeImageModal.centerOfBlade')}
              </div>
              <p>{totalWidthWithSlitting && totalWidthWithSlitting / 2}</p>
            </div>
            <div className="bladeModalContent__data__col__3">
              <div className="bladeModalContent__data__label">
                {t('bladeImageModal.bladeTurned')}
              </div>
              <div className="mirrorWpr">
                <label>
                  <Checkbox
                    name="horizontal"
                    checked={checkedItems.get('horizontal')}
                    onChange={this.handleCheckbox}
                    disabled={userLevel === 1}
                  />
                  {t('bladeImageModal.mirror')}
                </label>
              </div>
              {materialTitle && (
                <React.Fragment>
                  <div className="bladeModalContent__data__label">
                    {t('common:materials')}
                  </div>
                  <p>{materialTitle}</p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="bladeModalContent__data__notes">
            {fabricationNotes ? (
              <div className="bladeModalContent__data__notes__instruction">
                <div className="bladeModalContent__data__notes__instruction__label">
                  {t('common:fabricationNotes')}
                </div>
                <p>{fabricationNotes}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
PgBladeModalContent.defaultProps = {
  creationDate: null,
  polarity: null,
  bladeWithMargins: null,
  fabricationNotes: null,
}

PgBladeModalContent.propTypes = {
  polarity: PropTypes.number,
  bladeWithMargins: PropTypes.number,
  coatingMachine: PropTypes.string.isRequired,
  creationDate: PropTypes.string,
  bladeId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  totalWidthWithSlitting: PropTypes.number.isRequired,
  userLevel: PropTypes.number.isRequired,
  factory: PropTypes.string.isRequired,
  fabricationNotes: PropTypes.string,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => {
  return {
    polarity:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.polarity,
    bladeWithMargins:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.width,
    creationData:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.width,
    creationDate:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.created,
    bladeId:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.sknro,
    coatingMachine:
      state.coatingMachine && state.coatingMachine.selectedUserMachine.name,
    factory: state.coatingMachine.selectedFactory.city,
    overallWidth: state.BladeVisualisationLegendReducer.overallWidth,
    userLevel: state.AppUser.userLevel,
    totalWidthWithSlitting: state.bladeDrawingReducer.totalWidthWithSlitting,
    fabricationNotes: formValueSelector('newBladeForm')(
      state,
      'blade.fabricationNotes'
    ),
    material:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.bladeMaterial,
    previewUoM: uomPreviewSelector(
      state,
      'previewUoMDropDown.unitOfMeasurement'
    ),
  }
}

export default connect(mapStateToProps, { totalWidthWithSlittingAction })(
  withTranslation(['modal', 'common'])(PgBladeModalContent)
)
