import React from 'react'
import PropTypes from 'prop-types'

const BladeHistoryWrapper = ({ children }) => {
  return <div className="bladeHistoryWrapper">{children}</div>
}

BladeHistoryWrapper.propTypes = {
  children: PropTypes.node,
}

BladeHistoryWrapper.defaultProps = {
  children: null,
}

export default BladeHistoryWrapper
