/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const BladeTableDropDownOptions = props => {
  const {
    factoriesList,
    selectCoatingMachine,
    selectFactoryToLoad,
    coatingmachines,
    t,
    showMachineManagement,
    selectedFactory,
    selectedUserMachine,
  } = props
  return (
    <div className="dropdown">
      <div>
        <div className="dropdown__title">{t('factory')}</div>
        <select onChange={selectFactoryToLoad}>
          {factoriesList.map(fac => (
            <option
              key={fac.id}
              value={fac.id}
              selected={selectedFactory && selectedFactory.id === fac.id}
            >
              {fac.city}
            </option>
          ))}
        </select>
      </div>
      {showMachineManagement ? null : (
        <div>
          <div className="dropdown__title">{t('machine')}</div>
          <select onChange={selectCoatingMachine}>
            {coatingmachines.map(machine => (
              <option
                key={machine.recid}
                value={machine.recid}
                selected={
                  selectedUserMachine &&
                  selectedUserMachine.recid === machine.recid
                }
              >
                {machine.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}

BladeTableDropDownOptions.defaultProps = {
  factoriesList: [],
  selectedFactory: null,
  selectedUserMachine: null,
}

BladeTableDropDownOptions.propTypes = {
  selectFactoryToLoad: PropTypes.func.isRequired,
  selectedFactory: PropTypes.object,
  selectCoatingMachine: PropTypes.func.isRequired,
  coatingmachines: PropTypes.arrayOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  selectedUserMachine: PropTypes.object,
  factoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      country: PropTypes.string,
      city: PropTypes.string,
      siteType: PropTypes.string,
      factorySiteCode: PropTypes.string,
      plantCodeInSAP: PropTypes.string,
    })
  ),
}

const mapStateToProps = state => ({
  factoriesList: state.coatingMachine.factoriesList,
  selectedFactory: state.coatingMachine.selectedFactory,
  selectedUserMachine: state.coatingMachine.selectedUserMachine,
  showMachineManagement: state.MachineManagement.showMachineManagement,
})

export default withTranslation('common')(
  connect(mapStateToProps, {})(BladeTableDropDownOptions)
)
