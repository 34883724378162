/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Checkbox from '../../checkbox/CheckBox'
import Button from '../../button/Button'
import InstructionsModalContent from './InstructionsModalContent/InstructionsModalContent'
import BladeVisualization from '../../../../bladeVisulation/containers'
import CoatingLayersWrapper from './coatingLayers/coatingLayersWrapper/CoatingLayersWrapper'

const checkBoxes = [
  {
    name: 'stripeImage',
    key: 'stripeImage',
    label: 'Stripe image',
  },
  {
    name: 'slittingNotes',
    key: 'slittingNotes',
    label: 'Slitting notes',
  },
  {
    name: 'coatingNotes',
    key: 'coatingNotes',
    label: 'Coating notes',
  },
  {
    name: 'layers',
    key: 'layers',
    label: 'Layers',
  },
]

class InstructionsModal extends Component {
  constructor(props) {
    super(props)

    const checkboxItems = new Map()
    checkBoxes.map(val => checkboxItems.set(val.name, true))

    this.state = {
      checkedItems: checkboxItems,
    }
  }

  handleCheckbox = e => {
    const item = e.target.name
    const isChecked = e.target.checked
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
  }

  render() {
    const { checkedItems } = this.state
    const { onClose, t } = this.props
    return (
      <div className="instructionsModal">
        <div className="instructionsModal__header">
          <div className="instructionsModal__header__display">
            <h4>{t('instructionsModal.display')}</h4>
            <>
              {checkBoxes.map(item => (
                <div
                  className="instructionsModal__header__checkbox"
                  key={item.key}
                >
                  <label>
                    <Checkbox
                      name={item.name}
                      checked={checkedItems.get(item.name)}
                      onChange={this.handleCheckbox}
                      label={t(`common:${item.name}`)}
                    />
                  </label>
                </div>
              ))}
            </>
          </div>
          <div className="instructionsModal__header__buttons">
            <Button
              onClick={onClose}
              label="&times;"
              type="button"
              size="md--close"
              variant="contained--close"
              className="instructionsModal__header__buttons--button-close"
            />
          </div>
        </div>
        <div className="instructionsModal__data-stripes">
          <InstructionsModalContent
            laminationInstructions={checkedItems.get('coatingNotes')}
            slittingInstructions={checkedItems.get('slittingNotes')}
          />
          {checkedItems.get('stripeImage') && (
            <div className="instructionsModal__stripes">
              <BladeVisualization />
            </div>
          )}
        </div>
        <div className="coating-layers__main">
          {checkedItems.get('layers') && <CoatingLayersWrapper />}
        </div>
      </div>
    )
  }
}

InstructionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation(['modal', 'common'])(InstructionsModal)
