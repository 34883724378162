/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { saveDataInternally as saveDataInternallyAction } from './PreviewAction'
import RadioButtonField from '../../common/components/RadioButton/index'

let PreviewUoMDropDown = ({
  isOpenPreviewUoM,
  onTogglePreviewUoM,
  unitOfMeasurement,
  unitOfMeasurementFromMachine,
  coilWithStripes,
  blade,
  saveDataInternally,
}) => {
  const showWarning =
    unitOfMeasurement !== unitOfMeasurementFromMachine ? (
      <span>
        <FontAwesomeIcon
          size="sm"
          color="#FFBD06"
          icon={faExclamationTriangle}
          className="margin-right-sm"
        />
      </span>
    ) : null

  const millimeterOrInch = unitOfMeasurement === 0 ? 'Millimeter' : 'Inch'

  const handleUoMChange = () => {
    if (unitOfMeasurement === unitOfMeasurementFromMachine) {
      saveDataInternally({ blade, coilWithStripes })
    }
  }

  return (
    <>
      <button
        type="button"
        className={`${
          isOpenPreviewUoM
            ? 'dropdownSelection previewUoMSelected'
            : 'dropdownSelection'
        }`}
        onClick={() => onTogglePreviewUoM()}
      >
        <div>Preview UoM</div>
        {showWarning}
        <span className="dropdown-label">{millimeterOrInch}</span>
        {isOpenPreviewUoM ? (
          <FontAwesomeIcon size="sm" icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon size="sm" icon={faCaretDown} />
        )}
      </button>
      {isOpenPreviewUoM && (
        <div className="previewUoM">
          <p>
            <i>
              Shows the blade as chosen unit of measurement. Does not change or
              convert values, only used as a conversion preview.
            </i>
          </p>
          <form className="radioButtons">
            <div className="radioAndCheckboxes margin-bottom-md">
              <label>
                <Field
                  name="previewUoMDropDown.unitOfMeasurement"
                  component={RadioButtonField}
                  type="radio"
                  value={0}
                  parse={val => JSON.parse(val)}
                  label="Millimeter"
                  id="millimeters"
                  className={unitOfMeasurement === 0 ? 'checked' : 'unchecked'}
                  right
                  onChange={handleUoMChange}
                />{' '}
              </label>
            </div>
            <div className="radioAndCheckboxes margin-bottom-md">
              <label>
                <Field
                  name="previewUoMDropDown.unitOfMeasurement"
                  component={RadioButtonField}
                  type="radio"
                  value={1}
                  parse={val => JSON.parse(val)}
                  label="Inch"
                  id="inches"
                  className={unitOfMeasurement === 1 ? 'checked' : 'unchecked'}
                  right
                  onChange={handleUoMChange}
                />{' '}
              </label>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

PreviewUoMDropDown.defaultProps = {
  unitOfMeasurement: null,
  unitOfMeasurementFromMachine: null,
  coilWithStripes: null,
}

PreviewUoMDropDown.propTypes = {
  isOpenPreviewUoM: PropTypes.bool.isRequired,
  onTogglePreviewUoM: PropTypes.func.isRequired,
  unitOfMeasurement: PropTypes.number,
  unitOfMeasurementFromMachine: PropTypes.number,
  coilWithStripes: PropTypes.shape({
    coil: PropTypes.shape({
      popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    coilStripes: PropTypes.arrayOf(
      PropTypes.shape({
        coilID: PropTypes.number,
        id: PropTypes.number,
        orderNum: PropTypes.number,
        stripeType: PropTypes.number,
        width: PropTypes.number,
      })
    ),
    stripes: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }),
  saveDataInternally: PropTypes.func.isRequired,
}

PreviewUoMDropDown = reduxForm({
  form: 'previewUoMDropDown',
  enableReinitialize: true,
})(PreviewUoMDropDown)

const selector = formValueSelector('previewUoMDropDown')

export default PreviewUoMDropDown = connect(
  state => {
    const unitOfMeasurement = selector(
      state,
      'previewUoMDropDown.unitOfMeasurement'
    )
    const unitOfMeasurementFromMachine =
      state.coatingMachine.selectedUserMachine &&
      state.coatingMachine.selectedUserMachine.unitOfMeasurement
    return {
      unitOfMeasurement,
      unitOfMeasurementFromMachine,
      coilWithStripes: getFormValues('coilForm')(state),
      blade: getFormValues('newBladeForm')(state),
    }
  },
  {
    saveDataInternally: saveDataInternallyAction,
  }
)(PreviewUoMDropDown)
