import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import thunk from 'redux-thunk'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faSave,
  faTimes,
  faArrowUp,
  faFile,
  faArchive,
  faEye,
  faExclamationTriangle,
  faPlus,
  faCaretDown,
  faCaretUp,
  faCheck,
  faLayerGroup,
  faCut,
  faDotCircle,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import TelemetryProvider from './applicationInsights/telemetry-provider'
import { getAppInsights } from './applicationInsights/TelemetryService'
import reducers from './store/reducers'
import App from './App'
import i18n from './i18n'

library.add(
  faSearch,
  faSave,
  faTimes,
  faArrowUp,
  faFile,
  faArchive,
  faEye,
  faExclamationTriangle,
  faPlus,
  faCaretDown,
  faCaretUp,
  faCheck,
  faLayerGroup,
  faCut,
  faDotCircle,
  faDownload
)
/* eslint-disable */
const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))
export default store

let appInsights = null

ReactDOM.render(
  <div className="wrapper">
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          {/* TelemetryProvider is component for tracking application insights */}
          <TelemetryProvider
            after={() => {
              appInsights = getAppInsights()
            }}
          >
            <App />
          </TelemetryProvider>
        </Router>
      </Provider>
    </I18nextProvider>
  </div>,
  document.getElementById('root')
)
/* eslint-enable */
