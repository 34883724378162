import axios from 'axios'
// import store from './index'
import { msalApp, GRAPH_REQUESTS } from './auth-utils'

const hostname = window && window.location && window.location.hostname
let BASE_URL = 'https://upmraflataclabelpga-dev-web.azurewebsites.net/Api/'

if (
  hostname === 'labelpga-qa-front.azurewebsites.net' ||
  hostname === 'pga-qa.upmraflatac.com'
) {
  BASE_URL = 'https://upmraflataclabelpga-qa-web.azurewebsites.net/Api/'
} else if (
  hostname === 'labelpga-prod-front.azurewebsites.net' ||
  hostname === 'pga.upmraflatac.com'
) {
  BASE_URL = 'https://labelpga.azurewebsites.net/Api/'
}

async function getToken() {
  // grab current state
  const account = await msalApp.getAccount()
  const token =
    account && (await msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN))
  return {
    headers: {
      Authorization: `Bearer ${token.accessToken}`, // the token is a variable which holds the token
    },
  }
}
export const get = async (path, params = {}) => {
  const item = await getToken()
  return axios.get(`${BASE_URL}${path}`, item, params).then(res => res.data)
}
export const post = async (path, body = {}) => {
  // if (await getToken()) {
  const item = await getToken()
  return axios.post(`${BASE_URL}${path}`, body, item).then(res => res.data)
}
export const remove = async (path, params = {}) => {
  await axios
    .delete(`${BASE_URL}${path}`, params, getToken())
    .then(res => res.data)
}
export const getUserRole = () => {
  return get(`User`)
}

export const fetchHistory = value => {
  return get(`Blades/GetChangeLogItem/${value}`)
}

export const setLanguage = value => {
  return get(`User/Setlanguage/${value}`)
}

export const setMachine = value => {
  return get(`User/SetMachine/${value}`)
}

export const getFactories = () => {
  return get(`Factories`)
}

export const getMachines = factoryId => {
  return get(`MachinesByFactory/${factoryId}`)
}

export async function getBlades(machineId) {
  return get(`BladeScrollDownItems/${machineId}`)
}

export async function getBladeWithCoils(id) {
  return get(`BladeWithCoils/${id}`)
}

export const getCustomers = () => {
  return get(`Customer`)
}

export async function getEanCodes(id) {
  return get(`EANcodes/${id}`)
}

export async function removeEanCode(id) {
  return get(`DeleteEanCode/${id}`)
}

export async function getCoatingLayers(id) {
  return get(`CoatingLayers/${id}`)
}

export async function removeCoatingLayers(values) {
  return post(`DeleteCoatingLayers`, values)
}
export async function updateMaterialsPost(id, values) {
  return post(`MachineMaterials/Replace/${id}`, values)
}
export async function archiveBlade(id) {
  return get(`Blades/ArchiveAndReturn/${id}`)
}

export async function archiveMachine(id) {
  return get(`Machines/Archive/${id}`)
}

export async function restoreBlade(id) {
  return get(`Blades/UnArchiveAndReturn/${id}`)
}

export async function restoreMachine(id) {
  return get(`Machines/UnArchive/${id}`)
}

export async function saveData(values) {
  return post(`Blades/Create`, values)
}

export async function updateData(id, values) {
  return post(`Blades/UpdateBladeWithCoils/${id}`, values, {
    withCredentials: true,
  })
}

export async function createMachine(values) {
  return post(`Machines/Create`, values)
}

export async function updateMachine(id, values) {
  return post(`Machines/Update/${id}`, values)
}

export async function removeMachineCoatingLayers(values) {
  return post(`MachineCoatingLayers/Delete`, values)
}

export async function addFiles(id, values) {
  return post(`Blades/AddFile/${id}`, values)
}

export async function getFile(id) {
  return get(`Blades/GetFile/${id}`)
}

export async function removeFile(bladeId, fileId) {
  return get(`Blades/RemoveFIle/${bladeId}/${fileId}`)
}
