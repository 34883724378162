import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import CoilSingleLayer from '../../../../../../../assets/Coil-SingleLayer.png'
import CoilVisualization from '../coilVisualization/CoilVisualization'

const CoatingLayersOne = props => {
  const { coatingLayers, coilWithStripes, t } = props

  return (
    <div className="coatingLayers-outer">
      <div className="coatingLayers">
        <div className="coatingLayers__amount">
          {t('on_coil').toUpperCase()}: {coilWithStripes.coil.amount} X{' '}
          {coilWithStripes.coil.width}
        </div>
        <div className="coatingLayers__image">
          <div className="coatingLayers__A-side coil-margin-right">A</div>
          <img
            className="coating__image"
            src={CoilSingleLayer}
            alt="coil layers"
          />
          <div className="coatingLayers__B-side coil-margin-left">B</div>
        </div>
        <div className="coatingLayers__layers">
          {coatingLayers.map((layer, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="coatingLayers__layers--layer" key={i}>
              {`${i + 1}) ${layer.title}`}
            </div>
          ))}
        </div>
      </div>
      <CoilVisualization coilData={coilWithStripes} />
    </div>
  )
}

CoatingLayersOne.defaultProps = {
  coatingLayers: [],
}

CoatingLayersOne.propTypes = {
  coatingLayers: PropTypes.arrayOf(
    PropTypes.shape({
      coating: PropTypes.number,
      id: PropTypes.number,
      ordNum: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  t: PropTypes.func.isRequired,
  coilWithStripes: PropTypes.shape({
    coil: PropTypes.shape({
      popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    coilStripes: PropTypes.arrayOf(
      PropTypes.shape({
        coilID: PropTypes.number,
        id: PropTypes.number,
        orderNum: PropTypes.number,
        stripeType: PropTypes.number,
        width: PropTypes.number,
      })
    ),
    stripes: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }).isRequired,
}

export default withTranslation(['common'])(CoatingLayersOne)
