import React from 'react'
import WrapperModal from '../../modal/WrapperModal'
import Spinner from '../Spinner'

const AppWideSpinner = () => {
  return (
    <WrapperModal>
      <Spinner />
    </WrapperModal>
  )
}

export default AppWideSpinner
