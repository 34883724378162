/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import PropTypes from 'prop-types'
import Button from '../button/Button'
import BgBladeModalContent from './bgBladeModalContent/BgBladeModalContent'
import BladeDrawing from '../../../bladeDrawing/containers'

const PgBladeModal = props => {
  const { onClose, change } = props
  return (
    <div className="instructionsModal">
      <div className="instructionsModal__header headerMinHeight">
        <div className="instructionsModal__header__display" />
        <div className="instructionsModal__header__buttons">
          <Button
            onClick={onClose}
            label="&times;"
            type="button"
            size="md--close"
            variant="contained--close"
            className="instructionsModal__header__buttons--button-close"
          />
        </div>
      </div>
      <BgBladeModalContent change={change} />

      <div className="instructionsModal__coil">
        <BladeDrawing />
      </div>
    </div>
  )
}

PgBladeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
}

export default PgBladeModal
