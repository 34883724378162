import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CoilItemComponent from './CoilItemComponent'

const Item = props => {
  const {
    allStripesArray,
    totalWidth,
    changeStripes,
    uomPreview,
    color,
    coilWidth,
  } = props
  const stripesArray = []
  let amountCounter = 0
  const indexArray = []
  allStripesArray.map((item, i) => {
    amountCounter = 0
    // check is this stripe already merged.
    // if not in list then go forward
    if (indexArray.indexOf(i) === -1) {
      for (let index = i; index < allStripesArray.length; index++) {
        if (allStripesArray[index].stripeType !== item.stripeType) {
          // if not same same then break same type
          break
        }
        // Merges
        amountCounter += allStripesArray[index].width
        if (
          allStripesArray[index + 1] &&
          allStripesArray[index + 1].stripeType === item.stripeType
        ) {
          // Adds merged index of stripe to list
          indexArray.push(index + 1)
        }
      }
      stripesArray.push({
        stripeType: item.stripeType,
        width: amountCounter,
      })
    }
    return false
  })

  useEffect(() => {
    changeStripes(stripesArray)
  }, [stripesArray])

  return stripesArray.map((item, i) => {
    // renders stripes
    return (
      <CoilItemComponent
        key={i}
        item={item}
        totalWidth={totalWidth}
        i={i}
        uomPreview={uomPreview}
        color={color}
        coilWidth={coilWidth}
      />
    )
  })
}

Item.propTypes = {
  allStripesArray: PropTypes.arrayOf(
    PropTypes.shape({
      coilId: PropTypes.number,
      id: PropTypes.number,
      orderNum: PropTypes.number,
      stripeType: PropTypes.number,
      width: PropTypes.number,
    })
  ).isRequired,
  changeStripes: PropTypes.func.isRequired,
  totalWidth: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.arrayOf(PropTypes.number).isRequired,
  ]).isRequired,
}
export default Item
