/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createNewMachine as createNewMachineAction,
  updateMachineData as updateMachineDataAction,
} from '../MachineManagementFormAction'

import Button from '../../common/components/button/Button'

const RemoteSubmitButton = ({
  createNewMachine,
  updateMachineData,
  isArchived,
  t,
  machineData,
  saving,
  machineName,
  selectedFactory,
}) => {
  machineData &&
    machineData.machine &&
    machineData.machine.machineCoatingLayers &&
    machineData.machine.machineCoatingLayers.map(item => delete item.title)
  const machineDataExists = machineData && machineData.machine
  // making a copy of machines data so we can remove unnecessary fields
  const newMachineData = { ...machineDataExists }
  newMachineData.recid = saving
  newMachineData.factoryId = selectedFactory.id
  // We are passing array of coating layers to API to be removed
  const coatingLayersRemoving =
    machineData &&
    machineData.removedCoatingLayers &&
    machineData.removedCoatingLayers.map(element => element.id)
  const machineMaterials =
    machineData && machineData.machine && machineData.machine.machineMaterials

  const savingButton = (
    <Button
      label={t('save')}
      type="button"
      size="md"
      variant="contained--primary"
      icon={<FontAwesomeIcon icon="save" />}
      disabled={(isArchived && true) || !machineName}
      onClick={() =>
        createNewMachine(
          newMachineData,
          newMachineData.factoryId,
          machineMaterials
        )
      }
    />
  )
  const updatingButton = (
    <Button
      label={t('save')}
      type="button"
      size="md"
      variant="contained--primary"
      icon={<FontAwesomeIcon icon="save" />}
      disabled={(isArchived && true) || !machineName}
      onClick={() =>
        updateMachineData(
          saving,
          newMachineData,
          selectedFactory.id,
          coatingLayersRemoving,
          machineMaterials
        )
      }
    />
  )
  return saving === 0 ? savingButton : updatingButton
}

const mapStateToProps = state => {
  return {
    selectedFactory: state.coatingMachine.selectedFactory,
    machineData: getFormValues('MachineManagementForm')(state),
    machineId:
      state.form.MachineManagementForm &&
      state.form.MachineManagementForm.values.machine &&
      state.form.MachineManagementForm.values.machine.recid,
    machineName:
      state.form.MachineManagementForm &&
      state.form.MachineManagementForm.values.machine &&
      state.form.MachineManagementForm.values.machine.name,
  }
}
RemoteSubmitButton.propTypes = {
  createNewMachine: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  {
    createNewMachine: createNewMachineAction,
    updateMachineData: updateMachineDataAction,
  }
)(withTranslation(['common'])(RemoteSubmitButton))
