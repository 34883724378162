/* eslint-disable react/destructuring-assignment */
import React from 'react'
import NewBladeForm from '../../features/newBladeForm/containers'
import CoatingMachine from '../../features/coatingMachine/containers'
import BladeVisulation from '../../features/bladeVisulation/containers'
import CoilForm from '../../features/coilForm/containers/CoilForm'

const BladeTableAndNewBlade = () => {
  return (
    <div>
      <div className="bladeTableAndNewBlade">
        <CoatingMachine />
        <NewBladeForm />
      </div>
      <BladeVisulation />
      <CoilForm />
    </div>
  )
}

export default BladeTableAndNewBlade
