const rules = {
  0: {
    static: [],
  },
  1: {
    static: ['home-page:visit'],
  },
  2: {
    static: [
      'home-page:visit',
      'blade-table:seeArchived',
      'management:visit',
      'newBladeForm:edit',
      'newBladeForm:create',
      'newBladeForm:archive',
      'newBladeForm:addEAN',
      'newBladeForm:addCustomers',
      'coilForm:addNewCoil',
      'coilForm:deleteCoil',
      'coilForm:addStripe',
    ],
  },
  3: {
    static: [
      'home-page:visit',
      'blade-table:seeArchived',
      'management:visit',
      'machine-management:visit',
      'newBladeForm:edit',
      'newBladeForm:create',
      'newBladeForm:archive',
      'newBladeForm:addEAN',
      'newBladeForm:addCustomers',
      'coilForm:addNewCoil',
      'coilForm:deleteCoil',
      'coilForm:addStripe',
    ],
  },
}

export default rules
