import { connect } from 'react-redux'

import HistoryView from '../components'
import { HistoryViewAction } from '../HistoryAction'

const mapStateToProps = state => ({
  historyJson: state.HistoryReducer.historyJson,
})

export default connect(
  mapStateToProps,
  {
    HistoryViewAction,
  }
)(HistoryView)
