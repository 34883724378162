import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import WrapperModal from '../modal/WrapperModal'
import InnerModal from '../modal/innerModal/InnerModal'
import ModalBody from '../modal/modalBody/ModalBody'
import ModalHeader from '../modal/modalHeader/ModalHeader'
import ButtonWrapper from '../modal/buttonWrapper/ButtonWrapper'
import Button from '../button/Button'

const NewBladeModal = props => {
  const { handleshowNewModal, clearDataTrue, showMachineManagement, t } = props
  const machineOrBlade = showMachineManagement
    ? t('common:machine')
    : t('common:blade')
  const machineOrBladeWithAccusative = showMachineManagement
    ? t('common:machineAccusative')
    : t('common:bladeAccusative')
  return (
    <div>
      <WrapperModal>
        <InnerModal>
          <ModalHeader>{t('newModal.header', { machineOrBlade })}</ModalHeader>
          <ModalBody>
            {t('newModal.body', { machineOrBladeWithAccusative })}
          </ModalBody>
          <ButtonWrapper>
            <Button
              onClick={() => {
                clearDataTrue()
                handleshowNewModal()
              }}
              label={t('common:yes')}
              type="button"
              size="md"
              variant="outlined--primary"
            />
            <Button
              onClick={handleshowNewModal}
              label={t('common:cancel')}
              type="button"
              size="md"
              variant="outlined--tertiary"
            />
          </ButtonWrapper>
        </InnerModal>
      </WrapperModal>
    </div>
  )
}

NewBladeModal.propTypes = {
  handleshowNewModal: PropTypes.func.isRequired,
  clearDataTrue: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  showMachineManagement: state.MachineManagement.showMachineManagement,
})

export default connect(mapStateToProps)(
  withTranslation(['modal', 'common'])(NewBladeModal)
)
