/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { colors } from '../../../common/components/instructionsModal/instructionsModal/coatingLayers/coatingLayersWrapper/CoatingLayersWrapper'
import { convertToInches, convertToMm } from '../../../../utils/convertUoM'

const ValueGrid = props => {
  const {
    totalWidth,
    coilsIfObg,
    coilsAmount,
    fromInstructions,
    isInstructionsModalOpen,
    previewUoM,
    machineUoM,
  } = props

  const gridDivider = previewUoM === 0 ? 50 : 2

  // divided by divider
  const totalWidthDivaded = totalWidth / gridDivider

  // how many times gridDivider goes to totalwidth
  const rnd = _.times(totalWidthDivaded, i => {
    return i * gridDivider
  })

  const coilVal = []
  // creates array which is flat. and if amount is 5 it repeats same stripes 5 times
  coilsIfObg.forEach(item => {
    for (let i = 0; i < item.coil.amount; i++) {
      coilVal.push(item)
    }
  })

  // creates coil marker, which shows where coil ends.
  const coilsMarker = coilVal.map(item => {
    const array = []
    item.coilStripes.map(itemChild => {
      return array.push(itemChild.width)
    })
    return array
  })

  let counterParent = 0
  const cordinants = coilsMarker.map(element => {
    let counter = 0
    element.forEach(elementChild => {
      counter += elementChild
    })
    counterParent += counter
    return counterParent
  })

  let convertedCoilAmount
  if (previewUoM === 1 && previewUoM !== machineUoM) {
    convertedCoilAmount =
      coilsAmount &&
      coilsAmount.map(coilWithStripes => ({
        coil: {
          ...coilWithStripes.coil,
          width: convertToInches(coilWithStripes.coil.width),
        },
      }))
  } else if (previewUoM === 0 && previewUoM !== machineUoM) {
    convertedCoilAmount =
      coilsAmount &&
      coilsAmount.map(coilWithStripes => ({
        coil: {
          ...coilWithStripes.coil,
          width: convertToMm(coilWithStripes.coil.width),
        },
      }))
  } else {
    convertedCoilAmount = coilsAmount
  }

  // Calculation for colored stripes how much from left
  const coilsWidth = convertedCoilAmount.map(
    item => item.coil.amount * item.coil.width
  )
  coilsWidth && coilsWidth.splice(coilsWidth.length, 1)
  let total = 0
  const fromLeft = []

  coilsWidth &&
    coilsWidth.forEach(item => {
      fromLeft.push(total)
      total += item
    })

  return (
    <div className="gridWprOuter">
      <div className="coilsWidth" style={{ left: '-2px' }} />
      {
        // renders coils makers
      }
      {cordinants.map((val, i) => (
        <div
          className="coilsWidth"
          style={{ left: `${(val / totalWidth) * 100}%` }}
          key={`coilsWidth${i}`}
        />
      ))}
      {/* Colored coils for instruction modal */}
      {convertedCoilAmount &&
        convertedCoilAmount.map((coil, index) => (
          <div
            key={index}
            style={{
              width: `${((coil.coil.amount * coil.coil.width) / totalWidth) *
                100}%`,
              position: 'absolute',
              left: `${(fromLeft[index] / totalWidth) * 100}%`,
              top: '-1rem',
              borderBottom:
                isInstructionsModalOpen && fromInstructions // will be underlined with colors only for instructions modal with many coils state
                  ? `1rem solid ${colors[index]}`
                  : null,
            }}
          />
        ))}
      {
        // renders grid
      }
      <div className="gridWpr">
        {rnd.map(
          (val, index) =>
            totalWidth !== val && (
              <div
                className="gridItem"
                style={{
                  width: `${100 / (totalWidth / gridDivider)}%`,
                }}
                key={`gridItem${index}`}
              >
                <div className="marker">
                  <span />
                </div>

                <span
                  className={`content ${
                    val === 0 || 2 || 4 || 8 ? 'content__zero' : null
                  } ${val === gridDivider ? 'content__twoDigits' : null} ${
                    val >= totalWidth ? 'content__fourDigits' : null
                  }`}
                >
                  {val}
                </span>
              </div>
            )
        )}
      </div>
    </div>
  )
}

ValueGrid.defaultProps = {
  coilsAmount: null,
}

ValueGrid.propTypes = {
  totalWidth: PropTypes.number.isRequired,
  coilsIfObg: PropTypes.arrayOf(
    PropTypes.shape({
      stripes: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
      adhesive: PropTypes.bool,
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  coilsAmount: PropTypes.arrayOf(
    PropTypes.shape({
      coil: PropTypes.shape({
        popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        popid: PropTypes.number,
        skid: PropTypes.number,
        ordnum: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        firstTrackGlued: PropTypes.bool,
        perforated: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      coilStripes: PropTypes.arrayOf(
        PropTypes.shape({
          coilID: PropTypes.number,
          id: PropTypes.number,
          orderNum: PropTypes.number,
          stripeType: PropTypes.number,
          width: PropTypes.number,
        })
      ),
      stripes: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    })
  ),
  fromInstructions: PropTypes.bool.isRequired,
  isInstructionsModalOpen: PropTypes.bool.isRequired,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  coilsAmount:
    state.form.coilForm.values && state.form.coilForm.values.coilWithStripes,
  fromInstructions: state.coatingLayersWrapperReducer.manyCoilsView,
  isInstructionsModalOpen: state.newBladeFormReducer.isModalOpen,
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})

export default connect(mapStateToProps, null)(ValueGrid)
