/* eslint-disable react/destructuring-assignment */
import React from 'react'
import MachineManagementForm from '../../features/machineManagmentForm/containers'
import CoatingMachine from '../../features/coatingMachine/containers'

const MachineManagement = () => {
  return (
    <div className="bladeTableAndNewBlade">
      <CoatingMachine />
      <MachineManagementForm />
    </div>
  )
}

export default MachineManagement
