/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { convertToMm, convertToInches } from '../../../utils/convertUoM'
/* eslint-disable jsx-a11y/label-has-for */

const BladeDrawing = props => {
  const {
    coilData,
    bladeWithMargins,
    bladeMirroring,
    // Slitting margins are are added to first and last stripe
    slitting,
    // Spretting margins are removed from stripe on top(type 1) and added to bottom(type 0)
    spreading,
    totalWidthWithSlittingAction,
    bladeWidth,
    t,
    machineUoM,
    previewUoM,
  } = props

  // Convert coilData if necessary
  let convertedCoilData
  if (previewUoM === 0 && previewUoM !== machineUoM) {
    convertedCoilData = coilData && {
      coilWithStripes: coilData.coilWithStripes.map(item => ({
        coil: { ...item.coil },
        coilStripes: item.coilStripes.map(stripe => ({
          ...stripe,
          width: convertToMm(stripe.width),
        })),
      })),
    }
  } else if (previewUoM === 1 && previewUoM !== machineUoM) {
    convertedCoilData = coilData && {
      coilWithStripes: coilData.coilWithStripes.map(item => ({
        coil: { ...item.coil },
        coilStripes: item.coilStripes.map(stripe => ({
          ...stripe,
          width: convertToInches(stripe.width),
        })),
      })),
    }
  } else {
    convertedCoilData = coilData
  }

  const isInch = previewUoM === 1

  function getSum(total, num) {
    return total + num.width
  }
  if (bladeWithMargins === '') {
    return <h2>{t('bladeImageModal.specifyWidthOfBlade')}</h2>
  }
  const mirroring = null || bladeMirroring === 1

  const array = []
  const coilsIfObg = convertedCoilData && convertedCoilData.coilWithStripes
  if (coilsIfObg) {
    const stripesArray = []
    let amountCounter = 0
    const indexArray = []

    // creates one level array. if amount in coil is 3. it repeats it 3 times
    coilsIfObg.forEach(item => {
      for (let i = 0; i < item.coil.amount; i++) {
        item.coilStripes.forEach(itemChild => {
          array.push(itemChild)
        })
      }
    })
    array.map((item, i) => {
      amountCounter = 0
      // check is this stripe already merged.
      // if not in list then go forward
      if (indexArray.indexOf(i) === -1) {
        for (let index = i; index < array.length; index++) {
          if (array[index].stripeType !== item.stripeType) {
            // if not same same then break same type
            break
          }
          // Merges
          amountCounter += array[index].width
          if (
            array[index + 1] &&
            array[index + 1].stripeType === item.stripeType
          ) {
            // Adds merged index of stripe to list
            indexArray.push(index + 1)
          }
        }
        stripesArray.push({
          stripeType: item.stripeType,
          width: amountCounter,
        })
      }
      return false
    })
    // Flips the blade
    if (mirroring) {
      stripesArray.reverse()
    }
    const width = array.reduce(getSum, 0)
    if (!(width - 1 < bladeWidth)) {
      return <h2>{t('bladeImageModal.widerOverallWidth')}</h2>
    }
    const margins = (bladeWithMargins - width) / 2
    const wrapper = []
    let total = margins
    let cordinantsTotal = 0
    const linesStart = isInch ? 6 : 3
    const amountArray = [] // used upper component in ui. just number values in ui
    const amountCordinants = [] // used bottom component in ui.(instruction for carpenter)
    const linesEnd = 20
    // used for both upper and bottom component in ui
    stripesArray.forEach((item, i) => {
      const spreadingVal = item.stripeType === 1 ? -spreading : spreading
      let topOrBottom
      // stripes width
      const itemWidth = item.width + spreadingVal
      // checks is stripe bottom in ui
      if (item.stripeType === 0) {
        topOrBottom = linesEnd
      } else {
        topOrBottom = linesStart
      }
      let first
      let second
      // checks if is type 1(top on ui) and first stripe.

      if (i === 0 && item.stripeType === 1) {
        first = ((total + spreading / 2) / bladeWithMargins) * 100
        second =
          ((item.width + total - spreading / 2 + slitting) / bladeWithMargins) *
          100
        // used upper component in ui. just number values in ui
        amountArray.push({
          x: first,
          y: item.stripeType !== 0 ? 'top' : 'bottom',
          content: itemWidth + slitting,
          stripeType: item.stripeType,
          spreadingVal,
        })
        // checks if is type 1(top on ui) and last stripe.
      } else if (i === stripesArray.length - 1 && item.stripeType === 1) {
        first = ((total + spreading / 2) / bladeWithMargins) * 100
        second =
          ((item.width + slitting + total - spreading / 2) / bladeWithMargins) *
          100
        // used upper component in ui. just number values in ui
        amountArray.push({
          x: first,
          y: item.stripeType !== 0 ? 'top' : 'bottom',
          content: itemWidth + slitting,
          stripeType: item.stripeType,
          spreadingVal,
        })
        // all other cases
      } else {
        if (item.stripeType === 1) {
          // check its top stripe in ui
          first = ((total + spreading / 2) / bladeWithMargins) * 100
          second =
            ((item.width + total - spreading / 2) / bladeWithMargins) * 100
        } else {
          first = ((total - spreading / 2) / bladeWithMargins) * 100
          second =
            ((spreading / 2 + item.width + total) / bladeWithMargins) * 100
        }
        // used upper component in ui. just number values in ui
        amountArray.push({
          x: first,
          y: item.stripeType !== 0 ? 'top' : 'bottom',
          content: itemWidth,
          stripeType: item.stripeType,
          spreadingVal,
        })
      }
      // used upper component in ui. just number values in ui
      const pushVal = `${first} ${topOrBottom} ${second} ${topOrBottom}`

      if (
        (stripesArray[i + 1] &&
          stripesArray[i + 1].stripeType !== item.stripeType) ||
        !stripesArray[i + 1]
      ) {
        // if first or last stripe
        if (i === 0 || stripesArray.length - 1 === i) {
          // used for carpenters intructions.(lower component in ui)
          amountCordinants.push({
            cordinant: item.width + total + slitting + spreadingVal,
            amount: item.width + cordinantsTotal + slitting + spreadingVal,
            first: item.stripeType === 1,
            stripeType: item.stripeType,
            spreadingVal,
          })
        } else {
          // used for carpenters intructions.(lower component in ui)
          amountCordinants.push({
            cordinant: item.width + total + slitting + spreadingVal,
            amount: item.width + cordinantsTotal + spreadingVal,
            first: item.stripeType === 1,
            stripeType: item.stripeType,
            spreadingVal,
          })
        }
      } else {
        // used for carpenters intructions.(lower component in ui)
        cordinantsTotal -= spreadingVal
      }
      // check if it is first stripe or last stripe and type is 1(top in ui)
      if ((i === 0 || i === stripesArray.legth - 1) && item.stripeType === 1) {
        total += slitting
        cordinantsTotal += slitting
      }
      // check if it is not first stripe and type is not 0(lower in ui)
      if (!(i === 0 && item.stripeType === 0)) {
        cordinantsTotal += item.width + spreadingVal
      }
      total += item.width // calculates total for carpenter intractions (lower visualitation)
      wrapper.push(pushVal)
    })
    const slittingVal = stripesArray[0].stripeType === 1 ? slitting : 0

    let combinedFirst = 0
    let combinedLast = 0
    // Checks is last stripe 0. if so, then takes second last. because last one is merged to margins
    let lastStripeObj = amountCordinants[amountCordinants.length - 1]
    if (lastStripeObj.stripeType === 0) {
      lastStripeObj = amountCordinants[amountCordinants.length - 2]
    }
    // send data to parent modal. middle of blade data
    useEffect(() => {
      totalWidthWithSlittingAction(lastStripeObj.amount)
    })
    let leftMargin = ((margins + spreading / 2) / bladeWithMargins) * 100
    let rightMargin =
      ((total + slittingVal - spreading / 2) / bladeWithMargins) * 100
    // if first stripe is combined to margin. Needs to be type of 0(bottom in ui)
    if (stripesArray[0].stripeType === 0) {
      leftMargin = ((margins + spreading + slitting) / bladeWithMargins) * 100
    }
    // if last stripe is combined to margin. Needs to be type of 0(bottom in ui)
    if (stripesArray[stripesArray.length - 1].stripeType === 0) {
      rightMargin =
        ((total + slittingVal - spreading - slitting) / bladeWithMargins) * 100
    }
    // Next gets margings by minusin visiable blade to actual width of physical blade(has margings)
    // and dividing margins by 2

    let marginAmount = 0
    // This calculates total amount of length and takes first and last stripe if those type of 0
    amountArray.forEach((item, i) => {
      if (item.stripeType === 0 && i === 0) {
        combinedFirst = item.content
      }
      if (i === amountArray.length - 1 && item.stripeType === 0) {
        combinedLast = item.content
      }
      marginAmount += item.content
      return false
    })
    // Next gets margings by minusin visiable blade to actual width of physical blade(has margings)
    // and dividing margins by 2
    const marginsWithSettings = (bladeWithMargins - marginAmount) / 2
    return (
      <React.Fragment>
        <div className="bladeDrawingWpr margin-bottom-xl">
          <h3 className={bladeMirroring === 0 ? 'a title' : 'b title'}>a</h3>
          <h3 className={bladeMirroring === 0 ? 'b title' : 'a title'}>b</h3>
          {
            // margings + combined if type is 0(placed down in ui)
          }
          <p
            className={`${
              previewUoM === 1 ? 'rotate-numbers' : null
            } leftMargin left-margin-inches`}
          >
            {marginsWithSettings + combinedFirst}
          </p>
          <p
            className={`${
              previewUoM === 1 ? 'rotate-numbers' : null
            } rightMargin`}
          >
            {marginsWithSettings + combinedLast}
          </p>

          <div className="svgWpr">
            <svg
              width="100%"
              height="1000px"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polyline
                  id="Path"
                  stroke="#000"
                  strokeWidth="0.1%"
                  fill="#fff"
                  // prints lines. out side of blade
                  points={`50 0, 0 0, 0 20, ${leftMargin} 20
                  ${`${wrapper.map(
                    // this print blade lines
                    item => item
                  )} ${rightMargin} 20, 100 20, 100 0, 50 0`}  `}
                />
              </g>
            </svg>
          </div>
          {
            // __Renders width(value) of stripes__
          }
          {amountArray.map((item, i) => {
            // First stripes value will not be rendered if type is 0(placed down in ui)
            // , because those are merged to margings
            if (i === 0 && item.stripeType === 0) {
              return null
            }
            // Last stripes value will not be rendered if type is 0(placed down in ui)
            // , because those are merged to margings
            if (i === amountArray.length - 1 && item.stripeType === 0) {
              return null
            }
            // renders normal stripe
            return (
              <div
                style={{
                  left: `${item.x}%`,
                  width: `${(item.content / bladeWithMargins) * 100}%`,
                }}
                // Next decides is it up or down
                className={`${item.y} amount`}
                key={`amounts${i}`}
              >
                <p
                  className={`${previewUoM === 1 ? 'rotate-numbers' : null} ${
                    item.y === 'top'
                      ? isInch
                        ? 'numbers-up-imperial'
                        : 'numbers-up'
                      : 'numbers-down'
                  }`}
                >
                  {previewUoM === 1
                    ? parseFloat(item.content).toFixed(5)
                    : item.content}
                </p>
              </div>
            )
          })}
        </div>
        {
          // __renders intructions to carpenter. Every amount is bigger than previous__
        }
        <div className="cordinantaWpr">
          {amountCordinants.map((item, i) => {
            let content = null
            if (!(i === 0 && item.stripeType === 0)) {
              content = (
                <p
                  className={
                    item.first
                      ? 'first amountIdicater' // top in ui
                      : 'second amountIdicater' // bottom in ui
                  }
                  style={{
                    left: `${(item.cordinant / bladeWithMargins) * 100}%`,
                  }}
                >
                  <span className="line" />
                  <span
                    className={`${
                      previewUoM === 1
                        ? item.amount.toString().length > 4
                          ? 'amountContent-inches middle'
                          : 'amountInches-less-digits middle'
                        : 'amountContent middle'
                    }`}
                  >
                    {previewUoM === 1
                      ? parseFloat(item.amount).toFixed(5)
                      : item.amount}
                  </span>
                </p>
              )
            }
            // checks is last stripe and type 0 (bottom in ui)
            if (i === amountCordinants.length - 1 && item.stripeType === 0) {
              content = null
            }
            let itemClass
            if (item.first) {
              itemClass = 'second amountIdicater' // bottom in ui
            } else if (amountCordinants[0].stripeType === 0) {
              itemClass = 'second amountIdicater' // bottom in ui
            } else {
              itemClass = 'first amountIdicater' // top in ui
            }
            return (
              <React.Fragment key={`cordinants${i}`}>
                {
                  // renders first stripe value
                }
                {i === 0 && (
                  <p
                    className={itemClass}
                    key="margin-first"
                    style={{
                      left: `${((margins +
                        (item.stripeType === 0
                          ? amountCordinants[0].amount
                          : 0)) /
                        bladeWithMargins) *
                        100}%`,
                    }}
                  >
                    <span className="line" />
                    <span className="amountContent-zero">{0}</span>
                  </p>
                )}
                {content}
              </React.Fragment>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
  return null
}
BladeDrawing.defaultProps = {
  coilData: null,
  bladeMirroring: null,
  bladeWithMargins: null,
}
BladeDrawing.propTypes = {
  coilData: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  bladeWithMargins: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bladeMirroring: PropTypes.number,
  slitting: PropTypes.number.isRequired,
  spreading: PropTypes.number.isRequired,
  totalWidthWithSlittingAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  bladeWidth: PropTypes.number.isRequired,
  previewUoM: PropTypes.number.isRequired,
  machineUoM: PropTypes.number.isRequired,
}
export default withTranslation(['modal', 'common'])(BladeDrawing)
