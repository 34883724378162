import React from 'react'
import { FieldArray } from 'redux-form'
import PropTypes from 'prop-types'
import RenderCoilForm from './renderCoilForm/RenderCoilForm'

/* eslint-disable jsx-a11y/label-has-for */
const CoilForm = props => {
  const {
    handleSubmit,
    onSubmit,
    change,
    initialize,
    coilData,
    userLevel,
  } = props

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldArray
        name="coilWithStripes"
        component={RenderCoilForm}
        change={change}
        initialize={initialize}
        coilData={coilData}
        userLevel={userLevel}
      />
    </form>
  )
}

CoilForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  coilData: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  userLevel: PropTypes.number.isRequired,
}
CoilForm.defaultProps = {
  coilData: null,
}

export default CoilForm
