import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector, getFormValues } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../common/components/button/Button'
import RenderCoilField from '../renderCoilField/RenderCoilField'
import { fourDigits, fiveDecimals } from '../../../../utils/fieldNormalizing'
import Can from '../../../../CanAccess'
import { convertToInches, convertToMm } from '../../../../utils/convertUoM'
import { saveDataInternally as saveDataInternallyAction } from '../../../header/previewUoM/PreviewAction'

class RenderStripes extends Component {
  constructor(props) {
    super(props)
    this.onChangeFunc = this.onChangeFunc.bind(this)
  }

  componentWillMount() {
    const { fields } = this.props
    if (!fields.length) fields.push()
  }

  componentDidUpdate(prevProps) {
    const {
      UomPreview,
      uOmMachine,
      fields,
      bladeId,
      originalCoilStripes,
      change,
      saveDataInternally,
      blade,
      coilWithStripes,
    } = this.props

    if (
      (prevProps.UomPreview !== UomPreview &&
        prevProps.uOmMachine === uOmMachine) ||
      (uOmMachine !== UomPreview &&
        prevProps.bladeId !== bladeId &&
        prevProps.uOmMachine === uOmMachine)
    ) {
      if (prevProps.UomPreview !== UomPreview && UomPreview === uOmMachine) {
        const allStripes =
          originalCoilStripes &&
          originalCoilStripes.map(stripe => stripe.stripes)
        fields.map((coilStripe, index) => {
          const coilsStripeIndex = Number(coilStripe.charAt(16))
          change(coilStripe, allStripes[coilsStripeIndex][index])
        })
      } else {
        fields.map((coilStripe, index) => {
          this.changeUoMFieldValues(UomPreview, coilStripe, fields.get(index))
        })
      }
    }

    if (prevProps && prevProps.bladeId !== bladeId) {
      saveDataInternally({ blade, coilWithStripes })
    }
  }

  onChangeFunc(e) {
    const { change, coilData } = this.props
    const valArray = e.target.name.match(/\d+/g).map(Number)

    change(
      `coilWithStripes[${valArray[0]}]coilStripes[${valArray[1]}].width`,
      parseInt(e.target.valueAsNumber * 32, 10) / 32
    )
    change(`coilWithStripes[${valArray[0]}]coilStripes[${valArray[1]}].id`, 0)
    change(
      `coilWithStripes[${valArray[0]}]coilStripes[${valArray[1]}].orderNum`,
      valArray[1]
    )

    change(
      `coilWithStripes[${valArray[0]}]coilStripes[${valArray[1]}].coilId`,
      0
    )
    let inx
    if (coilData.coilWithStripes[valArray[0]].coil.firstTrackGlued) {
      inx = valArray[1] % 2 === 0 ? 1 : 0
    } else {
      inx = valArray[1] % 2 === 0 ? 0 : 1
    }
    change(
      `coilWithStripes[${valArray[0]}]coilStripes[${valArray[1]}].stripeType`,
      inx
    )

    this.coilWidth(valArray)
  }

  changeUoMFieldValues = (uom, fieldName, currentValue) => {
    const { change } = this.props
    let valueForWidth
    if (uom === 1) {
      valueForWidth = convertToInches(currentValue)
    } else if (uom === 0) {
      valueForWidth = convertToMm(currentValue)
    }
    change(fieldName, valueForWidth)
  }

  coilWidth(indexArray) {
    const { coilData, change } = this.props

    let total = 0
    coilData.coilWithStripes[indexArray[0]].coilStripes.forEach(
      (element, i) => {
        total += element.width
      }
    )
    change(
      `coilWithStripes[${indexArray[0]}].coil.width`,
      parseInt(total * 32, 10) / 32
    )
  }

  handleOnBlur(e, name) {
    const { initialize, change, coilData } = this.props
    const item = e.target.name.match(/\d+/g).map(Number)
    const formatValue = parseInt(e.target.value * 32, 10) / 32

    if (e.target.value === '') {
      // eslint-disable-next-line func-names
      setTimeout(() => {
        coilData.coilWithStripes[item[0]].coilStripes.splice(item[1], 1)
        coilData.coilWithStripes[item[0]].stripes.splice(item[1], 1)
        const ini = { coilWithStripes: coilData.coilWithStripes }
        initialize(ini)
        const firstGlue =
          coilData.coilWithStripes[item[0]].coil.firstTrackGlued && true

        coilData.coilWithStripes[item[0]].coilStripes.forEach((_element, i) => {
          let isEven
          if (firstGlue) {
            isEven = i % 2 === 0
          } else {
            isEven = i % 2 !== 0
          }
          change(
            `coilWithStripes[${item[0]}]coilStripes[${i}].stripeType`,
            isEven ? 1 : 0
          )
        })
        this.coilWidth(item)
      }, 500)
    } else if (e.target.value) {
      setTimeout(() => {
        change(name, formatValue)
      }, 500)
    }
    this.coilWidth(item)
  }

  fieldsPush() {
    const { fields } = this.props
    fields.push()
  }

  render() {
    const {
      fields,
      focusEle,
      userLevel,
      uOmMachine,
      UomPreview,
      meta: { error },
    } = this.props

    const readOnlyValue = userLevel === 1
    const disableWhenUoMpreview = uOmMachine !== UomPreview
    return (
      <>
        <ul className="renderStripes">
          {fields.map((coilStripe, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <Field
                name={coilStripe}
                type="number"
                component={RenderCoilField}
                label={`${index + 1}`}
                onFocus={e => focusEle(e)}
                className="renderStripeInput"
                normalize={uOmMachine === 0 ? fourDigits : fiveDecimals}
                readOnlyValue={readOnlyValue}
                onChange={e => this.onChangeFunc(e)}
                onBlur={(e, newValue, previousValue, name) =>
                  this.handleOnBlur(e, name)
                }
                disabled={disableWhenUoMpreview}
              />
            </li>
          ))}
          <Can
            // eslint-disable-next-line jsx-a11y/aria-role
            role={userLevel}
            perform="coilForm:addStripe"
            yes={() => (
              <li className="addButton">
                <Button
                  type="button"
                  variant="outlined--tertiary"
                  size="sm-m"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => {
                    this.fieldsPush()
                  }}
                  disabled={disableWhenUoMpreview}
                />
              </li>
            )}
            no={() => null}
          />

          {error && <li className="error">{error}</li>}
        </ul>
      </>
    )
  }
}

RenderStripes.propTypes = {
  fields: PropTypes.shape({}),
  focusEle: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  coilData: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  coilWithStripes: PropTypes.shape({
    coil: PropTypes.shape({
      popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    coilStripes: PropTypes.arrayOf(
      PropTypes.shape({
        coilID: PropTypes.number,
        id: PropTypes.number,
        orderNum: PropTypes.number,
        stripeType: PropTypes.number,
        width: PropTypes.number,
      })
    ),
    stripes: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }),
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  userLevel: PropTypes.number.isRequired,
  UomPreview: PropTypes.number,
  uOmMachine: PropTypes.number,
  saveDataInternally: PropTypes.func,
  bladeId: PropTypes.number,
}

RenderStripes.defaultProps = {
  fields: {},
  meta: {},
  coilData: null,
  UomPreview: null,
  uOmMachine: null,
  bladeId: null,
  saveDataInternally: null,
  coilWithStripes: null,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')
const bladeFormSelector = formValueSelector('newBladeForm')

const mapStateToProps = state => {
  const UomPreview = uomPreviewSelector(
    state,
    'previewUoMDropDown.unitOfMeasurement'
  )
  const bladeId = bladeFormSelector(state, 'blade.skid')
  return {
    UomPreview,
    uOmMachine:
      state.coatingMachine.selectedUserMachine &&
      state.coatingMachine.selectedUserMachine.unitOfMeasurement,
    bladeId,
    originalCoilStripes:
      state.previewReducer.bladeData &&
      state.previewReducer.bladeData.coilWithStripes &&
      state.previewReducer.bladeData.coilWithStripes.coilWithStripes,
    coilWithStripes: getFormValues('coilForm')(state),
    blade: getFormValues('newBladeForm')(state),
  }
}

export default connect(mapStateToProps, {
  saveDataInternally: saveDataInternallyAction,
})(RenderStripes)
