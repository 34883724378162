/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faArchive,
  faTimes,
  faPlus,
  faLayerGroup,
  faCut,
  faIndustry,
  faDotCircle,
} from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { validate, noEmptySpaces } from '../../../utils/validate'
import renderField from '../../common/components/RenderField'
import RadioButtonField from '../../common/components/RadioButton'
import TextAreaField from '../../common/components/RenderField/Textarea'
import Button from '../../common/components/button/Button'
import NewBladeModal from '../../common/components/newBladeModal/NewBladeModal'
import ArchiveModal from '../../common/components/archiveModal/ArchiveModal'
import RemoteSubmitButton from '../remoteSubmitButton/RemoteSubmitButton'
import { fourDigits, fiveDecimals } from '../../../utils/fieldNormalizing'
import AppWideSpinner from '../../common/components/spinner/appWideSpinner/AppWideSpinner'
import Checkbox from '../../common/components/checkbox/CheckBox'
import CheckboxNoReduxForm from '../../common/components/checkboxNoReduxForm/CheckBox'

import Can from '../../../CanAccess'

class MachineManagementForm extends Component {
  constructor(props) {
    super(props)
    this.coatingLayersChange = this.coatingLayersChange.bind(this)
    this.onClickCheckbox = this.onClickCheckbox.bind(this)

    this.state = {
      coatingNotesForm: true,
      slittingNotesForm: false,
      fabricationNotesForm: false,
      perforationNotesForm: false,
      showNewModal: false,
      showArchiveModal: false,
      coatingLayerButton: true,
    }
  }

  componentDidMount() {
    this.clearData()
  }

  componentDidUpdate(prevProps) {
    const { selectedFactory } = this.props
    if (prevProps.selectedFactory !== selectedFactory) this.clearData()
  }

  handleClickLaminationNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: true,
      slittingNotesForm: false,
      perforationNotesForm: false,
      fabricationNotesForm: false,
    })
  }

  handleClickSlittingNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: false,
      slittingNotesForm: true,
      perforationNotesForm: false,
      fabricationNotesForm: false,
    })
  }

  handleClickPerforationNotes = e => {
    e.preventDefault()
    const { perforation } = this.props
    perforation &&
      this.setState({
        coatingNotesForm: false,
        slittingNotesForm: false,
        perforationNotesForm: true,
        fabricationNotesForm: false,
      })
  }

  handleClickFabricationNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: false,
      slittingNotesForm: false,
      perforationNotesForm: false,
      fabricationNotesForm: true,
    })
  }

  handleshowNewModal = () => {
    this.setState(prevState => ({
      showNewModal: !prevState.showNewModal,
    }))
  }

  handleshowArchiveModal = () => {
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  handleArchive = () => {
    const { archiveSelectedMachine, machineId, selectedFactory } = this.props
    archiveSelectedMachine(machineId, selectedFactory.id)
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  handleRestore = () => {
    const { restoreSelectedMachine, machineId, selectedFactory } = this.props
    restoreSelectedMachine(machineId, selectedFactory.id)
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  clearData = () => {
    const { clearDataTrue } = this.props
    clearDataTrue()
  }

  onClickCheckbox(id) {
    const { machineMaterials, change } = this.props
    const machineMaterialsList = machineMaterials

    if (machineMaterialsList && machineMaterialsList.indexOf(id) === -1) {
      machineMaterialsList.push(id)
    } else {
      machineMaterialsList.splice(machineMaterialsList.indexOf(id), 1)
    }
    change(`machineMaterials`, machineMaterialsList)
    change('machine.materials', machineMaterialsList)
    this.forceUpdate()
  }

  handleCoatingLayers = coatingVal => {
    const { coatingLayersList, change, machineNmbrRedu } = this.props
    const coatingLayersObj = {
      id: 0,
      skid: machineNmbrRedu || 0,
      orderNum: coatingLayersList.length,
      coating: parseInt(coatingVal, 10),
    }
    // eslint-disable-next-line no-unused-expressions
    coatingLayersList.push(coatingLayersObj)
    change('machine.machineCoatingLayers', coatingLayersList)
    change('coatingLayersField', '')
    this.setState({ coatingLayerButton: true })
  }

  handleRemoveCoatingLayer = (coatingLayer, index) => {
    const { coatingLayersList, change, removedCoatingLayers } = this.props
    const array = coatingLayersList
      .slice(0, index)
      .concat(coatingLayersList.slice(index + 1))
    change('machine.machineCoatingLayers', array)
    if (coatingLayer.id !== 0) {
      removedCoatingLayers.push(coatingLayer)
    }
  }

  coatingLayersChange() {
    this.setState({ coatingLayerButton: false })
  }

  render() {
    const {
      t,
      reset,
      machineName,
      coatingLayersValue,
      coatingLayersList,
      backingOutside,
      showLoading,
      userLevel,
      isArchived,
      perforation,
      hasCoatingNotes,
      hasSlittingNotes,
      hasFabricationNotes,
      hasPerforationNotes,
      machineNmbrRedu,
      machineId,
      machineSavingId,
      machineMaterials,
      unitOfMeasurement,
    } = this.props
    const {
      coatingNotesForm,
      showNewModal,
      showArchiveModal,
      perforationNotesForm,
      slittingNotesForm,
      fabricationNotesForm,
      coatingLayerButton,
    } = this.state

    const newModal = showNewModal ? (
      <NewBladeModal
        reset={reset}
        handleshowNewModal={this.handleshowNewModal}
        clearDataTrue={this.clearData}
      />
    ) : null

    const archiveModal = showArchiveModal ? (
      <ArchiveModal
        handleshowArchive={this.handleshowArchiveModal}
        handleArchive={this.handleArchive}
        handleRestore={this.handleRestore}
      />
    ) : null

    const submitLoader = showLoading ? <AppWideSpinner /> : null
    const machineIdCheck = machineId || machineSavingId
    return (
      <form>
        <div className="bladeForm__buttons">
          <div>
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:edit"
              yes={() => <RemoteSubmitButton saving={machineIdCheck} />}
              no={() => null}
            />
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:create"
              yes={() => (
                <Button
                  onClick={this.handleshowNewModal}
                  label={t('common:new')}
                  type="button"
                  size="md"
                  variant="outlined--primary"
                  icon={<FontAwesomeIcon icon={faFile} />}
                  className="margin-left"
                />
              )}
              no={() => null}
            />
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:archive"
              yes={() => (
                <Button
                  onClick={this.handleshowArchiveModal}
                  label={isArchived ? t('common:restore') : t('common:archive')}
                  type="button"
                  size="md"
                  variant="outlined--secondary"
                  icon={<FontAwesomeIcon icon={faArchive} />}
                  className="margin-left"
                  disabled={machineNmbrRedu === null}
                />
              )}
              no={() => null}
            />
          </div>
        </div>
        <div className="bladeForm">
          <h2 className="title">{machineName || t('common:newMachine')}</h2>
          <div className="bladeForm__wrapper">
            <div className="formRow">
              {machineName ? (
                <div>
                  <h3>UoM</h3>
                  <label>
                    {unitOfMeasurement === 0 ? 'Millimeters' : 'Inches'}
                  </label>
                </div>
              ) : (
                <div>
                  {/* Translate label */}
                  <h3>UoM</h3>
                  <div className="radioAndCheckboxes">
                    <label>
                      <Field
                        name="machine.unitOfMeasurement"
                        component={RadioButtonField}
                        type="radio"
                        value={0}
                        parse={val => JSON.parse(val)}
                        label="Millimeters"
                        id="millimeters"
                        className={
                          unitOfMeasurement === 0 ? 'checked' : 'unchecked'
                        }
                        disabled={!!machineId}
                      />{' '}
                    </label>
                  </div>
                  <div className="radioAndCheckboxes">
                    <label>
                      <Field
                        name="machine.unitOfMeasurement"
                        component={RadioButtonField}
                        type="radio"
                        value={1}
                        parse={val => JSON.parse(val)}
                        label="Inches"
                        id="inches"
                        className={
                          unitOfMeasurement === 1 ? 'checked' : 'unchecked'
                        }
                        disabled={!!machineId}
                      />{' '}
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="formRow">
              <div className="inputWpr name">
                <Field
                  name="machine.name"
                  type="text"
                  label={t('common:name')}
                  component={renderField}
                  className="name"
                  normalize={noEmptySpaces}
                />
              </div>
              <div className="inputWpr netWidth">
                <Field
                  name="machine.netWidth"
                  type="text"
                  label={t('common:net_width')}
                  component={renderField}
                  normalize={
                    unitOfMeasurement === 0 ? fourDigits : fiveDecimals
                  }
                  className="netWidth"
                />
                {unitOfMeasurement === 0 ? <i>mm</i> : <i>in</i>}
              </div>
              <div className="inputWpr width">
                <Field
                  name="machine.width"
                  type="text"
                  label={t('common:width')}
                  component={renderField}
                  normalize={
                    unitOfMeasurement === 0 ? fourDigits : fiveDecimals
                  }
                  className="width"
                />
                {unitOfMeasurement === 0 ? <i>mm</i> : <i>in</i>}
              </div>
            </div>
            <div className="formRow">
              <div>
                <h3>{t('backingOutside')}</h3>
                <div className="radioAndCheckboxes">
                  <label>
                    <Field
                      name="machine.innerback"
                      component={RadioButtonField}
                      type="radio"
                      value
                      parse={val => JSON.parse(val)}
                      label={t('common:backingOutsideOS')}
                      id="backingOutside"
                      className={
                        backingOutside === true ? 'checked' : 'unchecked'
                      }
                    />{' '}
                  </label>
                </div>
                <div className="radioAndCheckboxes">
                  <label>
                    <Field
                      name="machine.innerback"
                      component={RadioButtonField}
                      type="radio"
                      value={false}
                      parse={val => JSON.parse(val)}
                      label={t('common:backingOutsideDS')}
                      id="backingOutside"
                      className={
                        backingOutside === false ? 'checked' : 'unchecked'
                      }
                    />{' '}
                  </label>
                </div>
              </div>
              <div className="selectWpr">
                <label htmlFor="spreadingMargin">
                  {t('common:spreadingMargin')}
                </label>
                <Field
                  name="machine.spreadspace"
                  component="select"
                  className="select"
                  id="spreadingMargin"
                  parse={val => JSON.parse(val)}
                >
                  <option value={0}>0</option>
                  <option value={unitOfMeasurement === 0 ? 1 : 0.03125}>
                    {unitOfMeasurement === 0 ? 1 : 0.03125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 2 : 0.0625}>
                    {unitOfMeasurement === 0 ? 2 : 0.0625}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 3 : 0.125}>
                    {unitOfMeasurement === 0 ? 3 : 0.125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 4 : 0.15625}>
                    {unitOfMeasurement === 0 ? 4 : 0.15625}
                  </option>
                </Field>
                {unitOfMeasurement === 0 ? <i>mm</i> : <i>in</i>}
              </div>
              <div className="selectWpr">
                <label htmlFor="slittingMargin">
                  {t('common:slittingMargin')}
                </label>
                <Field
                  name="machine.cutspace"
                  component="select"
                  className="bladeForm__select"
                  id="slittingMargin"
                  parse={val => JSON.parse(val)}
                >
                  <option value={0}>0</option>
                  <option value={unitOfMeasurement === 0 ? 1 : 0.03125}>
                    {unitOfMeasurement === 0 ? 1 : 0.03125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 2 : 0.0625}>
                    {unitOfMeasurement === 0 ? 2 : 0.0625}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 3 : 0.125}>
                    {unitOfMeasurement === 0 ? 3 : 0.125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 4 : 0.15625}>
                    {unitOfMeasurement === 0 ? 4 : 0.15625}
                  </option>
                </Field>
                {unitOfMeasurement === 0 ? <i>mm</i> : <i>in</i>}
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="checkboxWpr">
                  <label>
                    <Field
                      name="machine.perforated"
                      type="checkbox"
                      component={Checkbox}
                      label={t('common:perforation')}
                    />
                  </label>
                </div>
                <div className="checkboxWpr">
                  <label>
                    <Field
                      name="machine.urk1"
                      type="checkbox"
                      component={Checkbox}
                      label={t('rewind')}
                    />
                  </label>
                </div>
              </div>
              <div>
                <h3>{t('common:materials')}</h3>
                <div className="checkboxWpr">
                  <label>
                    <CheckboxNoReduxForm
                      onChange={() => this.onClickCheckbox(0)}
                      checked={
                        machineMaterials && machineMaterials.indexOf(0) !== -1
                      }
                      label={t('common:greenPe')}
                    />
                  </label>
                </div>
                <div className="checkboxWpr">
                  <label>
                    <CheckboxNoReduxForm
                      onChange={() => this.onClickCheckbox(1)}
                      checked={
                        machineMaterials && machineMaterials.indexOf(1) !== -1
                      }
                      label={t('common:whiteTeflon')}
                    />
                  </label>
                </div>
                <div className="checkboxWpr">
                  <label>
                    <CheckboxNoReduxForm
                      onChange={() => this.onClickCheckbox(2)}
                      checked={
                        machineMaterials && machineMaterials.indexOf(2) !== -1
                      }
                      label={t('common:metalFoil')}
                    />
                  </label>
                </div>
                <div className="checkboxWpr">
                  <label>
                    <CheckboxNoReduxForm
                      onChange={() => this.onClickCheckbox(3)}
                      checked={
                        machineMaterials && machineMaterials.indexOf(3) !== -1
                      }
                      label={t('common:pgSleeve')}
                    />
                  </label>
                </div>
                <div className="checkboxWpr">
                  <label>
                    <CheckboxNoReduxForm
                      onChange={() => this.onClickCheckbox(4)}
                      checked={
                        machineMaterials && machineMaterials.indexOf(4) !== -1
                      }
                      label={t('common:metalShim')}
                    />
                  </label>
                </div>
              </div>
              <div className="coatingLayersWpr">
                <h3>{t('coatingLayers')}</h3>
                <Field
                  name="coatingLayersField"
                  component="select"
                  onChange={this.coatingLayersChange}
                  className="bladeForm__select bladeForm__coatingLayers__select"
                >
                  <option value="" disabled />
                  <option value="1">{t('layer.face')}</option>
                  <option value="2">{t('layer.backing')}</option>
                  <option value="3">{t('layer.adhesivePG')}</option>
                  <option value="4">{t('layer.backprint')}</option>
                  <option value="5">{t('layer.silicone')}</option>
                  <option value="6">{t('layer.adhesive')}</option>
                </Field>
                <Button
                  type="button"
                  disabled={coatingLayerButton}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  variant="outlined--primary"
                  onClick={() => this.handleCoatingLayers(coatingLayersValue)}
                  size="sm"
                  className="bladeForm__EancodesCustomers__input__addInput__addButton"
                />
                {coatingLayersList &&
                  coatingLayersList.map((item, index) => {
                    switch (parseInt(item.coating, 10)) {
                      case 1:
                        item.title = t('layer.face')
                        break
                      case 2:
                        item.title = t('layer.backing')
                        break
                      case 3:
                        item.title = t('layer.adhesivePG')
                        break
                      case 4:
                        item.title = t('layer.backprint')
                        break
                      default:
                        item.title = t('layer.silicone')
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        <div className="bladeForm__EancodesCustomers__input__customerList coatingLayersWidth">
                          <div>
                            {index + 1}
                            <span>) </span>
                            {item.title}
                          </div>
                          <Button
                            type="button"
                            onClick={() => {
                              this.handleRemoveCoatingLayer(item, index)
                            }}
                            variant="outlined--danger"
                            size="sm"
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            className="bladeForm__EancodesCustomers__input__customerList__removeButton"
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="bladeForm__laminationSlittingNotesButtons">
              <div
                onClick={this.handleClickLaminationNotes}
                className={
                  coatingNotesForm
                    ? `pressed ${hasCoatingNotes}`
                    : `default ${hasCoatingNotes}`
                }
              >
                <FontAwesomeIcon icon={faLayerGroup} />
                <button type="button">{t('common:coatingNotes')}</button>
              </div>
              <div
                onClick={this.handleClickSlittingNotes}
                className={
                  slittingNotesForm
                    ? `pressed ${hasSlittingNotes}`
                    : `default ${hasSlittingNotes}`
                }
              >
                <FontAwesomeIcon icon={faCut} />
                <button type="button">{t('common:slittingNotes')}</button>
              </div>
              <div
                onClick={this.handleClickFabricationNotes}
                className={
                  fabricationNotesForm
                    ? `pressed ${hasFabricationNotes}`
                    : `default ${hasFabricationNotes}`
                }
              >
                <FontAwesomeIcon icon={faIndustry} />
                <button type="button">{t('common:fabricationNotes')}</button>
              </div>
              <div
                onClick={this.handleClickPerforationNotes}
                className={
                  perforation
                    ? perforationNotesForm
                      ? `pressed ${hasPerforationNotes}`
                      : `default ${hasPerforationNotes}`
                    : `disabled ${hasPerforationNotes}`
                }
              >
                <FontAwesomeIcon icon={faDotCircle} />
                <button type="button" disabled={!perforation && true}>
                  {t('common:perforationNotes')}
                </button>
              </div>
            </div>
            {coatingNotesForm ? (
              <div>
                <Field
                  cols="5"
                  rows="5"
                  name="machine.coatingNotes"
                  type="text"
                  placeholder={t('common:coatingNotes')}
                  component={TextAreaField}
                />
              </div>
            ) : slittingNotesForm ? (
              <div>
                <Field
                  cols="5"
                  rows="5"
                  name="machine.slittingNotes"
                  type="text"
                  placeholder={t('common:slittingNotes')}
                  component={TextAreaField}
                />
              </div>
            ) : fabricationNotesForm ? (
              <div>
                <Field
                  cols="5"
                  rows="5"
                  name="machine.fabricationNotes"
                  type="text"
                  placeholder={t('common:fabricationNotes')}
                  component={TextAreaField}
                />
              </div>
            ) : (
              <div>
                <Field
                  cols="5"
                  rows="5"
                  name="machine.perforationNotes"
                  type="text"
                  placeholder={t('common:perforationNotes')}
                  component={TextAreaField}
                />
              </div>
            )}
          </div>
        </div>
        <hr />

        {newModal}
        {archiveModal}
        {submitLoader}
      </form>
    )
  }
}

MachineManagementForm.propTypes = {
  machineName: PropTypes.string,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  clearDataTrue: PropTypes.func.isRequired,
  coatingLayersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      recid: PropTypes.number,
      orderNum: PropTypes.number,
      coating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  coatingLayersValue: PropTypes.string,
  backingOutside: PropTypes.bool,
  showLoading: PropTypes.bool.isRequired,
  userLevel: PropTypes.number.isRequired,
  archiveSelectedMachine: PropTypes.func,
  restoreSelectedMachine: PropTypes.func,
  machineId: PropTypes.number,
  clearData: PropTypes.bool.isRequired,
  isArchived: PropTypes.string,
  t: PropTypes.func.isRequired,
  machineNmbrRedu: PropTypes.number,
  perforation: PropTypes.bool,
  hasCoatingNotes: PropTypes.string,
  hasSlittingNotes: PropTypes.string,
  hasFabricationNotes: PropTypes.string,
  hasPerforationNotes: PropTypes.string,
  removedCoatingLayers: PropTypes.arrayOf(PropTypes.any),
  machineSavingId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  machineMaterials: PropTypes.arrayOf(PropTypes.any),
  unitOfMeasurement: PropTypes.number,
}

MachineManagementForm.defaultProps = {
  machineName: null,
  coatingLayersList: [],
  coatingLayersValue: '',
  archiveSelectedMachine: null,
  restoreSelectedMachine: null,
  machineId: null,
  isArchived: null,
  machineNmbrRedu: null,
  backingOutside: false,
  perforation: false,
  hasCoatingNotes: null,
  hasSlittingNotes: null,
  hasFabricationNotes: null,
  hasPerforationNotes: null,
  removedCoatingLayers: [],
  machineSavingId: null,
  machineMaterials: [],
  unitOfMeasurement: null,
}

// eslint-disable-next-line no-class-assign
MachineManagementForm = reduxForm({
  form: 'MachineManagementForm',
  enableReinitialize: true,
  validate,
})(MachineManagementForm)

const selector = formValueSelector('MachineManagementForm')
// eslint-disable-next-line no-class-assign
MachineManagementForm = connect(state => {
  const machineName = selector(state, 'machine.name')
  const machineNet = selector(state, 'machine.machineNet')

  const coatingLayersValue = selector(state, 'coatingLayersField')
  const removedCoatingLayers = selector(state, 'removedCoatingLayers')
  const coatingLayersList = selector(state, 'machine.machineCoatingLayers')
  const backingOutside = selector(state, 'machine.innerback')
  const machineNmbr = selector(state, 'machine.recid')
  const coatingNotes = selector(state, 'machine.coatingNotes')
  const slittingNotes = selector(state, 'machine.slittingNotes')
  const fabricationNotes = selector(state, 'machine.fabricationNotes')
  const perforationNotes = selector(state, 'machine.perforationNotes')
  const perforation = selector(state, 'machine.perforated')
  const machineSavingId = selector(state, 'machine.recid')
  const machineMaterials = selector(state, 'machine.machineMaterials')
  const unitOfMeasurement = selector(state, 'machine.unitOfMeasurement')
  // eslint-disable-next-line prefer-destructuring
  const clearData = state.newBladeFormReducer.clearData
  const initial = clearData
    ? {
        machine: {
          recid: 0,
          cts: null,
          uts: null,
          width: null,
          netWidth: null,
          cutspace: null,
          spreadspace: null,
          name: null,
          description: null,
          layer1: null,
          layer2: null,
          layer3: null,
          innerback: false,
          layerlaminat: null,
          polarity: null,
          ean: null,
          urk1: false,
          perforated: false,
          archived: null,
          coatingNotes: null,
          slittingNotes: null,
          fabricationNotes: null,
          perforationNotes: null,
          machineCoatingLayers: [],
          machineMaterials: [0, 1, 2, 3, 4],
          unitOfMeasurement: 0,
        },
      }
    : {
        machine: state.bladeTable.formValue,
        removedCoatingLayers: [],
        machineMaterials: [],
      }
  return {
    initialValues: initial,
    machineName,
    machineNet,
    bladeId: state.bladeTable.formValue && state.bladeTable.formValue.skid,
    machineId: state.bladeTable.formValue && state.bladeTable.formValue.recid,
    coatingLayersValue,
    showLoading: state.newBladeFormReducer.showLoading,
    showMachineManagement: state.MachineManagement.showMachineManagement,
    clearData,
    isArchived:
      state.bladeTable.formValue && state.bladeTable.formValue.archived,
    machineNmbr,
    coatingLayersList,
    backingOutside,
    removedCoatingLayers,
    perforation,
    hasCoatingNotes: coatingNotes && 'hasCoatingNotes',
    hasPerforationNotes: perforationNotes && 'hasPerforationNotes',
    hasSlittingNotes: slittingNotes && 'hasSlittingNotes',
    hasFabricationNotes: fabricationNotes && 'hasFabricationNotes',
    machineSavingId,
    machineMaterials,
    unitOfMeasurement,
  }
})(MachineManagementForm)

export default withTranslation(['bladeForm', 'common'])(MachineManagementForm)
