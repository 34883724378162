import React from 'react'

// eslint-disable-next-line react/prop-types
const ModalHeader = ({ children }) => {
  return (
    <div className="modalHeader">
      <h3>{children}</h3>
    </div>
  )
}

ModalHeader.defaultProps = {
  children: null,
}

// ModalHeader.propTypes = {
//   children: PropTypes.string,
// }

export default ModalHeader
