import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import fi from './locales/fi'
import en from './locales/en'
import fr from './locales/fr'
import zh from './locales/zh'
import pl from './locales/pl'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  ns: ['common', 'header'],
  nsSeparator: ':',
  defaultNS: 'common',
  resources: {
    en,
    fi,
    fr,
    zh,
    pl,
  },
  react: {
    wait: true,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase()
      if (format === 'lowercase') return value.toLowerCase()
      return value
    },
  },
})

export default i18n
