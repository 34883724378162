/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Moment from 'react-moment'

class InstructionsModalContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedItems: new Map(),
    }
  }

  handleCheckbox = e => {
    const item = e.target.name
    const isChecked = e.target.checked
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
  }

  render() {
    // const { checkedItems } = this.state
    const {
      slittingInstructions,
      laminationInstructions,
      coatingMachineId,
      coatingMachineList,
      author,
      created,
      bladeNmbr,
      coils,
      slittingNotes,
      coatingNotes,
      adhesivePercentage,
      rewind,
      backingOutside,
      perforated,
      t,
      factory,
      perforationNotes,
      previewUoM,
    } = this.props

    const coatingMachineName =
      coatingMachineList &&
      coatingMachineList.filter(machine => machine.recid === coatingMachineId)
    return (
      <div className="instructionsModalContent">
        <div className="instructionsModalContent__header">
          <div className="instructionsModalContent__header__title">
            {t('instructionsModal.header', {
              bladeNmbr,
            })}
          </div>
          <div>
            <div className="instructionsModalContent__data__label">
              {t('instructionsModal.dateUser')}
            </div>
            <p>
              {created ? (
                previewUoM === 1 ? (
                  <Moment format="MM.DD.YYYY">{created}</Moment>
                ) : (
                  <Moment format="DD.MM.YYYY">{created}</Moment>
                )
              ) : (
                '-'
              )}{' '}
              {t('instructionsModal.by')} {author || '-'}
            </p>
          </div>
        </div>
        <div className="instructionsModalContent__data">
          <div className="instructionsModalContent__data__col">
            <div className="instructionsModalContent__data__col__1">
              <div className="instructionsModalContent__data__label">
                {t('common:factory')}
              </div>
              <p>{factory}</p>

              <div className="instructionsModalContent__data__label">
                {t('common:machine')}
              </div>
              <p>
                {coatingMachineName[0] ? (
                  coatingMachineName[0].name
                ) : (
                  <span>-</span>
                )}
              </p>

              <div className="instructionsModalContent__data__label">
                {t('common:adhesive')}
              </div>
              <p>{adhesivePercentage}%</p>

              <div className="instructionsModalContent__data__label">
                {t('common:coil_plural')}
              </div>
              <div>
                {coils ? (
                  coils.map((coil, index) => (
                    <span key={index}>{`${index ? ',' : ''} ${
                      coil.coil.amount
                    }x${coil.coil.width}`}</span>
                  ))
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
            <div className="instructionsModalContent__data__col__2">
              <div className="instructionsModalContent__data__label">
                {t('bladeForm:backingOutside')}
              </div>
              <p>
                {backingOutside
                  ? t('common:backingOutsideOS')
                  : t('common:backingOutsideDS')}
              </p>
              <div className="instructionsModalContent__data__label">
                {t('bladeForm:rewind')}
              </div>
              <p>{rewind ? t('common:yes') : t('common:noAnswer')}</p>
              <div className="instructionsModalContent__data__label">
                {t('common:perforated')}
              </div>
              <p>
                {perforated
                  ? `${t('common:yes')}${
                      perforationNotes ? `. ${perforationNotes}` : ''
                    }`
                  : t('common:noAnswer')}
              </p>
            </div>
          </div>
          <div className="instructionsModalContent__data__notes">
            {laminationInstructions ? (
              <div className="instructionsModalContent__data__notes__instruction">
                <div className="instructionsModalContent__data__notes__instruction__label">
                  {t('common:coatingNotes')}
                </div>
                <p>{coatingNotes}</p>
              </div>
            ) : null}
            {slittingInstructions ? (
              <div className="instructionsModalContent__data__notes__instruction">
                <div className="instructionsModalContent__data__notes__instruction__label">
                  {t('common:slittingNotes')}
                </div>
                <p>{slittingNotes}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

InstructionsModalContent.propTypes = {
  laminationInstructions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  slittingInstructions: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  coatingMachineId: PropTypes.number,
  coatingMachineList: PropTypes.arrayOf(PropTypes.object),
  author: PropTypes.string,
  created: PropTypes.string,
  bladeNmbr: PropTypes.number,
  coils: PropTypes.arrayOf(
    PropTypes.shape({
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      width: PropTypes.number,
      popina: PropTypes.string,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
    })
  ),
  slittingNotes: PropTypes.string,
  coatingNotes: PropTypes.string,
  perforationNotes: PropTypes.string,
  adhesivePercentage: PropTypes.number,
  rewind: PropTypes.bool,
  backingOutside: PropTypes.bool,
  perforated: PropTypes.bool,
  t: PropTypes.func.isRequired,
  factory: PropTypes.string.isRequired,
  previewUoM: PropTypes.number,
}
InstructionsModalContent.defaultProps = {
  laminationInstructions: '',
  coatingMachineList: [],
  slittingInstructions: '',
  coatingMachineId: null,
  author: '',
  created: '',
  bladeNmbr: null,
  coils: [],
  slittingNotes: '',
  coatingNotes: '',
  adhesivePercentage: null,
  rewind: null,
  backingOutside: '',
  perforated: null,
  perforationNotes: '',
  previewUoM: null,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  factory: state.coatingMachine.selectedFactory.city,
  coatingMachineId:
    state.form.newBladeForm.values &&
    state.form.newBladeForm.values.blade.machineid,
  coatingMachineList:
    state.coatingMachine.machineList && state.coatingMachine.machineList,
  author:
    state.form.newBladeForm.values &&
    state.form.newBladeForm.values.blade.author,
  created:
    state.form.newBladeForm.values &&
    state.form.newBladeForm.values.blade.created,
  bladeNmbr:
    state.form.newBladeForm.values &&
    state.form.newBladeForm.values.blade.sknro,
  coils:
    state.form.coilForm.values && state.form.coilForm.values.coilWithStripes,
  adhesivePercentage: state.BladeVisualisationLegendReducer.adhesivePercentage,
  rewind: formValueSelector('newBladeForm')(state, 'blade.urk1'),
  backingOutside: formValueSelector('newBladeForm')(state, 'blade.innerback'),
  slittingNotes: formValueSelector('newBladeForm')(
    state,
    'blade.slittingNotes'
  ),
  coatingNotes: formValueSelector('newBladeForm')(state, 'blade.memo'),
  perforationNotes: formValueSelector('newBladeForm')(
    state,
    'blade.perforationNotes'
  ),
  perforated: formValueSelector('newBladeForm')(state, 'blade.perforated'),
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
})

export default connect(mapStateToProps)(
  withTranslation(['modal', 'common', 'bladeForm'])(InstructionsModalContent)
)
