import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import WrapperModal from '../modal/WrapperModal'
import InnerModal from '../modal/innerModal/InnerModal'
import ModalBody from '../modal/modalBody/ModalBody'
import ModalHeader from '../modal/modalHeader/ModalHeader'
import ButtonWrapper from '../modal/buttonWrapper/ButtonWrapper'
import Button from '../button/Button'

const ArchiveModal = props => {
  const {
    handleshowArchive,
    handleArchive,
    bladeNmbr,
    showMachineManagement,
    machineName,
    handleRestore,
    restoreButton,
    t,
  } = props

  const restoreOrArchive = restoreButton
    ? t('common:restore')
    : t('common:archive')
  const restoreOrArchiveInfinitive = restoreButton
    ? t('common:restoreInfinitive')
    : t('common:archiveInfinitive')
  const machineOrBlade = showMachineManagement
    ? t('common:machine')
    : t('common:blade')
  const machineOrBladeWithAccusative = showMachineManagement
    ? t('common:machineAccusative')
    : t('common:bladeAccusative')
  const machineOrBladeNumber = showMachineManagement ? machineName : bladeNmbr
  const restoreOrRemoveText = restoreButton
    ? t('archiveModal.subBody1')
    : t('archiveModal.subBody2')

  return (
    <div>
      <WrapperModal>
        <InnerModal>
          <ModalHeader>
            {t('archiveModal.header', {
              restoreOrArchive,
              machineOrBlade,
            })}
          </ModalHeader>
          <ModalBody>
            {t('archiveModal.body', {
              machineOrBlade,
              restoreOrArchiveInfinitive,
              machineOrBladeWithAccusative,
              machineOrBladeNumber,
              restoreOrRemoveText,
            })}
          </ModalBody>
          <ButtonWrapper>
            <Button
              onClick={restoreButton ? handleRestore : handleArchive}
              label={restoreButton ? t('common:restore') : t('common:archive')}
              type="button"
              size="md"
              variant="outlined--secondary"
            />
            <Button
              onClick={handleshowArchive}
              label={t('common:cancel')}
              type="submit"
              size="md"
              variant="outlined--tertiary"
            />
          </ButtonWrapper>
        </InnerModal>
      </WrapperModal>
    </div>
  )
}

ArchiveModal.defaultProps = {
  bladeNmbr: null,
  machineName: null,
  restoreButton: null,
  showMachineManagement: false,
}

ArchiveModal.propTypes = {
  handleArchive: PropTypes.func.isRequired,
  bladeNmbr: PropTypes.number,
  handleshowArchive: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool,
  machineName: PropTypes.string,
  restoreButton: PropTypes.string,
  handleRestore: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  bladeNmbr:
    state.form.newBladeForm &&
    state.form.newBladeForm.values.blade &&
    state.form.newBladeForm.values.blade.sknro,
  showMachineManagement: state.MachineManagement.showMachineManagement,
  machineName:
    state.form.MachineManagementForm &&
    state.form.MachineManagementForm.values.machine &&
    state.form.MachineManagementForm.values.machine.name,
  restoreButton:
    (state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.archived) ||
    (state.form.MachineManagementForm &&
      state.form.MachineManagementForm.values.machine &&
      state.form.MachineManagementForm.values.machine.archived),
})

export default connect(mapStateToProps)(
  withTranslation(['modal', 'common'])(ArchiveModal)
)
