/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import 'react-table/react-table.css'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive } from '@fortawesome/free-solid-svg-icons'

import {
  updateBlade as updateBladeAction,
  fetchEanCodes,
} from './BladeTableAction'
import { fetchBladeCoils } from '../../coatingMachineAction'
import { clearDataFalse as clearDataFalseAction } from '../../../newBladeForm/newBladeFormAction'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

class BladeTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRow: null,
    }
  }

  getTableProps = () => {
    return {
      style: {
        border: '8px solid #d2d2d2',
      },
    }
  }

  render() {
    const {
      filterBladesWithRole,
      t,
      // eslint-disable-next-line no-shadow
      fetchBladeCoils,
      updateBlade,
      showMachineManagement,
      filterMachines,
      clearDataFalse,
      previewUoM,
      machineUoM,
    } = this.props

    const { selectedRow } = this.state

    const isPreviewMode = previewUoM !== machineUoM

    const tableSize = showMachineManagement
      ? filterMachines.length < 13
        ? 13
        : filterMachines.length
      : filterBladesWithRole.length < 13
      ? 13
      : filterBladesWithRole.length
    return (
      <ReactTableFixedColumns
        data={
          showMachineManagement
            ? filterMachines
            : filterMachines && filterBladesWithRole
        }
        pageSize={tableSize}
        columns={
          showMachineManagement
            ? [
                {
                  id: 'No.',
                  Header: t('no'),
                  accessor: 'recid',
                  width: 95,
                  fixed: 'left',
                  sortable: true,
                  Cell: row => {
                    return row.original.archived ? (
                      <div>
                        <span className="isArchived">
                          <FontAwesomeIcon icon={faArchive} />
                        </span>
                        {row.original.recid}
                      </div>
                    ) : (
                      <div>{row.original.recid}</div>
                    )
                  },
                },
                {
                  Header: t('name'),
                  accessor: 'name',
                  sortable: false,
                  Cell: row => {
                    return <div>{row.value}</div>
                  },
                },
              ]
            : [
                {
                  id: 'No.',
                  Header: t('no'),
                  accessor: 'sknro',
                  width: 95,
                  fixed: 'left',
                  sortable: true,
                  Cell: row => {
                    return row.original.archived ? (
                      <div>
                        <span className="isArchived">
                          <FontAwesomeIcon icon={faArchive} />
                        </span>
                        {row.original.sknro}
                      </div>
                    ) : (
                      <div>{row.original.sknro}</div>
                    )
                  },
                },
                {
                  Header: 'EAN',
                  accessor: 'ean',
                  width: 130,
                  sortable: false,
                  Cell: row =>
                    row.original.eanCodes && (
                      <div>
                        {row.original.eanCodes.map((eanCode, index) => (
                          <div key={`${eanCode}${index}`}>{eanCode}</div>
                        ))}
                      </div>
                    ),
                },
                {
                  Header: t('coil_plural'),
                  accessor: 'coils',
                  width: 110,
                  sortable: false,
                  Cell: row =>
                    row.value && (
                      <div>
                        {row.value.map((coil, index) => (
                          <div key={`${index}${coil}`}>{coil}</div>
                        ))}
                      </div>
                    ),
                },
                {
                  Header: t('customer'),
                  accessor: 'customers',
                  sortable: false,
                  Cell: row =>
                    row.original.customers && (
                      <div>
                        {row.original.customers.map((customer, index) => (
                          <div key={`${index}${customer}`}>{customer}</div>
                        ))}
                      </div>
                    ),
                },
              ]
        }
        defaultSorted={[
          {
            id: 'No.',
            asc: true,
          },
        ]}
        showPaginationBottom={false}
        getTdProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            if (!showMachineManagement) {
              return {
                onClick: (e, handleOriginal) => {
                  if (isPreviewMode) {
                    return
                  }

                  this.setState({
                    selectedRow: rowInfo.original.skid,
                  })
                  if (handleOriginal) {
                    handleOriginal()
                    clearDataFalse()
                    fetchBladeCoils(rowInfo.original.skid)
                  }
                },
                style: {
                  backgroundColor:
                    rowInfo.original.skid === selectedRow
                      ? '#CEE2BF'
                      : 'inherit',
                  cursor: isPreviewMode ? 'no-drop' : 'pointer',
                  color: rowInfo.original.archived && 'orange',
                },
              }
            }
            return {
              onClick: (e, handleOriginal) => {
                this.setState({
                  selectedRow: rowInfo.original.recid,
                })
                if (handleOriginal) {
                  handleOriginal()
                  clearDataFalse()
                  updateBlade(rowInfo.original)
                }
              },

              style: {
                backgroundColor:
                  rowInfo.original.recid === selectedRow
                    ? '#CEE2BF'
                    : 'inherit',
                color: rowInfo.original.archived && 'orange',
              },
            }
          }
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        style={{
          height: '500px', // This will force the table body to overflow and scroll, since there is not enough room
          flexGrow: 2,
        }}
      />
    )
  }
}
BladeTable.defaultProps = {
  filterBladesWithRole: PropTypes.arrayOf(),
  previewUoM: null,
  machineUoM: null,
}

BladeTable.propTypes = {
  filterBladesWithRole: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      EAN: PropTypes.arrayOf(PropTypes.number),
      bands: PropTypes.string,
      stripes: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  updateBlade: PropTypes.func.isRequired,
  // t: PropTypes.func.isRequired,
  clearDataFalse: PropTypes.func.isRequired,
  fetchBladeCoils: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  filterMachines: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
    .isRequired,
  t: PropTypes.func.isRequired,
  previewUoM: PropTypes.number,
  machineUoM: PropTypes.number,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  showMachineManagement: state.MachineManagement.showMachineManagement,
  machineList: state.coatingMachine.machineList,
  userLevel: state.AppUser.userLevel,
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})
// eslint-disable-next-line no-class-assign
BladeTable = connect(mapStateToProps, {
  updateBlade: updateBladeAction,
  fetchEanCodes,
  clearDataFalse: clearDataFalseAction,
  fetchBladeCoils,
})(BladeTable)
export default withTranslation(['common', 'bladeList'])(BladeTable)
