/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues, formValueSelector } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { convertToInches, convertToMm } from '../../../../utils/convertUoM'
import {
  calculateAdhesive,
  calculateOverallwidth,
} from './BladeVisualisationLegendAction'

class BladeVisualisationLegend extends React.Component {
  componentDidMount() {
    const value = this.calculateAdhesivePercentage()
    this.props.calculateOverallwidth(value.overallWidth)
    this.props.calculateAdhesive(value.adhesivePercentage)
  }

  componentDidUpdate(prevProps) {
    const { stripeWidth } = this.props
    if (prevProps.stripeWidth !== stripeWidth) {
      const value = this.calculateAdhesivePercentage()
      this.props.calculateOverallwidth(value.overallWidth)
      this.props.calculateAdhesive(value.adhesivePercentage)
    }
  }

  calculateAdhesivePercentage = () => {
    const { stripeWidth } = this.props
    const stripeWidthExists = stripeWidth
      ? stripeWidth.coilWithStripes
      : [{ coil: { amount: 1 }, coilStripes: [1] }]
    const stripeArrays = stripeWidthExists.map(item =>
      item !== undefined ? item.stripes : null
    )
    // Calculating number of coils
    const amountOfCoils = stripeWidthExists.map(item =>
      item !== undefined &&
      item.coil.amount !== undefined &&
      item.amount !== null
        ? item.coil.amount
        : 0
    )
    // converting stripe values from strings to numbers
    const stringToNumber = stripeArrays
      ? stripeArrays.map(array =>
          array !== undefined && array !== null
            ? array.map(item => (item !== undefined ? parseFloat(item) : null))
            : null
        )
      : null
    // adding all stripes arrays to a single array
    const addWidthStripes = stringToNumber.map(item =>
      item !== undefined && item !== null
        ? item.reduce((acc, val) => acc + val, 0)
        : null
    )
    // multiplying amounts of coils with stripes
    const result = []
    for (let i = 0; i < amountOfCoils.length; i++) {
      result.push(amountOfCoils[i] * addWidthStripes[i])
    }
    const overallWidth =
      result && result[0] ? result.reduce((acc, val) => acc + val) : null
    // result to overall width
    // const overallWidth = this.calculateOverallWidth()
    // Calculating adhesive surface

    // IF first adhesive is select
    // Every even index
    const firstAdhesive = stringToNumber.map(item =>
      item !== undefined && item !== null
        ? item.filter((_element, index) => {
            return index % 2 === 0
          })
        : null
    )
    // IF first no adhesive is select
    // every odd index
    const noAdhesive = stringToNumber.map(item =>
      item !== undefined && item !== null
        ? item.filter((_element, index) => {
            return index % 2 === 1
          })
        : null
    )
    const adhesiveArr = []
    for (let i = 0; i < stripeWidthExists.length; i++) {
      if (stripeWidthExists[i].coil.firstTrackGlued === true) {
        adhesiveArr.push(firstAdhesive[i])
      } else {
        adhesiveArr.push(noAdhesive[i])
      }
    }

    // Adding values from every coil array
    const allAdhesive = adhesiveArr.map(item =>
      item !== undefined && item !== null
        ? item.reduce((acc, val) => acc + val, 0)
        : null
    )
    // Multiplying stripes array with amount of coils
    const allAdhesiveArray = []
    for (let i = 0; i < amountOfCoils.length; i++) {
      allAdhesiveArray.push(amountOfCoils[i] * allAdhesive[i])
    }
    // Adding all values in the single value and calculating percentage
    const adhesiveVal = allAdhesiveArray.reduce((acc, val) => acc + val, 0)
    // eslint-disable-next-line no-restricted-globals
    const adhesivePercentage = isNaN((adhesiveVal * 100) / overallWidth)
      ? 0
      : Math.round((adhesiveVal * 100) / overallWidth)
    const overallWidthAndAdhesive = {
      overallWidth,
      adhesivePercentage,
    }
    return overallWidthAndAdhesive
  }

  render() {
    const { margins, netWidth, t, machineUoM, previewUoM } = this.props
    const { overallWidth, adhesivePercentage } = this.props

    // Conversion of coil net width according to UoM
    let convertedNetWidth

    if (machineUoM !== previewUoM && machineUoM === 0) {
      convertedNetWidth = convertToInches(netWidth)
    } else if (machineUoM !== previewUoM && machineUoM === 1) {
      convertedNetWidth = convertToMm(netWidth)
    } else {
      convertedNetWidth = netWidth
    }

    const parsedOverallWidth =
      previewUoM === 1 ? parseInt(overallWidth * 32, 10) / 32 : overallWidth

    const mmOrin = previewUoM === 0 ? 'mm' : 'in'
    return (
      <div className="legend">
        <div className="legend__data">
          <div className="legend__data__square slitting" />
          <div className="legend__data__text">{t('slittingPointForCoil')}</div>
          <div className="legend__data__square nonAdhesive" />
          <div className="legend__data__text">{t('nonadhesiveStripe')}</div>

          <div className="legend__data__square adhesive" />
          <div className="legend__data__text">{t('adhesiveStripe')}</div>
          {margins ? (
            <>
              <div className="legend__data__square margins" />
              <div>{t('slittingMargin')}</div>
            </>
          ) : null}
        </div>
        <div className="legend__widthAdhesive">
          <div className="legend__widthAdhesive text">{t('overallWidth')}</div>
          {parsedOverallWidth > convertedNetWidth ? (
            <FontAwesomeIcon
              className="red-text"
              icon={faExclamationTriangle}
            />
          ) : (
            <div className="icon__placeholder" />
          )}
          <div
            className={
              parsedOverallWidth > convertedNetWidth
                ? 'red-text overallWidth'
                : 'overallWidth'
            }
          >
            {parsedOverallWidth} / {convertedNetWidth} {mmOrin}
          </div>
          <div className="legend__widthAdhesive margin__left text">
            {t('adhesiveAmount')}
          </div>
          <div>{adhesivePercentage} %</div>
        </div>
      </div>
    )
  }
}

BladeVisualisationLegend.defaultProps = {
  stripeWidth: null,
  margins: null,
}

BladeVisualisationLegend.propTypes = {
  stripeWidth: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  margins: PropTypes.number,
  t: PropTypes.func.isRequired,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  stripeWidth: getFormValues('coilForm')(state),
  overallWidth: state.BladeVisualisationLegendReducer.overallWidth,
  adhesivePercentage: state.BladeVisualisationLegendReducer.adhesivePercentage,
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})

export default connect(mapStateToProps, {
  calculateAdhesive,
  calculateOverallwidth,
})(withTranslation(['common'])(BladeVisualisationLegend))
