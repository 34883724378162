import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import store from '../../../../index'

// eslint-disable-next-line no-undef
const modalRoot = document.getElementById('modal')

class WrapperModal extends React.Component {
  // eslint-disable-next-line no-undef
  el = document.createElement('div')

  componentDidMount() {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  render() {
    const { children } = this.props
    return ReactDOM.createPortal(
      <Provider store={store}>
        <div className="modalWrapper">{children}</div>
      </Provider>,
      this.el
    )
  }
}

WrapperModal.propTypes = {
  children: PropTypes.node,
}

WrapperModal.defaultProps = {
  children: null,
}

export default WrapperModal
