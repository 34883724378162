/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../common/components/button/Button'

const DuplicateDetection = ({
  duplicates,
  bladeNumber,
  closeDuplicateDetection,
  t,
}) => {
  return (
    <div className="duplicates">
      <div className="duplicates__items">
        <FontAwesomeIcon
          size="lg"
          style={{
            color: 'red',
            paddingRight: '1rem',
            position: 'relative',
            top: '1rem',
          }}
          icon={faExclamationTriangle}
        />
        <div style={{ margin: 'auto' }}>
          <span className="duplicates__title">
            <Trans t={t} ns={'duplication'} i18nKey="duplication_content">
              Blade <strong>{{ bladeNumber }}</strong> has same values with
              these blades
            </Trans>
          </span>
          <div className="duplicates__values">
            {duplicates &&
              duplicates.map((duplicate, index) => (
                <strong key={duplicate}>{`${
                  index ? ',' : ''
                } ${duplicate}`}</strong>
              ))}
          </div>
        </div>
      </div>
      <Button
        onClick={closeDuplicateDetection}
        label="&times;"
        type="button"
        size="md--close"
        variant="contained--close"
      />
    </div>
  )
}

DuplicateDetection.defaultProps = {
  duplicates: null,
  bladeNumber: null,
  t: null,
}

DuplicateDetection.propTypes = {
  duplicates: PropTypes.arrayOf(PropTypes.number),
  bladeNumber: PropTypes.number,
  closeDuplicateDetection: PropTypes.func.isRequired,
  t: PropTypes.func,
}

const mapStateToProps = state => ({
  duplicates:
    state.coatingMachine &&
    state.coatingMachine.bladesWithCoils &&
    state.coatingMachine.bladesWithCoils.duplicates,
  bladeNumber:
    state.coatingMachine.bladesWithCoils.blade &&
    state.coatingMachine.bladesWithCoils.blade.sknro,
})

export default connect(mapStateToProps)(DuplicateDetection)
