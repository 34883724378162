/* eslint-disable consistent-return */
export const fourDigits = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 4) {
    return onlyNums
  }
}

export const twoDigits = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) {
    return onlyNums
  }
}

export const thirteenDigits = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 13) {
    return onlyNums
  }
}

export const fiveDecimals = value => {
  const onlyNums = value.replace(/[^0-9.]/g, '') // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = onlyNums.split('.')

  if (sections[0].length > 3) {
    return onlyNums.slice(0, 3)
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return `${sections[0]}.${sections[1].slice(0, 5)}`
    // If original value had a decimal place at the end, add it back
  }
  if (onlyNums.indexOf('.') !== -1) {
    return `${sections[0]}.`
    // Otherwise, return only section
  }
  return sections[0]
}
