import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import WrapperModal from '../modal/WrapperModal'
import ModalBody from '../modal/modalBody/ModalBody'
import ModalHeaderWithCloseButton from '../modal/modalHeader/modalHeaderWithCloseButton/ModalHeaderWithCloseButton'
import ButtonWrapper from '../modal/buttonWrapper/ButtonWrapper'
import Button from '../button/Button'
import ModalTable from './modalTable/ModalTable'
import BladeHistoryWrapper from './bladeHistoryWrapper/BladeHistoryWrapper'

const BladeHistoryModal = props => {
  const { t, onClose, blade } = props

  return (
    <div>
      <WrapperModal>
        <BladeHistoryWrapper>
          <ModalHeaderWithCloseButton>
            <h3>
              {t('change_history')} - {t('common:blade')} {blade && blade}
            </h3>
            <Button
              onClick={onClose}
              label="&times;"
              type="button"
              size="md--close"
              variant="contained--close"
              className="ModalHeaderWithCloseButton__button"
            />
          </ModalHeaderWithCloseButton>
          <ModalBody>
            <ModalTable />
          </ModalBody>
          <ButtonWrapper>
            <Button
              onClick={onClose}
              label={t('close')}
              type="button"
              size="md"
              variant="outlined--secondary"
            />
          </ButtonWrapper>
        </BladeHistoryWrapper>
      </WrapperModal>
    </div>
  )
}

BladeHistoryModal.defaultProps = {
  blade: null,
}

BladeHistoryModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  blade: PropTypes.number,
}

const mapStateToProps = state => ({
  blade:
    state.coatingMachine.bladesWithCoils.blade &&
    state.coatingMachine.bladesWithCoils.blade.sknro,
})

export default connect(mapStateToProps)(
  withTranslation(['historyView', 'common'])(BladeHistoryModal)
)
