import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import Item from '../../../../../../bladeVisulation/components/CoilItem'
import {
  convertToInches,
  convertToMm,
} from '../../../../../../../utils/convertUoM'

function getSum(total, num) {
  return total + Number(num)
}
const CoilVisualization = props => {
  const {
    coilData,
    noLetters,
    color,
    previewUoM,
    machineUoM,
    coilWidth,
  } = props
  const array = []
  if (coilData && coilData) {
    coilData.coilStripes.forEach(itemChild => {
      array.push(itemChild)
    })

    let convertedCoilData

    if (previewUoM === 1 && previewUoM !== machineUoM) {
      convertedCoilData = array.map(stripe => ({
        ...stripe,
        width: convertToInches(stripe.width),
      }))
    } else if (previewUoM === 0 && previewUoM !== machineUoM) {
      convertedCoilData = array.map(stripe => ({
        ...stripe,
        width: convertToMm(stripe.width),
      }))
    } else {
      convertedCoilData = array
    }

    const totalArray = convertedCoilData.map(item => item.width)
    const totalWidth = totalArray.reduce(getSum, 0)
    return (
      <div>
        <div
          className={
            color
              ? 'coilWprOuter__coil-Instructions__no-max-width '
              : 'coilWprOuter__coil-Instructions'
          }
        >
          <div className={noLetters ? 'hide-letter' : 'coilVisualization__A'}>
            A
          </div>
          <div
            className="coilWpr coil-border-left coil-border-right"
            style={{
              borderTop: 'none',
            }}
          >
            <Item
              allStripesArray={convertedCoilData}
              totalWidth={totalWidth}
              changeStripes={() => {}}
              color={color}
              uomPreview={previewUoM}
              coilWidth={coilWidth}
            />

            <div
              style={{
                height: '1rem',
                backgroundColor: `${color}`, // color for instructions modal
                position: 'relative',
                top: '-0.4rem',
              }}
            />
          </div>
          <div className={noLetters ? 'hide-letter' : 'coilVisualization__B'}>
            B
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="coilWprOuter">
      <div className="coilWpr" />
    </div>
  )
}
CoilVisualization.defaultProps = {
  coilData: null,
  noLetters: false,
  color: null,
  previewUoM: null,
  machineUoM: null,
  coilWidth: null,
}
CoilVisualization.propTypes = {
  coilData: PropTypes.shape({
    coil: PropTypes.shape({
      popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    coilStripes: PropTypes.arrayOf(
      PropTypes.shape({
        coilID: PropTypes.number,
        id: PropTypes.number,
        orderNum: PropTypes.number,
        stripeType: PropTypes.number,
        width: PropTypes.number,
      })
    ),
    stripes: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }),
  noLetters: PropTypes.bool,
  color: PropTypes.string,
  previewUoM: PropTypes.number,
  machineUoM: PropTypes.number,
  coilWidth: PropTypes.number,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})

export default connect(mapStateToProps, null)(CoilVisualization)
