import { MANY_COILS_VIEW } from './coatinglayersWrapperAction'

const initialState = {
  manyCoilsView: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MANY_COILS_VIEW:
      return {
        ...state,
        manyCoilsView: action.payload,
      }

    default:
      return state
  }
}
