import { UserAgentApplication } from 'msal'

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  )
}

export const isIE = () => {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') > -1
  const msie11 = ua.indexOf('Trident/') > -1

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11
}

const hostname = window && window.location && window.location.hostname
let clientId
let scopes

if (
  // QA ENVIRONMENT
  hostname === 'labelpga-qa-front.azurewebsites.net' ||
  hostname === 'pga-qa.upmraflatac.com'
) {
  clientId = '7c3bc4fc-3237-4572-961c-93a4a2ea59c3'
  scopes = 'https://upmraflataclabelpga-qa-web.azurewebsites.net/Access.Site'
} else if (
  // PRODUCTION ENVIRONMENT
  hostname === 'labelpga-prod-front.azurewebsites.net' ||
  hostname === 'pga.upmraflatac.com'
) {
  clientId = '693ed351-1a82-4fc1-8c16-51702d301c49'
  scopes = 'https://labelpga.azurewebsites.net/Access.Site'
} else if (
  // DEV ENVIRONMENT
  hostname === 'upmraflataclabelpga-dev-front-web.azurewebsites.net' ||
  hostname === 'labelpga-dev-front.azurewebsites.net' ||
  hostname === 'pga-dev.upmraflatac.com'
) {
  clientId = 'd9eed6b2-61b5-4c13-8dac-c26baae1445b'
  scopes = 'https://upmraflataclabelpga-dev-web.azurewebsites.net/Access.Site'
} else if (hostname === 'localhost') {
  // LOCALHOST ENVIRONMENT
  clientId = 'd9eed6b2-61b5-4c13-8dac-c26baae1445b'
  scopes = 'https://upmraflataclabelpga-dev-web.azurewebsites.net/Access.Site'
}
export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [scopes],
  },
}

export const msalApp = new UserAgentApplication({
  auth: {
    clientId,
    authority:
      'https://login.microsoftonline.com/9eab37f0-91c6-47e3-9c00-fe8544bd272e',
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
    // postLogoutRedirectUri: 'https://www.upmraflatac.com',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE(),
  },
})
