export const CALCULATE_ADHESIVE_PERCENTAGE = 'CALCULATE_ADHESIVE_PERCENTAGE'
export const CALCULATE_OVERALL_WIDTH = 'CALCULATE_OVERALL_WIDTH'

export const calculateAdhesive = value => dispatch => {
  dispatch({
    type: CALCULATE_ADHESIVE_PERCENTAGE,
    payload: value,
  })
}

export const calculateOverallwidth = value => dispatch => {
  dispatch({
    type: CALCULATE_OVERALL_WIDTH,
    payload: value,
  })
}
