import rules from './rbac-rules'

// eslint-disable-next-line no-unused-vars
const check = (rbacRules, role, action, data) => {
  const permissions = rbacRules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true
  }
  return false
}

const Can = props =>
  check(rules, props.role, props.perform, props.data) ? props.yes() : props.no()

Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default Can
