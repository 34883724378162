import { connect } from 'react-redux'
import { getFormValues, formValueSelector } from 'redux-form'

import BladeDrawing from '../components'
import totalWidthWithSlittingAction from '../bladeDrawingAction'

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => {
  return {
    coilData: getFormValues('coilForm')(state),
    bladeWithMargins: getFormValues('newBladeForm')(state).blade.width,
    bladeMirroring: getFormValues('newBladeForm')(state).blade.polarity,
    slitting: getFormValues('newBladeForm')(state).blade.cutting,
    spreading: getFormValues('newBladeForm')(state).blade.spreading,
    bladeWidth: getFormValues('newBladeForm')(state).blade.width,
    previewUoM: uomPreviewSelector(
      state,
      'previewUoMDropDown.unitOfMeasurement'
    ),
    machineUoM:
      state.coatingMachine.selectedUserMachine &&
      state.coatingMachine.selectedUserMachine.unitOfMeasurement,
  }
}
export default connect(mapStateToProps, { totalWidthWithSlittingAction })(
  BladeDrawing
)
