import React from 'react'
import PropTypes from 'prop-types'

/* eslint react/button-has-type: 0 */
const Button = ({
  id,
  size,
  disabled,
  onClick,
  className,
  label,
  icon,
  variant,
  type,
}) => (
  <button
    id={id}
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={`button button--${size} button--${variant} ${className} ${disabled}`}
  >
    {icon && <span className="button--icon">{icon}</span>}
    {label && <span className="button--label">{label}</span>}
  </button>
)

Button.defaultProps = {
  id: null,
  type: 'button',
  size: 'md',
  disabled: false,
  label: null,
  className: null,
  variant: 'contained--primary',
  icon: null,
  onClick: null,
}

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['sm', 'sm-m', 'md', 'md--close', 'lg']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.element]),
  variant: PropTypes.oneOf([
    'contained--primary',
    'contained--secondary',
    'contained--tertiary',
    'contained--danger',
    'contained--close',
    'outlined--primary',
    'outlined--secondary',
    'outlined--tertiary',
    'outlined--danger',
    'outlined--success',
  ]),
}

export default Button
