/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faArchive,
  faEye,
  faTimes,
  faPlus,
  faLayerGroup,
  faCut,
  faDotCircle,
  faIndustry,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'
import renderField from '../../common/components/RenderField'
import RadioButtonField from '../../common/components/RadioButton'
import TextAreaField from '../../common/components/RenderField/Textarea'
import Button from '../../common/components/button/Button'
import WrapperModal from '../../common/components/modal/WrapperModal'
import InstructionsModal from '../../common/components/instructionsModal/instructionsModal/InstructionsModal'
import InstructionsModalInner from '../../common/components/instructionsModal/instructionsModal/InstructionsModalInner/InstructionsModalInner'
import BgBladeModal from '../../common/components/bgBladeModal'
import BgBladeModalInner from '../../common/components/bgBladeModal/bgBladeModalInner/BgBladeModalInner'
import { validate } from '../../../utils/validate'
import { convertToInches, convertToMm } from '../../../utils/convertUoM'
import NewBladeModal from '../../common/components/newBladeModal/NewBladeModal'
import ArchiveModal from '../../common/components/archiveModal/ArchiveModal'
import BladeHistoryModal from '../../common/components/bladeHistoryModal/BladeHistoryModal'
import RemoteSubmitButton from '../remoteSubmitButton/RemoteSubmitButton'
import {
  fourDigits,
  thirteenDigits,
  fiveDecimals,
} from '../../../utils/fieldNormalizing'
import AppWideSpinner from '../../common/components/spinner/appWideSpinner/AppWideSpinner'
import Checkbox from '../../common/components/checkbox/CheckBox'
import Can from '../../../CanAccess'

class NewBladeForm extends Component {
  constructor(props) {
    super(props)
    this.coatingLayersChange = this.coatingLayersChange.bind(this)
    this.state = {
      coatingNotesForm: true,
      slittingNotesForm: false,
      fabricationNotesForm: false,
      perforationNotesForm: false,
      showNewModal: false,
      showArchiveModal: false,
      showBladeImageModal: false,
      showBladeHistoryModal: false,
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      coatingLayerButton: true,
    }
  }

  componentDidMount() {
    const { fetchCustomers, clearDataTrue } = this.props
    fetchCustomers()
    clearDataTrue()
  }

  componentDidUpdate(prevProps) {
    const {
      change,
      selectedFactory,
      selectedUserMachine,
      previewUoM,
      bladeWidthCurrent,
      spreadingMargins,
      slittingMargins,
      bladeId,
      unitOfMeasurement,
      bladeWidthOriginal,
      slittingMarginsOriginal,
      spreadingMarginsOriginal,
    } = this.props
    if (
      (prevProps.selectedFactory &&
        prevProps.selectedFactory.id !== selectedFactory.id) ||
      (prevProps.selectedUserMachine &&
        prevProps.selectedUserMachine.recid !== selectedUserMachine.recid)
    ) {
      this.clearData()
    }
    if (
      (prevProps.previewUoM !== null &&
        prevProps.previewUoM !== previewUoM &&
        prevProps.unitOfMeasurement === unitOfMeasurement) ||
      (unitOfMeasurement !== previewUoM &&
        prevProps.bladeId !== bladeId &&
        prevProps.unitOfMeasurement === unitOfMeasurement)
    ) {
      if (
        prevProps.previewUoM !== previewUoM &&
        previewUoM === unitOfMeasurement
      ) {
        change('blade.width', bladeWidthOriginal)
        change('blade.cutting', slittingMarginsOriginal)
        change('blade.spreading', spreadingMarginsOriginal)
      } else {
        // change blade width on uom change
        this.changeUoMFieldValues(previewUoM, 'blade.width', bladeWidthCurrent)

        // change slitting margins on uom change
        this.changeUoMFieldValues(previewUoM, 'blade.cutting', slittingMargins)

        // change spreading margins
        this.changeUoMFieldValues(
          previewUoM,
          'blade.spreading',
          spreadingMargins
        )
      }
    }
  }

  handleClickLaminationNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: true,
      slittingNotesForm: false,
      perforationNotesForm: false,
      fabricationNotesForm: false,
    })
  }

  handleClickSlittingNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: false,
      slittingNotesForm: true,
      perforationNotesForm: false,
      fabricationNotesForm: false,
    })
  }

  handleClickFabricationNotes = e => {
    e.preventDefault()
    this.setState({
      coatingNotesForm: false,
      slittingNotesForm: false,
      perforationNotesForm: false,
      fabricationNotesForm: true,
    })
  }

  handleClickPerforationNotes = e => {
    e.preventDefault()
    const { perforation } = this.props
    if (perforation) {
      this.setState({
        coatingNotesForm: false,
        slittingNotesForm: false,
        perforationNotesForm: true,
        fabricationNotesForm: false,
      })
    }
  }

  handleshowNewModal = () => {
    this.setState(prevState => ({
      showNewModal: !prevState.showNewModal,
    }))
  }

  handleshowArchiveModal = () => {
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  handleshowBladeHistoryModal = () => {
    this.setState(prevState => ({
      showBladeHistoryModal: !prevState.showBladeHistoryModal,
    }))
  }

  handleArchive = () => {
    const { archiveSelectedBlade, bladeId } = this.props
    archiveSelectedBlade(bladeId)
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  handleRestore = () => {
    const { restoreSelectedBlade, bladeId } = this.props
    restoreSelectedBlade(bladeId)
    this.setState(prevState => ({
      showArchiveModal: !prevState.showArchiveModal,
    }))
  }

  clearData = () => {
    const { clearDataTrue } = this.props
    clearDataTrue()
  }

  handleshowIstructionsModal = () => {
    const { isInstructionsModalOpen, instructionsModalOpen } = this.props
    isInstructionsModalOpen(!instructionsModalOpen)
  }

  handleshowBladeImageModal = () => {
    this.setState(prevState => ({
      showBladeImageModal: !prevState.showBladeImageModal,
    }))
  }

  handleCustomerSuggestions = e => {
    const { customers: suggestions, change } = this.props
    const userInput = e.currentTarget.value
    // Filter our suggestions that don't contain the user's input
    if (suggestions && userInput.length >= 3) {
      const filteredSuggestions = suggestions.filter(suggestion => {
        if (suggestion) {
          return (
            suggestion.customerName
              .toLowerCase()
              .indexOf(userInput.toLowerCase()) > -1
          )
        }
        return null
      })
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
      })
      change('customerField', userInput)
    } else {
      this.setState({
        showSuggestions: false,
      })
    }
  }

  onSuggestionClick = e => {
    const { change } = this.props
    const input = e.currentTarget.innerText
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    })
    change('customerField', input)
  }

  onSuggestionKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state
    const { change } = this.props
    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
      })
      change('customerField', filteredSuggestions[activeSuggestion])
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 })
    }
  }

  handleAddedCustomers = val => {
    const { change, customerValue, allCustomers, customerList } = this.props
    const existingCustomer = allCustomers.find(
      customer => customer.customerName === customerValue
    )
    const newCustObj = {
      id: 0,
      custNumber: null,
      customerName: val,
      inserted: '2000-10-10',
    }
    const custObj = existingCustomer || newCustObj
    if (val) {
      const array = [...customerList, custObj]
      change('customerList', array)
      change('customerField', '')
    }
  }

  handleRemoveCustomer = idx => {
    const { change, customerList } = this.props

    const array = customerList.slice(0, idx).concat(customerList.slice(idx + 1))
    change('customerList', array)
  }

  handleAddEanCode = eanCodeVal => {
    const { bladeNmbr, eanCodesBlade, change } = this.props
    const eanCodesObj = {
      id: 0,
      skid: bladeNmbr || 0,
      eanCode: eanCodeVal,
    }
    // eslint-disable-next-line no-unused-expressions
    eanCodesBlade.push(eanCodesObj)

    change('blade.eanCodes', eanCodesBlade)
    change('eanField', '')
  }

  handleRemoveEan = (eanCode, index) => {
    const { eanCodesBlade, change, removedEanCodes } = this.props
    const array = eanCodesBlade
      .slice(0, index)
      .concat(eanCodesBlade.slice(index + 1))
    change('blade.eanCodes', array)
    if (eanCode.id !== 0) {
      removedEanCodes.push(eanCode)
    }
  }

  handleAddCoatingLayer = coatingVal => {
    const { bladeId, coatingLayersList, change } = this.props
    const coatingLayersObj = {
      id: 0,
      skid: bladeId || 0,
      ordNum: coatingLayersList.length,
      coating: parseInt(coatingVal, 10),
    }
    // eslint-disable-next-line no-unused-expressions
    coatingLayersList.push(coatingLayersObj)
    change('blade.coatingLayers', coatingLayersList)
    change('coatingLayersField', '')
    // eslint-disable-next-line react/no-unused-state
    this.setState({ coatingLayerButton: true })
  }

  handleRemoveCoatingLayer = (coatingLayer, index) => {
    const { coatingLayersList, change, removedCoatingLayers } = this.props
    const array = coatingLayersList
      .slice(0, index)
      .concat(coatingLayersList.slice(index + 1))
    change('blade.coatingLayers', array)
    if (coatingLayer.id !== 0) {
      removedCoatingLayers.push(coatingLayer)
    }
  }

  handleLoadLocalFile = event => {
    const { saveFileToServer, bladeId, t } = this.props
    event.preventDefault()

    const reader = new FileReader()
    const file = event.target.files[0]
    if (file) {
      reader.onloadend = () => {
        // file in MB
        if (reader.result.length / 1000000 < 11) {
          // restrict file name on 255 char.
          if (file.name.length > 255) {
            file.name.substring(255, file.name.length)
          }
          saveFileToServer(reader.result, file.name, bladeId)
        } else {
          toast.warn(t('toaster:too_big_file'))
        }
      }
      reader.readAsDataURL(file)
    }
    event.target.value = ''
    return null
  }

  handleGetFile = async id => {
    const { getFileAction } = this.props
    const file = await getFileAction(id)

    const downloadLink = document.createElement('a')

    downloadLink.href = file.fileContents
    downloadLink.download = file.fileName
    downloadLink.click()
  }

  handleRemoveFile = (bladeIdParam, fileId) => {
    const { removeFileAction, bladeId } = this.props
    removeFileAction(bladeId, fileId)
  }

  coatingLayersChange() {
    this.setState({ coatingLayerButton: false })
  }

  // selectMaterial(e) {
  //   const { change } = this.props
  //   change('blade.bladeMaterial', e.target.value)
  // }

  changeUoMFieldValues = (uom, fieldName, currentValue) => {
    const { change } = this.props
    let valueForWidth
    if (uom === 1) {
      valueForWidth = convertToInches(currentValue)
    } else if (uom === 0) {
      valueForWidth = convertToMm(currentValue)
    }
    change(fieldName, valueForWidth)
  }

  render() {
    const {
      t,
      author,
      bladeNmbr,
      bladeId,
      created,
      customerValue,
      customerList,
      coatingLayersValue,
      coatingLayersList,
      showLoading,
      change,
      userLevel,
      eanCodesBlade,
      eanValue,
      backingOutside,
      isArchived,
      perforation,
      hasCoatingNotes,
      hasSlittingNotes,
      hasFabricationNotes,
      hasPerforationNotes,
      coilWithStripes,
      previewFiles,
      changeHistoryUser,
      changeHistoryDate,
      materialsFieldList,
      unitOfMeasurement,
      previewUoM,
      slittingMargins,
      spreadingMargins,
      instructionsModalOpen,
    } = this.props
    const bgBlade = true
    const {
      coatingNotesForm,
      showNewModal,
      showArchiveModal,
      showBladeImageModal,
      showBladeHistoryModal,
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      perforationNotesForm,
      slittingNotesForm,
      fabricationNotesForm,
      coatingLayerButton,
    } = this.state
    const readOnlyValue = userLevel === 1
    const newModal = showNewModal ? (
      <NewBladeModal
        handleshowNewModal={this.handleshowNewModal}
        clearDataTrue={this.clearData}
      />
    ) : null

    const archiveModal = showArchiveModal ? (
      <ArchiveModal
        handleshowArchive={this.handleshowArchiveModal}
        handleArchive={this.handleArchive}
        handleRestore={this.handleRestore}
      />
    ) : null

    const instructionsModal = instructionsModalOpen ? (
      <WrapperModal>
        <InstructionsModalInner>
          <InstructionsModal onClose={this.handleshowIstructionsModal} />
        </InstructionsModalInner>
      </WrapperModal>
    ) : null
    const bgBladeImageModal = showBladeImageModal ? (
      <WrapperModal>
        <BgBladeModalInner>
          <BgBladeModal
            bgBlade={bgBlade}
            onClose={this.handleshowBladeImageModal}
            change={change}
          />
        </BgBladeModalInner>
      </WrapperModal>
    ) : null

    const bladeHistoryModal = showBladeHistoryModal ? (
      <BladeHistoryModal onClose={this.handleshowBladeHistoryModal} />
    ) : null
    const submitLoader = showLoading ? <AppWideSpinner /> : null

    let suggestionsListComponent = null
    if (showSuggestions && customerValue) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((item, index) => {
              let className
              if (index === activeSuggestion) {
                className = 'suggestion-active'
              }
              const withSpecialCharacter = customerValue.replace(
                /[.*+?^${}()|[\]\\]/g,
                '\\$&'
              )
              const regex = new RegExp(withSpecialCharacter, 'gi')
              const highlightPattern = (str, pattern) => {
                const splitText = str.split(pattern)

                if (splitText.length <= 1) {
                  return str
                }

                const matches = str.match(pattern)

                return splitText.reduce(
                  (arr, element, idx) =>
                    matches[idx]
                      ? [
                          ...arr,
                          element,
                          <mark
                            className="highlightSuggestion"
                            key={matches[idx]}
                          >
                            {matches[idx]}
                          </mark>,
                        ]
                      : [...arr, element],
                  []
                )
              }

              const val = highlightPattern(item.customerName, regex)
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={className}
                  key={`${item.customerName}${index}`}
                  onClick={this.onSuggestionClick}
                >
                  {val}
                </li>
              )
            })}
          </ul>
        )
      }
    }
    const millimeterOrInch = unitOfMeasurement === 0 ? 'Millimeter' : 'Inch'
    const mmOrin = previewUoM === 0 ? 'mm' : 'in'
    const disableWhenUoMpreview = unitOfMeasurement !== previewUoM
    return (
      <form>
        <div className="bladeForm__buttons">
          <div>
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:edit"
              yes={() => (
                <RemoteSubmitButton
                  disableWhenUoMpreview={disableWhenUoMpreview}
                  saving={bladeNmbr}
                />
              )}
              no={() => null}
            />
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:create"
              yes={() => (
                <Button
                  onClick={this.handleshowNewModal}
                  label={t('common:new')}
                  type="button"
                  size="md"
                  variant="outlined--primary"
                  icon={<FontAwesomeIcon icon={faFile} />}
                  className="margin-left"
                  disabled={disableWhenUoMpreview}
                />
              )}
              no={() => null}
            />
            <Can
              // eslint-disable-next-line jsx-a11y/aria-role
              role={userLevel}
              perform="newBladeForm:archive"
              yes={() => (
                <Button
                  onClick={this.handleshowArchiveModal}
                  label={isArchived ? t('common:restore') : t('common:archive')}
                  type="button"
                  size="md"
                  variant="outlined--secondary"
                  icon={<FontAwesomeIcon icon={faArchive} />}
                  className="margin-left"
                  disabled={
                    bladeId === null || bladeId === 0 || disableWhenUoMpreview
                  }
                />
              )}
              no={() => null}
            />
          </div>
          <div>
            <Button
              onClick={this.handleshowIstructionsModal}
              label={t('instructions')}
              type="button"
              size="md"
              variant="outlined--tertiary"
              icon={<FontAwesomeIcon icon={faEye} />}
            />
            <Button
              onClick={this.handleshowBladeImageModal}
              label={t('bladeImage')}
              type="button"
              size="md"
              variant="outlined--tertiary"
              icon={<FontAwesomeIcon icon={faEye} />}
              className="margin-left"
              disabled={
                bladeId === null ||
                bladeId === 0 ||
                coilWithStripes === null ||
                (coilWithStripes && coilWithStripes.length === 0)
              }
            />
          </div>
        </div>
        <div className="bladeForm">
          <div className="bladeForm__heading">
            <div className="bladeForm__heading__title">
              {t('common:blade')} {bladeNmbr}
            </div>
            {bladeId ? (
              <div className="bladeForm__heading__history">
                {changeHistoryUser ? (
                  <button
                    className="bladeForm__heading__history--button"
                    onClick={this.handleshowBladeHistoryModal}
                    type="button"
                  >
                    {t('historyView:viewChangeHistory')}
                  </button>
                ) : (
                  <div>{t('historyView:no_change_record')}</div>
                )}
                {changeHistoryUser ? (
                  <div>
                    {t('historyView:lastModified')}:{' '}
                    {changeHistoryDate ? (
                      previewUoM === 1 ? (
                        <Moment format="MM.DD.YYYY">{changeHistoryDate}</Moment>
                      ) : (
                        <Moment format="DD.MM.YYYY">{changeHistoryDate}</Moment>
                      )
                    ) : null}{' '}
                    {t('modal:instructionsModal.by')}{' '}
                    {changeHistoryUser && changeHistoryUser}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="bladeForm__wrapper">
            <div className="bladeForm__data">
              <div>
                <div className="select__label">UoM</div>
                <div className="static-value">{millimeterOrInch}</div>
              </div>
              <div>
                <div className="select__label">{t('creator')}</div>
                <div className="static-value">{author || <div>-</div>}</div>
              </div>
              <div>
                <div className="select__label">{t('dateCreated')}</div>
                <div className="static-value">
                  {created ? (
                    previewUoM === 1 ? (
                      <Moment format="MM.DD.YYYY">{created}</Moment>
                    ) : (
                      <Moment format="DD.MM.YYYY">{created}</Moment>
                    )
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
              <div className="bladeForm__inputfieldsMargins__field">
                <Field
                  name="blade.width"
                  type="text"
                  label={t('common:width')}
                  component={renderField}
                  normalize={
                    unitOfMeasurement === 0 ? fourDigits : fiveDecimals
                  }
                  readOnlyValue={readOnlyValue}
                  disabled={disableWhenUoMpreview}
                  className="bladeForm__inputfieldsMargins__width"
                />
                <i className="bladeForm__width__mm">{mmOrin}</i>
              </div>

              <div>
                <div className="select__label">
                  {t('common:spreadingMargin')}
                </div>
                <Field
                  name="blade.spreading"
                  component="select"
                  className="bladeForm__select"
                  disabled={readOnlyValue || disableWhenUoMpreview}
                  parse={val => JSON.parse(val)}
                >
                  <option value={0}>0</option>
                  <option value={unitOfMeasurement === 0 ? 1 : 0.03125}>
                    {unitOfMeasurement === 0 ? 1 : 0.03125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 2 : 0.0625}>
                    {unitOfMeasurement === 0 ? 2 : 0.0625}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 3 : 0.125}>
                    {unitOfMeasurement === 0 ? 3 : 0.125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 4 : 0.15625}>
                    {unitOfMeasurement === 0 ? 4 : 0.15625}
                  </option>
                  {/* This is for previewing Inches */}
                  {previewUoM !== unitOfMeasurement ? (
                    <option value={spreadingMargins}>{spreadingMargins}</option>
                  ) : null}
                </Field>
                <i>{mmOrin}</i>
              </div>
              <div>
                <div className="select__label">
                  {t('common:slittingMargin')}
                </div>
                <Field
                  name="blade.cutting"
                  component="select"
                  className="bladeForm__select"
                  disabled={readOnlyValue || disableWhenUoMpreview}
                  parse={val => JSON.parse(val)}
                >
                  <option value={0}>0</option>
                  <option value={unitOfMeasurement === 0 ? 1 : 0.03125}>
                    {unitOfMeasurement === 0 ? 1 : 0.03125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 2 : 0.0625}>
                    {unitOfMeasurement === 0 ? 2 : 0.0625}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 3 : 0.125}>
                    {unitOfMeasurement === 0 ? 3 : 0.125}
                  </option>
                  <option value={unitOfMeasurement === 0 ? 4 : 0.15625}>
                    {unitOfMeasurement === 0 ? 4 : 0.15625}
                  </option>
                  {/* This is for previewing Inches */}
                  {previewUoM !== unitOfMeasurement ? (
                    <option value={slittingMargins}>{slittingMargins}</option>
                  ) : null}
                </Field>
                <i>{mmOrin}</i>
              </div>
            </div>
            <div className="bladeForm__radioAndCheckboxes">
              <div>
                <h5>{t('backingOutside')}</h5>
                <div className="bladeForm__radioAndCheckboxes__withMargin">
                  <label>
                    <Field
                      name="blade.innerback"
                      disabled={readOnlyValue || disableWhenUoMpreview}
                      component={RadioButtonField}
                      type="radio"
                      value
                      parse={val => JSON.parse(val)}
                      label={t('common:backingOutsideOS')}
                      id="backingOutside"
                      className={
                        backingOutside === true ? 'checked' : 'unchecked'
                      }
                    />{' '}
                  </label>
                </div>
                <div className="bladeForm__radioAndCheckboxes__withMargin">
                  <label>
                    <Field
                      name="blade.innerback"
                      disabled={readOnlyValue || disableWhenUoMpreview}
                      component={RadioButtonField}
                      type="radio"
                      value={false}
                      parse={val => JSON.parse(val)}
                      label={t('common:backingOutsideDS')}
                      id="backingOutside"
                      className={
                        backingOutside === false ? 'checked' : 'unchecked'
                      }
                    />{' '}
                  </label>
                </div>
              </div>
              <div>
                <div className="materials">
                  {materialsFieldList[0] !== undefined && (
                    <>
                      <div className="select__label">
                        <label>{t('common:materials')}</label>
                      </div>
                      <div>
                        <Field
                          name="blade.bladeMaterial"
                          component="select"
                          className="bladeForm__select"
                          disabled={readOnlyValue || disableWhenUoMpreview}
                        >
                          <option />
                          {materialsFieldList.map(item => {
                            let title = ''
                            switch (item) {
                              case 0:
                                title = t('common:greenPe')
                                break
                              case 1:
                                title = t('common:whiteTeflon')
                                break
                              case 2:
                                title = t('common:metalFoil')
                                break
                              case 3:
                                title = t('common:pgSleeve')
                                break
                              case 4:
                                title = t('common:metalShim')
                                break
                              default:
                                title = ''
                            }
                            return (
                              <option value={item} key={`option${item}`}>
                                {title}
                              </option>
                            )
                          })}
                        </Field>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="bladeForm__coatingLayers">
                <h5>{t('coatingLayers')}</h5>
                <Field
                  name="coatingLayersField"
                  component="select"
                  disabled={readOnlyValue || disableWhenUoMpreview}
                  onChange={this.coatingLayersChange}
                  className="bladeForm__select bladeForm__coatingLayers__select"
                >
                  <option value="" disabled />
                  <option value="1">{t('layer.face')}</option>
                  <option value="2">{t('layer.backing')}</option>
                  <option value="3">{t('layer.adhesivePG')}</option>
                  <option value="4">{t('layer.backprint')}</option>
                  <option value="5">{t('layer.silicone')}</option>
                  <option value="6">{t('layer.adhesive')}</option>
                </Field>
                <Can
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role={userLevel}
                  perform="newBladeForm:addEAN"
                  yes={() => (
                    <Button
                      type="button"
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      disabled={coatingLayerButton || disableWhenUoMpreview}
                      variant="outlined--primary"
                      onClick={() =>
                        this.handleAddCoatingLayer(coatingLayersValue)
                      }
                      size="sm"
                      className="bladeForm__EancodesCustomers__input__addInput__addButton"
                    />
                  )}
                  no={() => null}
                />

                {coatingLayersList &&
                  coatingLayersList
                    .sort((a, b) => a.ordNum - b.ordNum)
                    .map((item, index) => {
                      switch (parseInt(item.coating, 10)) {
                        case 1:
                          item.title = t('layer.face')
                          break
                        case 2:
                          item.title = t('layer.backing')
                          break
                        case 3:
                          item.title = t('layer.adhesivePG')
                          break
                        case 4:
                          item.title = t('layer.backprint')
                          break
                        case 5:
                          item.title = t('layer.silicone')
                          break
                        default:
                          item.title = t('layer.adhesive')
                      }
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                          <div className="bladeForm__EancodesCustomers__input__customerList coatingLayersWidth">
                            <div>
                              {index + 1}
                              <span>) </span>
                              {item.title}
                            </div>
                            <Can
                              // eslint-disable-next-line jsx-a11y/aria-role
                              role={userLevel}
                              perform="newBladeForm:addEAN"
                              yes={() => (
                                <Button
                                  type="button"
                                  onClick={() => {
                                    this.handleRemoveCoatingLayer(item, index)
                                  }}
                                  variant="outlined--danger"
                                  size="sm"
                                  icon={<FontAwesomeIcon icon={faTimes} />}
                                  className="bladeForm__EancodesCustomers__input__customerList__removeButton"
                                  disabled={disableWhenUoMpreview}
                                />
                              )}
                              no={() => null}
                            />
                          </div>
                        </div>
                      )
                    })}
              </div>
            </div>
            <div className="bladeForm__radioAndCheckboxes">
              <div>
                <div className="bladeForm__perforation-checkbox">
                  <label>
                    <Field
                      name="blade.perforated"
                      type="checkbox"
                      disabled={readOnlyValue || disableWhenUoMpreview}
                      component={Checkbox}
                      label={t('common:perforation')}
                    />
                  </label>
                </div>
                <div className="bladeForm__rewindable-checkbox">
                  <label>
                    <Field
                      name="blade.urk1"
                      type="checkbox"
                      disabled={readOnlyValue || disableWhenUoMpreview}
                      component={Checkbox}
                      label={t('rewind')}
                    />
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div className="bladeForm__EancodesCustomers">
              <div>
                <div className="bladeForm__EancodesCustomers__input__addInput">
                  <Field
                    name="eanField"
                    type="text"
                    readOnlyValue={readOnlyValue || disableWhenUoMpreview}
                    label={t('common:eanCodes')}
                    component={renderField}
                    normalize={thirteenDigits}
                  />
                  <Can
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role={userLevel}
                    perform="newBladeForm:addEAN"
                    yes={() => (
                      <Button
                        type="button"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        variant="outlined--primary"
                        onClick={() => this.handleAddEanCode(eanValue)}
                        size="sm"
                        className="bladeForm__EancodesCustomers__input__addInput__addButton"
                        disabled={eanValue === null || eanValue === ''}
                      />
                    )}
                    no={() => null}
                  />
                </div>
                {eanCodesBlade &&
                  eanCodesBlade.map((code, idx) => (
                    <div key={idx}>
                      <div className="bladeForm__EancodesCustomers__input__customerList customersWidth">
                        <div>{code.eanCode}</div>
                        <Can
                          // eslint-disable-next-line jsx-a11y/aria-role
                          role={userLevel}
                          perform="newBladeForm:addEAN"
                          yes={() => (
                            <Button
                              type="button"
                              onClick={() => this.handleRemoveEan(code, idx)}
                              variant="outlined--danger"
                              size="sm"
                              icon={<FontAwesomeIcon icon={faTimes} />}
                              className="bladeForm__EancodesCustomers__input__customerList__removeButton"
                              disabled={disableWhenUoMpreview}
                            />
                          )}
                          no={() => null}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="bladeForm__EancodesCustomers__input">
                <div className="bladeForm__EancodesCustomers__input__addInput">
                  <Field
                    name="customerField"
                    type="text"
                    label={t('common:customer_plural')}
                    component={renderField}
                    readOnlyValue={
                      readOnlyValue ||
                      (customerList && customerList.length > 0) ||
                      bladeId === null ||
                      bladeId === 0 ||
                      disableWhenUoMpreview
                    }
                    onChange={this.handleCustomerSuggestions}
                    onKeyDown={this.onSuggestionKeyDown}
                  />
                  <Can
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role={userLevel}
                    perform="newBladeForm:addCustomers"
                    yes={() => (
                      <Button
                        type="button"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        variant="outlined--primary"
                        onClick={() => this.handleAddedCustomers(customerValue)}
                        size="sm"
                        className="bladeForm__EancodesCustomers__input__addInput__addButton"
                        disabled={
                          bladeId === null ||
                          bladeId === 0 ||
                          customerList.length > 0 ||
                          disableWhenUoMpreview
                        }
                      />
                    )}
                    no={() => null}
                  />
                </div>
                {suggestionsListComponent}
                {customerList &&
                  customerList.map((cus, idx) => (
                    <div key={idx}>
                      <div className="bladeForm__EancodesCustomers__input__customerList customersWidth">
                        <div>{cus.customerName}</div>
                        <Can
                          // eslint-disable-next-line jsx-a11y/aria-role
                          role={userLevel}
                          perform="newBladeForm:addCustomers"
                          yes={() => (
                            <Button
                              type="button"
                              onClick={() => this.handleRemoveCustomer(idx)}
                              variant="outlined--danger"
                              size="sm"
                              icon={<FontAwesomeIcon icon={faTimes} />}
                              className="bladeForm__EancodesCustomers__input__customerList__removeButton"
                              disabled={disableWhenUoMpreview}
                            />
                          )}
                          no={() => null}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <hr />
            <div>
              <div className="bladeForm__laminationSlittingNotesButtons">
                <div
                  onClick={this.handleClickLaminationNotes}
                  className={
                    coatingNotesForm
                      ? `pressed ${hasCoatingNotes}`
                      : `default ${hasCoatingNotes}`
                  }
                >
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <button type="button">{t('common:coatingNotes')}</button>
                </div>
                <div
                  onClick={this.handleClickSlittingNotes}
                  className={
                    slittingNotesForm
                      ? `pressed ${hasSlittingNotes}`
                      : `default ${hasSlittingNotes}`
                  }
                >
                  <FontAwesomeIcon icon={faCut} />
                  <button type="button">{t('common:slittingNotes')}</button>
                </div>

                <div
                  onClick={this.handleClickFabricationNotes}
                  className={
                    fabricationNotesForm
                      ? `pressed ${hasFabricationNotes}`
                      : `default ${hasFabricationNotes}`
                  }
                >
                  <FontAwesomeIcon icon={faIndustry} />
                  <button type="button">{t('common:fabricationNotes')}</button>
                </div>

                <div
                  onClick={this.handleClickPerforationNotes}
                  className={
                    perforation
                      ? perforationNotesForm
                        ? `pressed ${hasPerforationNotes}`
                        : `default ${hasPerforationNotes}`
                      : `disabled ${hasPerforationNotes}`
                  }
                >
                  <FontAwesomeIcon icon={faDotCircle} />
                  <button type="button" disabled={!perforation && true}>
                    {t('common:perforationNotes')}
                  </button>
                </div>
              </div>
              {coatingNotesForm ? (
                <div>
                  <Field
                    cols="5"
                    rows="5"
                    name="blade.memo"
                    type="text"
                    placeholder={t('common:coatingNotes')}
                    component={TextAreaField}
                    readOnlyValue={readOnlyValue || disableWhenUoMpreview}
                  />
                </div>
              ) : slittingNotesForm ? (
                <div>
                  <Field
                    cols="5"
                    rows="5"
                    name="blade.slittingNotes"
                    type="text"
                    placeholder={t('common:slittingNotes')}
                    component={TextAreaField}
                    readOnlyValue={readOnlyValue || disableWhenUoMpreview}
                  />
                </div>
              ) : fabricationNotesForm ? (
                <div>
                  <Field
                    cols="5"
                    rows="5"
                    name="blade.fabricationNotes"
                    type="text"
                    placeholder={t('common:fabricationNotes')}
                    component={TextAreaField}
                    readOnlyValue={readOnlyValue || disableWhenUoMpreview}
                  />
                </div>
              ) : (
                <div>
                  <Field
                    cols="5"
                    rows="5"
                    name="blade.perforationNotes"
                    type="text"
                    placeholder={t('common:perforationNotes')}
                    component={TextAreaField}
                    readOnlyValue={readOnlyValue || disableWhenUoMpreview}
                  />
                </div>
              )}
            </div>
            <hr />
            {bladeId !== 0 && (
              <h5 className="bladeForm__add-files__supported">
                {t('common:file_size_supported')}
              </h5>
            )}
            {bladeId !== 0 && (
              <div
                className="bladeForm__add-files"
                style={{
                  justifyContent:
                    userLevel === 1 ? 'flex-end' : 'space-between',
                }}
              >
                <Can
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role={userLevel}
                  perform="newBladeForm:addCustomers"
                  yes={() => (
                    <>
                      <input
                        id="my-upload-btn"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={this.handleLoadLocalFile}
                        // eslint-disable-next-line no-return-assign
                        ref={fileInput => (this.fileInput = fileInput)}
                      />
                      <Button
                        onClick={() => this.fileInput.click()}
                        label={t('common:add_file')}
                        type="button"
                        size="md"
                        variant="outlined--primary"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        className="margin-left backgroundWhite min-width-add-files-button "
                        disabled={disableWhenUoMpreview}
                      />
                    </>
                  )}
                  no={() => null}
                />

                <div className="addFiles">
                  {previewFiles &&
                    previewFiles.map(file => (
                      <div
                        key={file.id}
                        className="bladeForm__EancodesCustomers__input__customerList margin-left-small addFiles__minWidth align-content-files"
                      >
                        <div className="addFiles__minWidth">
                          {file.fileName}
                        </div>
                        <Button
                          type="button"
                          onClick={() => this.handleGetFile(file.id)}
                          variant="outlined--success"
                          size="sm"
                          icon={<FontAwesomeIcon icon={faDownload} />}
                          className="bladeForm__EancodesCustomers__input__addInput__addButton height-file-buttons "
                          disabled={disableWhenUoMpreview}
                        />
                        <Can
                          // eslint-disable-next-line jsx-a11y/aria-role
                          role={userLevel}
                          perform="newBladeForm:addCustomers"
                          yes={() => (
                            <Button
                              type="button"
                              onClick={() => {
                                this.handleRemoveFile(bladeId, file.id)
                              }}
                              variant="outlined--danger"
                              size="sm"
                              icon={<FontAwesomeIcon icon={faTimes} />}
                              className="bladeForm__EancodesCustomers__input__customerList__removeButton height-file-buttons "
                              disabled={disableWhenUoMpreview}
                            />
                          )}
                          no={() => null}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {newModal}
        {archiveModal}
        {instructionsModal}
        {bgBladeImageModal}
        {bladeHistoryModal}
        {submitLoader}
      </form>
    )
  }
}

NewBladeForm.propTypes = {
  author: PropTypes.string,
  created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bladeNmbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  clearDataTrue: PropTypes.func.isRequired,
  customerList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      custNumber: null,
      customerName: PropTypes.string,
      inserted: PropTypes.string,
    })
  ),
  changeHistoryUser: PropTypes.string,
  changeHistoryDate: PropTypes.string,
  customerValue: PropTypes.string,
  fetchCustomers: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.any),
  coatingLayersValue: PropTypes.string,
  showLoading: PropTypes.bool.isRequired,
  eanValue: PropTypes.string,
  saveFileToServer: PropTypes.func.isRequired,
  getFileAction: PropTypes.func.isRequired,
  removeFileAction: PropTypes.func.isRequired,
  eanCodesBlade: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      skid: PropTypes.number,
      eanCode: PropTypes.string,
    })
  ),
  removedEanCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      skid: PropTypes.number,
      eanCode: PropTypes.string,
    })
  ),
  coatingLayersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      skid: PropTypes.number,
      orderNum: PropTypes.number,
      coating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  removedCoatingLayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      skid: PropTypes.number,
      orderNum: PropTypes.number,
      coating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  userLevel: PropTypes.number.isRequired,
  archiveSelectedBlade: PropTypes.func,
  bladeId: PropTypes.number,
  restoreSelectedBlade: PropTypes.func,
  isArchived: PropTypes.string,
  backingOutside: PropTypes.bool,
  hasPerforationNotes: PropTypes.string,
  hasCoatingNotes: PropTypes.string,
  hasSlittingNotes: PropTypes.string,
  hasFabricationNotes: PropTypes.string,
  perforation: PropTypes.bool,
  materialsFieldList: PropTypes.arrayOf(PropTypes.number),
  allCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      custNumber: PropTypes.oneOf([PropTypes.number, null]),
      customerName: PropTypes.string,
      inserted: PropTypes.string,
    })
  ).isRequired,
  coilWithStripes: PropTypes.arrayOf(
    PropTypes.shape({
      coil: PropTypes.shape({
        popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        popid: PropTypes.number,
        skid: PropTypes.number,
        ordnum: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        firstTrackGlued: PropTypes.bool,
        perforated: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      coilStripes: PropTypes.arrayOf(
        PropTypes.shape({
          coilID: PropTypes.number,
          id: PropTypes.number,
          orderNum: PropTypes.number,
          stripeType: PropTypes.number,
          width: PropTypes.number,
        })
      ),
      stripes: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    })
  ),
  isInstructionsModalOpen: PropTypes.func.isRequired,
  previewFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      id: PropTypes.number,
      skid: PropTypes.number,
      timeStamp: PropTypes.string,
    })
  ),
  unitOfMeasurement: PropTypes.number,
  previewUoM: PropTypes.number,
  bladeWidthCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slittingMargins: PropTypes.number,
  spreadingMargins: PropTypes.number,
  selectedFactory: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    factorySiteCode: PropTypes.string,
    id: PropTypes.number,
    plantCodeInSAP: PropTypes.string,
    siteType: PropTypes.string,
  }),
  instructionsModalOpen: PropTypes.bool.isRequired,
}

NewBladeForm.defaultProps = {
  author: '',
  created: '',
  bladeNmbr: '',
  customerValue: '',
  customerList: [],
  customers: '',
  coatingLayersList: [],
  coatingLayersValue: '',
  eanValue: '',
  eanCodesBlade: [],
  removedEanCodes: [],
  removedCoatingLayers: [],
  archiveSelectedBlade: null,
  bladeId: null,
  restoreSelectedBlade: null,
  isArchived: null,
  backingOutside: null,
  hasPerforationNotes: null,
  hasCoatingNotes: null,
  hasSlittingNotes: null,
  hasFabricationNotes: null,
  perforation: null,
  coilWithStripes: null,
  changeHistoryUser: null,
  changeHistoryDate: null,
  materialsFieldList: [],
  previewFiles: null,
  unitOfMeasurement: null,
  previewUoM: null,
  bladeWidthCurrent: null,
  slittingMargins: null,
  spreadingMargins: null,
  selectedFactory: null,
}

// eslint-disable-next-line no-class-assign
NewBladeForm = reduxForm({
  form: 'newBladeForm',
  enableReinitialize: true,
  validate,
})(NewBladeForm)

const selector = formValueSelector('newBladeForm')
const coilSelector = formValueSelector('coilForm')
const uomPreviewSelector = formValueSelector('previewUoMDropDown')
// eslint-disable-next-line no-class-assign
NewBladeForm = connect(state => {
  const customerValue = selector(state, 'customerField')
  const customerList = selector(state, 'customerList')
  const coatingLayersValue = selector(state, 'coatingLayersField')
  const coatingLayersList = selector(state, 'blade.coatingLayers')
  const removedCoatingLayers = selector(state, 'removedCoatingLayers')
  const removedEanCodes = selector(state, 'removedEanCodes')
  const eanCodesBlade = selector(state, 'blade.eanCodes')
  const eanValue = selector(state, 'eanField')
  const backingOutside = selector(state, 'blade.innerback')
  const bladeId = selector(state, 'blade.skid')
  const bladeWidthCurrent = selector(state, 'blade.width')
  const perforation = selector(state, 'blade.perforated')
  const coatingNotes = selector(state, 'blade.memo')
  const spreadingMargins = selector(state, 'blade.spreading')
  const slittingMargins = selector(state, 'blade.cutting')
  const slittingNotes = selector(state, 'blade.slittingNotes')
  const fabricationNotes = selector(state, 'blade.fabricationNotes')
  const perforationNotes = selector(state, 'blade.perforationNotes')
  const bladeMaterialSelected = selector(state, 'blade.bladeMaterial')
  const coilWithStripes = coilSelector(state, 'coilWithStripes')
  const previewUoM = uomPreviewSelector(
    state,
    'previewUoMDropDown.unitOfMeasurement'
  )
  const unitOfMeasurement =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement
  const modifiedArray = []
  let machineMaterials
  if (state.coatingMachine.selectedUserMachine) {
    state.coatingMachine.selectedUserMachine.machineMaterials.forEach(
      element => {
        modifiedArray.push(element.material)
      }
    )
    machineMaterials = modifiedArray
  }
  const allCustomers =
    state.newBladeFormReducer.customers && state.newBladeFormReducer.customers
  const { clearData } = state.newBladeFormReducer
  const machineId =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.recid
  const bladeWidth =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.width
  const bladeCutting =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.cutspace
  const spreadMargin =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.spreadspace
  const rewind =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.urk1
  const perforated =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.perforated
  const backing =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.innerback
  const coatingLayers =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.machineCoatingLayers
  const coatingLayersChanged =
    coatingLayers &&
    coatingLayers.map(coatingLayer => {
      return {
        id: 0,
        skid: 0,
        coating: coatingLayer.coating,
        ordNum: coatingLayer.ordNum,
      }
    })
  const instructionsModalOpen =
    state.newBladeFormReducer && state.newBladeFormReducer.isModalOpen
  const machineCoatingNotes =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.coatingNotes
  const machineSlittingNotes =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.slittingNotes
  const machineFabricationNotes =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.fabricationNotes
  const machinePerforationNotes =
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.perforationNotes
  const previewFiles =
    state.coatingMachine &&
    state.coatingMachine.bladesWithCoils &&
    state.coatingMachine.bladesWithCoils.fileInfo
  const changeHistoryUser =
    state.coatingMachine.bladesWithCoils.changeLogs &&
    state.coatingMachine.bladesWithCoils.changeLogs[0] &&
    state.coatingMachine.bladesWithCoils.changeLogs[
      state.coatingMachine.bladesWithCoils.changeLogs.length - 1
    ].userName
  const changeHistoryDate =
    state.coatingMachine.bladesWithCoils.changeLogs &&
    state.coatingMachine.bladesWithCoils.changeLogs[0] &&
    state.coatingMachine.bladesWithCoils.changeLogs[
      state.coatingMachine.bladesWithCoils.changeLogs.length - 1
    ].changeTime
  const bladeWidthOriginal =
    state.previewReducer.bladeData &&
    state.previewReducer.bladeData.blade.blade &&
    state.previewReducer.bladeData.blade.blade.width
  const slittingMarginsOriginal =
    state.previewReducer.bladeData &&
    state.previewReducer.bladeData.blade.blade &&
    state.previewReducer.bladeData.blade.blade.cutting
  const spreadingMarginsOriginal =
    state.previewReducer.bladeData &&
    state.previewReducer.bladeData.blade.blade &&
    state.previewReducer.bladeData.blade.blade.spreading
  const initial = clearData
    ? {
        blade: {
          skid: 0,
          sknro: 0,
          name: null,
          created: null,
          author: null,
          ean: null,
          memo: machineCoatingNotes,
          structure: null,
          innerback: backing,
          layerlaminat: false,
          layer1: null,
          layer2: null,
          layer3: null,
          urk1: rewind,
          structure2: null,
          spreading: spreadMargin,
          materialsField: null,
          kalli: null,
          cutting: bladeCutting,
          width: bladeWidth,
          polarity: 0,
          archived: null,
          machineid: machineId,
          perforated,
          slittingNotes: machineSlittingNotes,
          fabricationNotes: machineFabricationNotes,
          perforationNotes: machinePerforationNotes,
          coatingLayers: coatingLayersChanged,
          eanCodes: [],
          // customers: [],
        },
      }
    : {
        blade: state.coatingMachine.bladesWithCoils.blade,
        customerList: state.coatingMachine.bladesWithCoils.customers
          ? state.coatingMachine.bladesWithCoils.customers
          : [],
        removedEanCodes: [],
        removedCoatingLayers: [],
      }
  return {
    initialValues: initial,
    author: clearData
      ? ''
      : state.coatingMachine.bladesWithCoils.blade &&
        state.coatingMachine.bladesWithCoils.blade.author,
    created: clearData
      ? null
      : state.coatingMachine.bladesWithCoils.blade &&
        state.coatingMachine.bladesWithCoils.blade.created,
    bladeNmbr: clearData
      ? null
      : state.coatingMachine.bladesWithCoils.blade &&
        state.coatingMachine.bladesWithCoils.blade.sknro,
    eanCodes:
      state.coatingMachine.bladesWithCoils.blade &&
      state.coatingMachine.bladesWithCoils.blade.eanCodes,
    isArchived:
      state.coatingMachine.bladesWithCoils.blade &&
      state.coatingMachine.bladesWithCoils.blade.archived,
    instructionsModalOpen,
    allCustomers,
    customerValue,
    customerList,
    coatingLayersValue,
    coatingLayersList,
    showLoading: state.newBladeFormReducer.showLoading,
    eanCodesBlade,
    eanValue,
    removedEanCodes,
    removedCoatingLayers,
    showMachineManagement: state.MachineManagement.showMachineManagement,
    clearData,
    backingOutside,
    bladeId,
    bladeWidthCurrent,
    spreadingMargins,
    slittingMargins,
    perforation,
    hasCoatingNotes: coatingNotes && 'hasCoatingNotes',
    hasPerforationNotes: perforationNotes && 'hasPerforationNotes',
    hasFabricationNotes: fabricationNotes && 'hasFabricationNotes',
    hasSlittingNotes: slittingNotes && 'hasSlittingNotes',
    coilWithStripes,
    previewFiles,
    changeHistoryUser,
    changeHistoryDate,
    materialsFieldList: machineMaterials || [],
    bladeMaterialSelected,
    previewUoM,
    unitOfMeasurement,
    bladeWidthOriginal,
    slittingMarginsOriginal,
    spreadingMarginsOriginal,
  }
})(NewBladeForm)

export default withTranslation([
  'bladeForm',
  'common',
  'historyView',
  'modal',
  'toaster',
])(NewBladeForm)
