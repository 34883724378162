import { toast } from 'react-toastify'
import i18n from './../../i18n'
import {
  FETCH_FACTORIES_START,
  FETCH_FACTORIES_SUCCESS,
  FETCH_FACTORIES_FAILED,
  FETCH_MACHINE_START,
  FETCH_MACHINE_SUCCESS,
  FETCH_MACHINE_FAILED,
  FETCH_BLADES_START,
  FETCH_BLADES_SUCCESS,
  FETCH_BLADES_FAILED,
  FETCH_BLADECOIL_START,
  FETCH_BLADECOIL_SUCCESS,
  FETCH_BLADECOIL_FAILED,
  SELECT_FACTORY,
  SELECT_MACHINE_START,
  SELECT_MACHINE_SUCCESS,
  SELECT_MACHINE_FAILED,
} from './coatingMachineAction'

import { CLEAR_DATA_TRUE } from '../machineManagmentForm/MachineManagementFormAction'
import {
  SAVE_FILE_START,
  SAVE_FILE_SUCCESS,
  SAVE_FILE_FAIL,
} from '../newBladeForm/newBladeFormAction'

const initialState = {
  showLoading: false,
  showLoadingBlades: false,
  factoriesList: [],
  machineList: [],
  machineListWithUnarchived: [],
  showError: false,
  blades: [],
  bladesWithCoils: [],
  selectedFactory: null,
  selectedUserMachine: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_MACHINE_START:
    case FETCH_FACTORIES_START:
    case FETCH_MACHINE_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case FETCH_MACHINE_SUCCESS:
      return {
        ...state,
        showLoading: false,
        machineList: action.payload,
        machineListWithUnarchived: action.payload.filter(
          machine => !machine.archived
        ),
      }
    case SELECT_MACHINE_SUCCESS:
      return {
        ...state,
        showLoading: false,
        selectedUserMachine: action.payload,
      }
    case FETCH_FACTORIES_SUCCESS:
      return {
        ...state,
        showLoading: false,
        factoriesList: action.payload,
      }
    case SELECT_MACHINE_FAILED:
    case FETCH_FACTORIES_FAILED:
    case SAVE_FILE_FAIL:
    case FETCH_BLADECOIL_FAILED:
    case FETCH_MACHINE_FAILED:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
        showError: action.payload,
      }
    case FETCH_BLADES_START:
      return {
        ...state,
        showLoadingBlades: true,
        showError: false,
      }
    case FETCH_BLADES_SUCCESS:
      return {
        ...state,
        showLoadingBlades: false,
        blades: action.payload.map(item => {
          return { ...item, customers: item.customers, coils: item.coils }
        }),
      }
    case SELECT_FACTORY:
      return {
        ...state,
        selectedFactory: action.payload,
      }
    case FETCH_BLADECOIL_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case FETCH_BLADES_FAILED:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
        showError: action.payload,
      }
    case FETCH_BLADECOIL_SUCCESS:
      return {
        ...state,
        showLoading: false,
        bladesWithCoils: action.payload,
      }
    case CLEAR_DATA_TRUE:
      return {
        ...state,
        bladesWithCoils: [],
      }
    case SAVE_FILE_START:
      return {
        ...state,
        showLoading: true,
      }
    case SAVE_FILE_SUCCESS:
      toast.success(i18n.t('toaster:save_file_success'))
      return {
        ...state,
        showLoading: false,
        bladesWithCoils: {
          ...state.bladesWithCoils,
          fileInfo: action.payload,
        },
      }
    default:
      return state
  }
}
