import { setLanguage } from '../../Api'

export const MANAGE_MACHINE_VIEW = 'MANAGE_MACHINE_VIEW'
export const SWITCH_LANGUAGE_START = 'SWITCH_LANGUAGE_START'
export const SWITCH_LANGUAGE_SUCCESS = 'SWITCH_LANGUAGE_SUCCESS'
export const SWITCH_LANGUAGE_FAILED = 'SWITCH_LANGUAGE_FAILED'

export const manageMachines = value => ({
  type: MANAGE_MACHINE_VIEW,
  payload: value,
})

export const switchLanguageToSave = value => async dispatch => {
  dispatch({ type: SWITCH_LANGUAGE_START })
  try {
    const results = await setLanguage(value)
    return dispatch({ type: SWITCH_LANGUAGE_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: SWITCH_LANGUAGE_FAILED, payload: error })
  }
}
