import {
  GET_USEROLE_START,
  GET_USEROLE_SUCCESS,
  GET_USEROLE_FAILED,
  DISPATCH_USER_ACCESS_SUCCESS,
} from './AppAction'

const initialState = {
  userName: '',
  userLevel: null,
  userLanguage: null,
  userFactory: null,
  userMachine: null,
  showLoading: false,
  showError: false,
  userAccess: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USEROLE_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case GET_USEROLE_SUCCESS:
      return {
        ...state,
        showLoading: false,
        userName: action.payload.userName,
        userLevel: action.payload.userLevel,
        userLanguage: action.payload.language,
        userFactory: action.payload.factory,
        userMachine: action.payload.machine,
      }
    case GET_USEROLE_FAILED:
      return {
        ...state,
        showLoading: false,
        showError: action.payload,
      }
    case DISPATCH_USER_ACCESS_SUCCESS:
      return {
        ...state,
        userAccess: action.payload,
      }
    default:
      return state
  }
}
