import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="inner">
        <div className="footerContent">
          <p>
            <strong>Upm</strong>Raflatac
          </p>
        </div>
      </div>
    </footer>
  )
}
export default Footer
