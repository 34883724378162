import React from 'react'
import PropTypes from 'prop-types'

const InstructionsModalInner = ({ children }) => {
  return <div className="bgBladeModalInner">{children}</div>
}

InstructionsModalInner.propTypes = {
  children: PropTypes.node,
}

InstructionsModalInner.defaultProps = {
  children: null,
}

export default InstructionsModalInner
