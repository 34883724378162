import { toast } from 'react-toastify'
import {
  FETCH_CUSTOMERS_START,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILED,
  CLEAR_DATA_TRUE,
  CLEAR_DATA_FALSE,
  SAVE_BLADE_DATA_START,
  SAVE_BLADE_DATA_SUCCESS,
  SAVE_BLADE_DATA_FAIL,
  UPDATE_BLADE_DATA_START,
  UPDATE_BLADE_DATA_SUCCESS,
  UPDATE_BLADE_DATA_FAIL,
  ARCHIVE_BLADE_START,
  ARCHIVE_BLADE_SUCCESS,
  ARCHIVE_BLADE_FAIL,
  RESTORE_BLADE_START,
  RESTORE_BLADE_SUCCESS,
  RESTORE_BLADE_FAIL,
  CHANGES_STRIPES,
  GET_FILE_START,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  REMOVE_FILE_FAIL,
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_START,
  IS_INSTRUCTIONS_MODAL_OPEN,
} from './newBladeFormAction'
import { INITIAL_BLADE_FORM_SUCCESS } from '../coatingMachine/components/bladeTable/BladeTableAction'
import i18n from '../../i18n'

const initialState = {
  showLoading: false,
  customers: [],
  clearData: false,
  isModalOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BLADE_DATA_START:
    case UPDATE_BLADE_DATA_START:
    case FETCH_CUSTOMERS_START:
    case ARCHIVE_BLADE_START:
    case RESTORE_BLADE_START:
      return {
        ...state,
        showLoading: true,
      }
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        showLoading: false,
        customers: action.payload,
      }
    case ARCHIVE_BLADE_SUCCESS:
      toast.success(i18n.t('toaster:archive_blade_success'))
      return {
        ...state,
        showLoading: false,
      }
    case RESTORE_BLADE_SUCCESS:
      toast.success(i18n.t('toaster:restore_blade_success'))
      return {
        ...state,
        showLoading: false,
      }
    case GET_FILE_FAIL:
    case SAVE_BLADE_DATA_FAIL:
    case UPDATE_BLADE_DATA_FAIL:
    case FETCH_CUSTOMERS_FAILED:
    case RESTORE_BLADE_FAIL:
    case ARCHIVE_BLADE_FAIL:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
      }
    case SAVE_BLADE_DATA_SUCCESS:
      toast.success(i18n.t('toaster:save_blade_success'))
      return {
        ...state,
        showLoading: false,
      }
    case UPDATE_BLADE_DATA_SUCCESS:
      toast.success(i18n.t('toaster:update_blade_success'))
      return {
        ...state,
        showLoading: false,
      }
    case INITIAL_BLADE_FORM_SUCCESS:
      return {
        ...state,
        showLoading: false,
      }
    case CLEAR_DATA_TRUE:
      return {
        ...state,
        clearData: true,
      }
    case CLEAR_DATA_FALSE:
      return {
        ...state,
        clearData: false,
      }

    case CHANGES_STRIPES:
      return {
        ...state,
        formatedStripes: action.array,
      }
    case GET_FILE_START:
      return {
        ...state,
        showLoading: true,
      }
    case GET_FILE_SUCCESS:
      return {
        ...state,
        showLoading: false,
      }
    case REMOVE_FILE_START:
      return {
        ...state,
        showLoading: true,
      }
    case REMOVE_FILE_SUCCESS:
      toast.success(i18n.t('toaster:remove_file_success'))
      return {
        ...state,
        showLoading: false,
      }
    case REMOVE_FILE_FAIL:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
      }
    case IS_INSTRUCTIONS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      }
    default:
      return state
  }
}
