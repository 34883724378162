/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'lodash'
import Checkbox from '../../../common/components/checkbox/CheckBox'

class BladeTableFilters extends React.Component {
  raiseDoSearchWhenStopTyping = debounce(value => {
    const { archivedFilterFunc, textFilterFunc } = this.props
    if (typeof value === 'string') {
      textFilterFunc(value)
    } else {
      archivedFilterFunc(value)
    }
  }, 200)

  state = {
    searchValue: '',
    checkBoxValue: false,
  }

  handleChange = e => {
    const value = e.target[e.target.type === 'checkbox' ? 'checked' : 'value']
    if (typeof value === 'boolean') {
      this.setState({ checkBoxValue: value }, () => {
        this.raiseDoSearchWhenStopTyping(value)
      })
    } else {
      this.setState(
        {
          searchValue: value,
        },
        () => {
          this.raiseDoSearchWhenStopTyping(value)
        }
      )
    }
  }

  onKeyPress = e => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault()
    }
  }

  render() {
    const { children, showMachineManagement, t, userLevel } = this.props

    const { searchValue, checkBoxValue } = this.state
    return (
      <form className="filters">
        {showMachineManagement ? null : userLevel === 1 ? null : (
          <div>
            <label>
              <Checkbox
                checked={checkBoxValue}
                name="archivedFilter"
                onChange={e => this.handleChange(e)}
                label={t('changedItemHistory:archived')}
              />
            </label>
          </div>
        )}
        <div className="filters__searchNumber">{children}</div>
        <div className="search">
          <button className="faSearch" type="button">
            <FontAwesomeIcon
              icon="search"
              size="lg"
              style={{ color: '#d2d2d2' }}
            />
          </button>
          <input
            className="filterText"
            type="text"
            placeholder={t('bladeList:search')}
            value={searchValue}
            name="textFilter"
            onChange={e => this.handleChange(e)}
            onKeyPress={this.onKeyPress}
          />
        </div>
      </form>
    )
  }
}

BladeTableFilters.propTypes = {
  children: PropTypes.element.isRequired,
  archivedFilterFunc: PropTypes.func.isRequired,
  textFilterFunc: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  userLevel: PropTypes.number.isRequired,
}
const mapStateToProps = state => ({
  showMachineManagement: state.MachineManagement.showMachineManagement,
  machineList: state.coatingMachine.machineList,
})
// eslint-disable-next-line no-class-assign
BladeTableFilters = connect(
  mapStateToProps,
  {}
)(BladeTableFilters)
export default withTranslation(['common', 'bladeList', 'changedItemHistory'])(
  BladeTableFilters
)
