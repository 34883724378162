/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

/* eslint-disable jsx-a11y/label-has-for */

const HistoryView = props => {
  const {
    historyJson,
    historyId,
    HistoryViewAction,
    i18n,
    t,
    languageToSave,
    userLanguage,
  } = props
  useEffect(() => {
    HistoryViewAction(historyId)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(languageToSave || userLanguage)
  }, [languageToSave, userLanguage])
  const localTimeFunc = time => {
    const localTime = moment.utc(time).toDate()
    const local = moment(localTime)
      .local()
      .format('DD.MM.YYYY HH:mm')
    return local
  }
  return (
    <div className="history">
      <h1>{t('title')}</h1>
      {historyJson.skid && (
        <React.Fragment>
          <header>
            <p>
              {t('subtitle')}: {historyJson.sknro}
            </p>
            <p>
              {t('change_made_by')}: {historyJson.userName}
            </p>
            <p>
              {t('time_of_change')}: {localTimeFunc(historyJson.changeTime)}
            </p>
          </header>
          <main>
            <div className="left">
              <h2>{t('new_version')}</h2>
              <pre>{historyJson.newValue}</pre>
            </div>
            <div className="right">
              <h2>{t('old_version')}</h2>

              <pre>{historyJson.oldValue}</pre>
            </div>
          </main>
        </React.Fragment>
      )}
    </div>
  )
}

HistoryView.defaultProps = {
  historyJson: null,
  userLanguage: null,
  languageToSave: null,
}
HistoryView.propTypes = {
  historyJson: PropTypes.shape({
    blade: PropTypes.string,
  }),
  historyId: PropTypes.number.isRequired,
  HistoryViewAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  userLanguage: PropTypes.string,
  languageToSave: PropTypes.string,
}

const mapStateToProps = state => ({
  userLanguage: state.AppUser.userLanguage,
  languageToSave: state.MachineManagement.languageToSave,
})

export default withTranslation(['historyTabView', 'common'])(
  connect(
    mapStateToProps,
    {}
  )(HistoryView)
)
