/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  updateBladeData as updateBladeDataAction,
  saveBladeData as saveBladeDataAction,
} from '../newBladeFormAction'
import {
  removeEan as removeEanAction,
  removeLayers as removeLayersAction,
} from '../../coatingMachine/components/bladeTable/BladeTableAction'

import Button from '../../common/components/button/Button'
import { convertToMm } from '../../../utils/convertUoM'

const RemoteSubmitButton = ({
  coilWithStripes,
  blade,
  bladeId,
  updateBladeData,
  saveBladeData,
  removeEan,
  removeLayers,
  saving,
  isArchived,
  formatedStripes,
  customerList,
  disableWhenUoMpreview,
  machineUoM,
  t,
}) => {
  const coilsStripes =
    coilWithStripes &&
    coilWithStripes.coilWithStripes &&
    coilWithStripes.coilWithStripes.map(({ stripes, ...item }) => item)
  const bladeExists = blade && blade.blade
  // making a copy of blades data so we can remove unnecessary fields, coils and customers
  const bladeWithoutCoils = { ...bladeExists }
  delete bladeWithoutCoils.coils
  const eanCodesForRemoving = blade && blade.removedEanCodes
  const coatingLayersForRemoving = blade && blade.removedCoatingLayers

  let convertedFormattedStripes
  if (machineUoM === 1) {
    convertedFormattedStripes =
      formatedStripes &&
      formatedStripes.map(stripe => {
        return {
          stripeType: stripe.stripeType,
          width: convertToMm(stripe.width),
        }
      })
  } else {
    convertedFormattedStripes = formatedStripes
  }

  // delete bladeWithoutCoils.customers
  const formUpdateValues = {
    bladeImage: {
      bladeImageElements: convertedFormattedStripes || [],
    },
    blade: bladeWithoutCoils,
    customers: customerList || [],
    coilWithStripes: coilsStripes || [],
  }
  const removeAllEan = eanArr => {
    eanArr.forEach(ean => {
      removeEan(ean.id)
    })
  }
  // We are passing coting layers ID's to API to be removed
  const removeAllCoatingLayers =
    coatingLayersForRemoving &&
    coatingLayersForRemoving.map(element => element.id)

  const updatingButton = (
    <Button
      label={t('save')}
      type="button"
      size="md"
      variant="contained--primary"
      icon={<FontAwesomeIcon icon="save" />}
      onClick={() => {
        saveBladeData(blade.blade)
      }}
      disabled={(isArchived && true) || disableWhenUoMpreview}
    />
  )
  const savingButton = (
    <Button
      label={t('save')}
      type="button"
      size="md"
      variant="contained--primary"
      icon={<FontAwesomeIcon icon="save" />}
      onClick={() => {
        updateBladeData(bladeId, formUpdateValues)
        removeAllEan(eanCodesForRemoving)
        removeLayers(removeAllCoatingLayers)
      }}
      disabled={(isArchived && true) || disableWhenUoMpreview}
    />
  )

  return saving ? savingButton : updatingButton
}

const mapStateToProps = state => {
  return {
    customerList:
      state.form.newBladeForm &&
      state.form.newBladeForm.values &&
      state.form.newBladeForm.values.customerList,
    coilWithStripes: getFormValues('coilForm')(state),
    blade: getFormValues('newBladeForm')(state),
    bladeId:
      state.form.newBladeForm &&
      state.form.newBladeForm.values.blade &&
      state.form.newBladeForm.values.blade.skid,
    isArchived:
      state.coatingMachine.bladesWithCoils.blade &&
      state.coatingMachine.bladesWithCoils.blade.archived,
    formatedStripes: state.newBladeFormReducer.formatedStripes,
    machineUoM:
      state.coatingMachine.selectedUserMachine &&
      state.coatingMachine.selectedUserMachine.unitOfMeasurement,
  }
}
RemoteSubmitButton.propTypes = {
  updateBladeData: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  updateBladeData: updateBladeDataAction,
  saveBladeData: saveBladeDataAction,
  removeEan: removeEanAction,
  removeLayers: removeLayersAction,
})(withTranslation(['common'])(RemoteSubmitButton))
