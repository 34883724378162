import React from 'react'

// eslint-disable-next-line react/prop-types
const ModalHeaderWithCloseButton = ({ children }) => {
  return <div className="ModalHeaderWithCloseButton">{children}</div>
}

ModalHeaderWithCloseButton.defaultProps = {
  children: null,
}

export default ModalHeaderWithCloseButton
