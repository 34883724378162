/* eslint-disable consistent-return */
import { fetchHistory } from '../../Api'

export const FETCH_HISTORY_START = 'FETCH_HISTORY_START'
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS'
export const FETCH_HISTORY_FAIL = 'FETCH_HISTORY_FAIL'

export const HistoryViewAction = id => async dispatch => {
  dispatch({
    type: FETCH_HISTORY_START,
  })
  try {
    const results = await fetchHistory(id)
    dispatch({ type: FETCH_HISTORY_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: FETCH_HISTORY_FAIL })
  }
}
