import { toast } from 'react-toastify'
import i18n from './../../i18n'
import {
  FETCH_HISTORY_START,
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_FAIL,
} from './HistoryAction'

const initialState = {
  historyJson: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        historyJson: action.payload,
      }
    case FETCH_HISTORY_FAIL:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
      }
    default:
      return state
  }
}
