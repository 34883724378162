import React from 'react'
import PropTypes from 'prop-types'

const ModalBody = ({ children }) => {
  return <div className="modalBody">{children}</div>
}

ModalBody.defaultProps = {
  children: null,
}

ModalBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ModalBody
