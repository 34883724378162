import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import CoilVisualization from '../coilVisualization/CoilVisualization'
import {
  convertToInches,
  convertToMm,
} from '../../../../../../../utils/convertUoM'

const CoatingLayersMany = props => {
  const {
    coilWithStripes,
    color,
    coatingLayersWidth,
    previewUoM,
    machineUoM,
  } = props
  const coilWidth = coilWithStripes.coil.width

  let convertedCoilWidth
  if (previewUoM === 1 && previewUoM !== machineUoM) {
    convertedCoilWidth = convertToInches(coilWidth)
  } else if (previewUoM === 0 && previewUoM !== machineUoM) {
    convertedCoilWidth = convertToMm(coilWidth)
  } else {
    convertedCoilWidth = coilWidth
  }
  const mmOrin = previewUoM === 0 ? 'mm' : 'in'
  return (
    <div
      className="coating-layers-many"
      style={{ width: `${coatingLayersWidth}%` }}
    >
      <div className="coating-layers-many__size">
        <div
          className={
            previewUoM === 1
              ? 'coating-layers-many__size__number rotate-45'
              : 'coating-layers-many__size__number'
          }
        >
          {convertedCoilWidth}
          <span
            className={
              previewUoM === 1
                ? 'coating-layers-many__size__unit rotate-45'
                : 'coating-layers-many__size__unit'
            }
          >
            {mmOrin}
          </span>
        </div>
      </div>
      <CoilVisualization
        color={color}
        noLetters
        coilData={coilWithStripes}
        coilWidth={coatingLayersWidth}
        previewUoM={previewUoM}
      />
    </div>
  )
}

CoatingLayersMany.defaultProps = {
  color: null,
  previewUoM: null,
  machineUoM: null,
}

CoatingLayersMany.propTypes = {
  coilWithStripes: PropTypes.shape({
    coil: PropTypes.shape({
      popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      popid: PropTypes.number,
      skid: PropTypes.number,
      ordnum: PropTypes.number,
      amount: PropTypes.number,
      firstTrackGlued: PropTypes.bool,
      perforated: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    coilStripes: PropTypes.arrayOf(
      PropTypes.shape({
        coilID: PropTypes.number,
        id: PropTypes.number,
        orderNum: PropTypes.number,
        stripeType: PropTypes.number,
        width: PropTypes.number,
      })
    ),
    stripes: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }).isRequired,
  color: PropTypes.string,
  coatingLayersWidth: PropTypes.number.isRequired,
  previewUoM: PropTypes.number,
  machineUoM: PropTypes.number,
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})

export default connect(mapStateToProps, null)(CoatingLayersMany)
