import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { withTranslation } from 'react-i18next'
import CoatingLayersOne from '../coatingLayersOne/CoatingLayersOne'
import CoatingLayersMany from '../coatingLayersMany/CoatingLayersMany'
import SingleCoil from '../../../../../../../assets/singleCoil.png'
import AllCoils from '../../../../../../../assets/allCoils.png'
import { manyCoilsViewAction } from './coatinglayersWrapperAction'

export const colors = [
  '#BEFAD7',
  '#FCAE91',
  '#BDD7E7',
  '#CD913C',
  '#6BAED6',
  '#5F820A',
  '##7828A0',
  '#46AA96',
  '#A03764',
  '#A50F15',
  '#E1FFF5',
  '#BEFAD7',
  '#FCAE91',
  '#BDD7E7',
  '#CD913C',
  '#6BAED6',
  '#5F820A',
  '##7828A0',
  '#46AA96',
  '#A03764',
  '#A50F15',
  '#E1FFF5',
]

const CoatingLayersWrapper = props => {
  const [manyCoilsView, setmanyCoilsView] = useState(true)

  useEffect(() => {
    props.manyCoilsViewAction(manyCoilsView)
  }, [manyCoilsView])

  const { coils, coatingLayers, t } = props
  const coilWithStripesArray = coils

  const allCoils = []

  // making all coils array
  // eslint-disable-next-line no-unused-vars
  const newCoils =
    coils &&
    coils.map(coil => {
      for (let i = 0; i < coil.coil.amount; i += 1) {
        allCoils.push(coil)
      }
      return allCoils
    })

  const coilsWidth = allCoils && allCoils.map(coil => coil.coil.width)
  let totalWidth = 0
  coilsWidth.forEach(coil => {
    totalWidth = coil + totalWidth
  })

  // creates color array for rendering colors to coils
  const colorsForCoils = []
  const idArray = []
  let indexVar = 0
  allCoils.forEach(item => {
    if (idArray.indexOf(item.coil.popina) === -1) {
      idArray.push(item.coil.popina)
      for (let i = 0; i < item.coil.amount; i += 1) {
        colorsForCoils.push(colors[indexVar])
      }
      indexVar += 1
    }
  })

  const coilsPercentageWidth =
    coilsWidth && coilsWidth.map(item => 100 * (item / totalWidth))

  return (
    <div style={{ position: 'relative' }}>
      <div className="coil-buttons">
        <button
          className={
            manyCoilsView ? 'button-coil active' : 'button-coil inactive'
          }
          onClick={() => setmanyCoilsView(true)}
          type="button"
        >
          <img src={AllCoils} alt="all coils" />
        </button>
        <button
          style={{ marginLeft: '0.5rem' }}
          className={
            manyCoilsView ? 'button-coil inactive' : 'button-coil active'
          }
          onClick={() => setmanyCoilsView(false)}
          type="button"
        >
          <img src={SingleCoil} alt="single coil" />
        </button>
      </div>
      {manyCoilsView ? (
        <div className="coils-many">
          <div className="coils-many__a coils-many__letters">A</div>
          <div className="coils-many__main">
            <div className="coils-many__main-layers">
              {coatingLayers.map((layer, i) => (
                <div className="coatingLayers__layers--layer" key={i}>
                  {`${i + 1}) ${layer.title}`}
                </div>
              ))}
            </div>
            <div className="coils-many__main-coils">
              {allCoils &&
                allCoils.map((coil, i) => (
                  <CoatingLayersMany
                    key={i}
                    coilWithStripes={allCoils[i]}
                    coatingLayers={coatingLayers}
                    color={colorsForCoils[i]}
                    coatingLayersWidth={coilsPercentageWidth[i]}
                  />
                ))}
            </div>
          </div>
          <div className="coils-many__b coils-many__letters">B</div>
        </div>
      ) : (
        <Tabs>
          <TabList>
            {coils &&
              coils.map((coil, i) => (
                <Tab key={coil.coil.popina}>
                  {t('coil')} {i + 1}
                </Tab>
              ))}
          </TabList>
          {coils &&
            coils.map((coil, i) => (
              <TabPanel key={coil.coil.popina}>
                <CoatingLayersOne
                  coilWithStripes={coilWithStripesArray[i]}
                  coatingLayers={coatingLayers}
                />
              </TabPanel>
            ))}
        </Tabs>
      )}
    </div>
  )
}

CoatingLayersWrapper.defaultProps = {
  coatingLayers: [],
  coils: null,
}

CoatingLayersWrapper.propTypes = {
  coils: PropTypes.arrayOf(
    PropTypes.shape({
      coil: PropTypes.shape({
        popina: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        popid: PropTypes.number,
        skid: PropTypes.number,
        ordnum: PropTypes.number,
        amount: PropTypes.number,
        firstTrackGlued: PropTypes.bool,
        perforated: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      coilStripes: PropTypes.arrayOf(
        PropTypes.shape({
          coilID: PropTypes.number,
          id: PropTypes.number,
          orderNum: PropTypes.number,
          stripeType: PropTypes.number,
          width: PropTypes.number,
        })
      ),
      stripes: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    })
  ),
  coatingLayers: PropTypes.arrayOf(
    PropTypes.shape({
      coating: PropTypes.number,
      id: PropTypes.number,
      ordNum: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  manyCoilsViewAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  coils: formValueSelector('coilForm')(state, 'coilWithStripes'),
  coatingLayers: formValueSelector('newBladeForm')(
    state,
    'blade.coatingLayers'
  ),
})

export default connect(mapStateToProps, { manyCoilsViewAction })(
  withTranslation(['common'])(CoatingLayersWrapper)
)
