import React, { Component } from 'react'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import renderField from '../../../common/components/RenderField'
import Button from '../../../common/components/button/Button'
import RenderStripes from '../renderStripes/RenderStripes'
import { twoDigits } from '../../../../utils/fieldNormalizing'
import Can from '../../../../CanAccess'

class RenderCoilForm extends Component {
  static elHasClass(element, classToTest) {
    const pattern = new RegExp(`(^| )${classToTest}( |$)`)
    return !!pattern.test(element.className)
  }

  constructor(props) {
    super(props)
    this.focusEle = this.focusEle.bind(this)
    this.doFocus = this.doFocus.bind(this)
    this.state = {
      lastFocused: null,
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', e => {
      this.handleTabPress(e)
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleTabPress)
  }

  handleTabPress = event => {
    if (event.key === 'Tab') {
      const ele = document.activeElement
      ele.click()
      this.doFocus(ele)
    }
  }

  pushFields() {
    const { fields } = this.props
    fields.push({ adhesive: true, amount: 1 })
  }

  doFocus(ele) {
    const { lastFocused } = this.state
    const idArray = lastFocused && lastFocused.match(/\d+/g).map(Number)
    const id =
      idArray && `coilWithStripes[${idArray[0]}].stripes[${idArray[1] + 1}]`
    if (this.constructor.elHasClass(ele, 'button--outlined--tertiary')) {
      // eslint-disable-next-line func-names
      setTimeout(function() {
        const idElement = document.getElementById(id)
        if (idElement) {
          idElement.focus()
        }
      }, 300)
    }
  }

  focusEle(e) {
    this.setState({ lastFocused: `${e.target.id}` })
  }

  handleSelectChange(e, coil) {
    const { coilData, change } = this.props

    const coilArray = coil.match(/\d+/g).map(Number)
    coilData.coilWithStripes[coilArray[0]].coilStripes.forEach((element, i) => {
      change(
        `coilWithStripes[${coilArray[0]}]coilStripes[${i}].stripeType`,
        element.stripeType === 0 ? 1 : 0
      )
    })
  }

  fieldPush() {
    const { fields, bladeId } = this.props

    const newCoilObj = {
      coil: {
        popid: 0,
        skid: bladeId,
        ordnum: -1,
        popina: fields.length,
        width: 0,
        amount: 1,
        firstTrackGlued: true,
        perforated: false,
      },
      coilStripes: [{ stripeType: 1, width: 0, id: 0, coilId: 0 }],
    }
    fields.push(newCoilObj)
  }

  calculateCoilWidth(index) {
    const { fields, uOmMachine } = this.props

    const width =
      fields.getAll()[index] &&
      typeof fields.getAll()[index].stripes !== 'undefined'
        ? fields
            .getAll()
            [index].stripes.map(item =>
              item === undefined || item === '' ? 0 : parseFloat(item)
            )
            .reduce(
              (acc, item) =>
                item === undefined || item === '' ? 0 : acc + item,
              0
            )
        : 0
    return uOmMachine === 1 ? parseInt(width * 32, 10) / 32 : width
  }

  render() {
    const {
      fields,
      coilData,
      change,
      initialize,
      bladeId,
      userLevel,
      t,
      uOmPreview,
      uOmMachine,
      meta: { error, submitFailed },
    } = this.props

    const readOnlyValue = userLevel === 1
    const mmOrin = uOmPreview === 0 ? 'mm' : 'in'
    const isDisabled = uOmPreview !== uOmMachine
    return (
      <ul className="renderCoil__ul">
        {fields.map((coil, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className="renderCoil__ul--li" key={index}>
            <h4>
              {t('coil')} {index + 1}
            </h4>
            <div className="coilForm__segment">
              <div className="coilForm__segment__input">
                <div>
                  <div className="coilForm__segment__width">
                    {t('coilWidth')}
                  </div>
                  <div className="coilForm__segment__value">
                    {// calculate width of the coils
                    this.calculateCoilWidth(index)
                    /* field only for calculating coil width */
                    }
                    <div className="invisibleWidth">
                      <Field
                        name={`${coil}.coil.width`}
                        type="number"
                        component={renderField}
                        label={t('width')}
                      />
                    </div>
                  </div>
                  <i>{mmOrin}</i>
                </div>
                <div className="coilForm__segment__input--amount">
                  <Field
                    name={`${coil}.coil.amount`}
                    type="number"
                    component={renderField}
                    label={t('amount')}
                    normalize={twoDigits}
                    disabled={isDisabled}
                    // readOnlyValue={readOnlyValue || isDisabled}
                  />
                </div>
                <div className="coilForm__segment__input--adhesive">
                  <div className="coilForm__segment__input--adhesive--label">
                    {t('1stStripeAdhesive')}
                  </div>
                  <Field
                    name={`${coil}.coil.firstTrackGlued`}
                    component="select"
                    className="bladeForm__select"
                    parse={val => JSON.parse(val)}
                    disabled={readOnlyValue || isDisabled}
                    onChange={e => this.handleSelectChange(e, coil)}
                  >
                    <option value>{t('yes')}</option>
                    <option value={false}>{t('noAnswer')}</option>
                  </Field>
                </div>
              </div>
              <Can
                // eslint-disable-next-line jsx-a11y/aria-role
                role={userLevel}
                perform="coilForm:deleteCoil"
                yes={() => (
                  <Button
                    type="button"
                    onClick={() => fields.remove(index)}
                    variant="outlined--danger"
                    size="sm"
                    icon={<FontAwesomeIcon icon={faTimes} />}
                    className="coilForm__segment__removeButton"
                    disabled={isDisabled}
                  />
                )}
                no={() => null}
              />
            </div>
            <div className="widthLabel">
              <b>{t('stripeWidth')}</b> (<i>{mmOrin}</i>)
            </div>
            <FieldArray
              name={`${coil}.stripes`}
              focusEle={this.focusEle}
              component={RenderStripes}
              change={change}
              coilData={coilData}
              initialize={initialize}
              userLevel={userLevel}
            />
          </li>
        ))}
        <Can
          // eslint-disable-next-line jsx-a11y/aria-role
          role={userLevel}
          perform="coilForm:addNewCoil"
          yes={() => (
            <li>
              <Button
                type="button"
                label={t('newCoil')}
                icon={<FontAwesomeIcon icon={faPlus} />}
                variant="outlined--primary"
                onClick={() => this.fieldPush()}
                className="coilMargin-top"
                disabled={bladeId === null || bladeId === 0 || isDisabled}
              />
              {submitFailed && error && <span>{error}</span>}
            </li>
          )}
          no={() => null}
        />
      </ul>
    )
  }
}

RenderCoilForm.propTypes = {
  fields: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  coilData: PropTypes.shape({
    coils: PropTypes.arrayOf(
      PropTypes.shape({
        stripes: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        adhesive: PropTypes.boolean,
      })
    ),
  }),
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  bladeId: PropTypes.number,
  userLevel: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  uOmPreview: PropTypes.number,
  uOmMachine: PropTypes.number,
}

RenderCoilForm.defaultProps = {
  fields: {},
  meta: {},
  coilData: null,
  bladeId: null,
  uOmPreview: null,
  uOmMachine: null,
}

const selector = formValueSelector('newBladeForm')
const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  uOmPreview: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  bladeId: selector(state, 'blade.skid'),
  dateCreated: selector(state, 'blade.created'),
  uOmMachine:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
})

export default connect(mapStateToProps)(
  withTranslation(['common'])(RenderCoilForm)
)
