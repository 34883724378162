import { connect } from 'react-redux'

import MachineManagementForm from '../components'
import {
  clearDataTrue,
  archiveSelectedMachine,
  restoreSelectedMachine,
} from '../MachineManagementFormAction'

const mapStateToProps = state => {
  return {
    customers: state.newBladeFormReducer.customers,
    userName: state.AppUser.userName,
    userLevel: state.AppUser.userLevel,
    machineNmbrRedu: state.MachineManagementFormReducer.machineNmbrRedu,
    selectedFactory: state.coatingMachine.selectedFactory,
    selectedUserMachine: state.coatingMachine.selectedUserMachine,
  }
}

export default connect(mapStateToProps, {
  clearDataTrue,
  archiveSelectedMachine,
  restoreSelectedMachine,
})(MachineManagementForm)
