/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import { msalApp, requiresInteraction, GRAPH_REQUESTS } from './auth-utils'

export default C =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props)

      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
      }
    }

    async acquireToken(request, redirect) {
      return msalApp.acquireTokenSilent(request).catch(error => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
          return redirect
            ? msalApp.acquireTokenRedirect(request)
            : msalApp.acquireTokenPopup(request)
        }
      })
    }

    async onSignIn() {
      return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN)
    }

    onSignOut() {
      msalApp.logout()
    }

    async componentDidMount() {
      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : 'Unable to acquire access token.'
          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage,
          })
        }
      })

      const account = msalApp.getAccount()
      if (account) {
        this.setState({
          account,
        })
      } else {
        this.onSignIn()
      }
    }

    render() {
      const { account, error } = this.state
      return (
        <C
          {...this.props}
          accountInfo={account}
          error={error}
          onSignIn={() => this.onSignIn()}
          onSignOut={() => this.onSignOut()}
        />
      )
    }
  }
