import { SAVE_BLADE_AND_COILS_DATA } from './PreviewAction'

const initialState = {
  bladeData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BLADE_AND_COILS_DATA:
      return {
        ...state,
        bladeData: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
