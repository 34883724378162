import React from 'react'
import PropTypes from 'prop-types'

/* eslint jsx-a11y/label-has-for: 0 */
const TextAreaField = ({
  input,
  type,
  label,
  cols,
  rows,
  placeholder,
  readOnlyValue,
  meta: { error, touched },
}) => {
  return (
    <div>
      <label htmlFor={input.name} className="label">
        {label}
      </label>
      <textarea
        {...input}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
        type={type}
        name={input.name}
        id={input.name}
        readOnly={readOnlyValue}
      />
      <div className="red-text">{touched && error}</div>
    </div>
  )
}

TextAreaField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  cols: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
  readOnlyValue: PropTypes.bool,
}

TextAreaField.defaultProps = {
  meta: {
    touched: false,
    error: '',
    active: false,
  },
  label: null,
  readOnlyValue: false,
}
export default TextAreaField
