import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import AppReducer from '../../AppReducer'
import BladeTableReducer from '../../features/coatingMachine/components/bladeTable/BladeTableReducer'
import coatingMachineReducer from '../../features/coatingMachine/coatingMachineReducer'
import newBladeFormReducer from '../../features/newBladeForm/newBladeFormReducer'
import BladeVisualisationLegendReducer from '../../features/bladeVisulation/components/legend/BladeVisualisationLegendReducer'
import MachineManagement from '../../features/header/HeaderReducer'
import MachineManagementFormReducer from '../../features/machineManagmentForm/MachineManagementFormReducer'
import bladeDrawingReducer from '../../features/bladeDrawing/bladeDrawingReducer'
import HistoryReducer from '../../features/HistoryTabView/HistoryReducer'
import coatingLayersWrapperReducer from '../../features/common/components/instructionsModal/instructionsModal/coatingLayers/coatingLayersWrapper/coatingLayersWrapperReducer'
import previewReducer from '../../features/header/previewUoM/PreviewReducer'

export default combineReducers({
  AppUser: AppReducer,
  form: formReducer,
  bladeTable: BladeTableReducer,
  coatingMachine: coatingMachineReducer,
  newBladeFormReducer,
  BladeVisualisationLegendReducer,
  MachineManagement,
  MachineManagementFormReducer,
  bladeDrawingReducer,
  HistoryReducer,
  coatingLayersWrapperReducer,
  previewReducer,
})
