import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CoilItemHoverComponent from './CoilItemHoverComponent'

const CoilItemComponent = ({
  item,
  totalWidth,
  i,
  uomPreview,
  color,
  coilWidth,
}) => {
  const [hover, setHover] = useState(false)

  // Different scenarios for small stripe hover component (covers also many coils in instructions modal)
  const smallStripeCondition =
    (hover && (item.width * 100) / totalWidth < 4 && uomPreview === 1) ||
    (hover &&
      color &&
      uomPreview === 1 &&
      (item.width * 100) / totalWidth < 30 &&
      coilWidth < 25) ||
    (hover &&
      color &&
      uomPreview === 1 &&
      (item.width * 100) / totalWidth < 80 &&
      coilWidth < 10)

  // Different scenarios for vertical numbers in stripes (covers also many coils in instructions modal)
  const verticalStripeNumbersCondition =
    (uomPreview === 1 && (item.width * 100) / totalWidth < 4 && !color) ||
    (color &&
      (item.width * 100) / totalWidth < 4 &&
      coilWidth > 80 &&
      uomPreview === 1) ||
    (color &&
      (item.width * 100) / totalWidth < 25 &&
      coilWidth < 25 &&
      uomPreview === 1) ||
    (color &&
      (item.width * 100) / totalWidth < 80 &&
      uomPreview === 1 &&
      coilWidth < 10) ||
    (color &&
      (item.width * 100) / totalWidth < 5 &&
      uomPreview === 1 &&
      coilWidth < 40)

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={item && item.stripeType === 0 ? 'bandItem' : 'bandItem glue'}
      // eslint-disable-next-line react/no-array-index-key
      key={`stripe${i}`}
      style={
        // eslint-disable-next-line no-nested-ternary
        smallStripeCondition
          ? {
              width: `${(item.width * 100) / totalWidth}%`,
              zIndex: 'auto',
              overflow: 'visible',
            }
          : verticalStripeNumbersCondition
          ? {
              width: `${(item.width * 100) / totalWidth}%`,
              writingMode: 'vertical-rl',
              textOrientation: 'upright',
            }
          : {
              width: `${(item.width * 100) / totalWidth}%`,
              writingMode: 'horizontal-tb',
              lineHeight: '10rem',
            }
      }
    >
      {/* This is for Imperial small stripes */}
      {smallStripeCondition ? <CoilItemHoverComponent stripe={item} /> : null}
      <span className="centered-stripe-number">
        {smallStripeCondition ? null : item.width}
      </span>
    </div>
  )
}

CoilItemComponent.defaultProps = {
  totalWidth: null,
  i: null,
  uomPreview: null,
  item: null,
  color: null,
  coilWidth: null,
}

CoilItemComponent.propTypes = {
  totalWidth: PropTypes.number,
  i: PropTypes.number,
  uomPreview: PropTypes.number,
  item: PropTypes.shape({
    stripeType: PropTypes.number,
    width: PropTypes.number,
  }),
  color: PropTypes.string,
  coilWidth: PropTypes.number,
}

export default CoilItemComponent
