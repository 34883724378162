import React from 'react'
import PropTypes from 'prop-types'

const InnerModal = ({ children }) => {
  return <div className="innerModal">{children}</div>
}

InnerModal.propTypes = {
  children: PropTypes.node,
}

InnerModal.defaultProps = {
  children: null,
}

export default InnerModal
