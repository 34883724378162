import { connect } from 'react-redux'

import NewBladeForm from '../components'
import {
  removeEan,
  updateBlade,
} from '../../coatingMachine/components/bladeTable/BladeTableAction'
import {
  fetchCustomers,
  clearDataTrue,
  archiveSelectedBlade,
  restoreSelectedBlade,
  saveFileToServer,
  getFileAction,
  removeFileAction,
  isInstructionsModalOpen,
} from '../newBladeFormAction'

const mapStateToProps = state => ({
  customers: state.newBladeFormReducer.customers,
  userName: state.AppUser.userName,
  userLevel: state.AppUser.userLevel,
  selectedFactory: state.coatingMachine.selectedFactory,
  selectedUserMachine: state.coatingMachine.selectedUserMachine,
})

export default connect(mapStateToProps, {
  fetchCustomers,
  clearDataTrue,
  removeEan,
  updateBlade,
  archiveSelectedBlade,
  restoreSelectedBlade,
  saveFileToServer,
  getFileAction,
  removeFileAction,
  isInstructionsModalOpen,
})(NewBladeForm)
