import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

import CoilForm from '../components'

const mapStateToProps = state => {
  // eslint-disable-next-line prefer-destructuring
  const clearData = state.newBladeFormReducer.clearData
  const initial = clearData
    ? null
    : {
        coilWithStripes:
          state.coatingMachine.bladesWithCoils.coilWithStripes &&
          state.coatingMachine.bladesWithCoils.coilWithStripes,
      }

  return {
    initialValues: initial,
    coilData: getFormValues('coilForm')(state),
    userName: state.AppUser.userName,
    userLevel: state.AppUser.userLevel,
    coilWithStripes:
      state.coatingMachine.bladesWithCoils.coilWithStripes &&
      state.coatingMachine.bladesWithCoils.coilWithStripes,
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'coilForm',
    enableReinitialize: true,
    onSubmit: () => {},
  })(CoilForm)
)
