import React from 'react'
import PropTypes from 'prop-types'

const CoilItemHoverComponent = ({ stripe }) => {
  return (
    <div
      className={
        stripe.stripeType === 0
          ? 'white-stripe coilItemHover'
          : 'black-stripe coilItemHover'
      }
    >
      {stripe.width}
    </div>
  )
}

CoilItemHoverComponent.propTypes = {
  stripe: PropTypes.shape({
    width: PropTypes.number,
    stripeType: PropTypes.number,
  }).isRequired,
}

export default CoilItemHoverComponent
