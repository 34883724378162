/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Can from '../../../CanAccess'
import {
  manageMachines as manageMachinesAction,
  switchLanguageToSave,
} from '../HeaderAction'
import { fetchUserRole } from '../../../AppAction'
import PreviewUoMDropDown from '../previewUoM'
import LogOutModal from '../../common/components/logOutModal/LogOutModal'

/* eslint-disable jsx-a11y/label-has-for */
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openLanguageSelector: false,
      openPreviewUoM: false,
      showLogOutModal: false,
    }
  }

  componentDidMount() {
    const { userLanguage, i18n } = this.props
    if (userLanguage) {
      const language = userLanguage.trim()
      switchLanguageToSave(language)
      i18n.changeLanguage(language)
      this.forceUpdate()
    }
  }

  handleshowNewModal = () => {
    this.setState(prevState => ({
      showLogOutModal: !prevState.showLogOutModal,
    }))
  }

  openLanguageSelectorFunc = () => {
    this.setState(prevState => ({
      openLanguageSelector: !prevState.openLanguageSelector,
    }))
  }

  togglePreviewUoM = () => {
    this.setState(prevState => ({
      openPreviewUoM: !prevState.openPreviewUoM,
    }))
  }

  changeLanguage = val => {
    // eslint-disable-next-line no-shadow
    const { i18n, switchLanguageToSave } = this.props
    this.setState({
      openLanguageSelector: false,
    })
    switchLanguageToSave(val)
    i18n.changeLanguage(val)
  }

  renderUserGroup = level => {
    const { t } = this.props
    switch (level) {
      case 0:
        return 'No Access'
      case 1:
        return t('common:userGroup.operator')
      case 2:
        return t('common:userGroup.manager')
      case 3:
        return t('common:userGroup.administrator')
      default:
        return 'No Access'
    }
  }

  render() {
    const {
      t,
      userName,
      userLevel,
      userLanguage,
      languageToSave,
      manageMachines,
      onSignOut,
      showMachineManagement,
    } = this.props
    const user = {
      name: userName,
      group: this.renderUserGroup(userLevel),
    }
    const { openLanguageSelector, openPreviewUoM, showLogOutModal } = this.state
    const createAnimation = (slow, type) => {
      const speed = '3s'
      const timing = type === 'yAxis' ? 'ease-in-out' : 'linear'
      return {
        animation: `${type} ${speed} ${timing}`,
      }
    }

    const hostname = window && window.location && window.location.hostname
    let environment
    if (
      // QA ENVIRONMENT
      hostname === 'upmraflataclabelpga-qa-web.azurewebsites.net' ||
      hostname === 'labelpga-qa-front.azurewebsites.net' ||
      hostname === 'pga-qa.upmraflatac.com'
    ) {
      environment = 'QA'
    } else if (
      // PRODUCTION ENVIRONMENT
      hostname === 'upmraflataclabelpga-prod-web.azurewebsites.net' ||
      hostname === 'labelpga-prod-front.azurewebsites.net' ||
      hostname === 'pga.upmraflatac.com'
    ) {
      environment = ''
    } else if (
      // DEV ENVIRONMENT
      hostname === 'upmraflataclabelpga-dev-front-web.azurewebsites.net' ||
      hostname === 'labelpga-dev-front.azurewebsites.net' ||
      hostname === 'pga-dev.upmraflatac.com'
    ) {
      environment = 'DEV'
    } else if (hostname === 'localhost') {
      environment = 'localhost'
    } else {
      environment = 'UNKNOWN'
    }

    const newModal = showLogOutModal ? (
      <LogOutModal
        handleshowNewModal={this.handleshowNewModal}
        onSignOut={onSignOut}
      />
    ) : null

    return (
      <header className="header">
        <div className="outer">
          <div className="inner">
            <div className="leftWpr">
              <div className="iconWpr">
                <div className="icon" style={createAnimation('slow', 'yAxis')}>
                  <span className="first" />
                  <span className="second" />
                  <span className="third" />
                  <span className="fourth" />
                </div>
              </div>
              <h1>
                {t('header:title')} {environment}
              </h1>
            </div>
            <nav className="nav">
              <ul>
                <li>
                  <button
                    type="button"
                    value="pgBlades"
                    onClick={e => manageMachines(e.target.value)}
                    className={
                      showMachineManagement === false
                        ? 'activeUnderline'
                        : 'nonActiveUnderline'
                    }
                  >
                    {t('header:subtitle1')}
                  </button>
                </li>
                <Can
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role={userLevel}
                  perform="machine-management:visit"
                  yes={() => (
                    <li>
                      <button
                        type="button"
                        value="machineManagement"
                        onClick={e => manageMachines(e.target.value)}
                        className={
                          showMachineManagement === true
                            ? 'activeUnderline'
                            : 'nonActiveUnderline'
                        }
                      >
                        {t('subtitle2')}
                      </button>
                    </li>
                  )}
                  no={() => null}
                />
              </ul>
            </nav>
            <div className="rightWpr">
              <div className="tools">
                {showMachineManagement === false ? (
                  <PreviewUoMDropDown
                    onTogglePreviewUoM={this.togglePreviewUoM}
                    isOpenPreviewUoM={openPreviewUoM}
                  />
                ) : (
                  <div className="emptyDivPlaceholder" />
                )}
                <button
                  type="button"
                  className={`${
                    openLanguageSelector
                      ? 'dropdownSelection languageSelectionSelected'
                      : 'dropdownSelection'
                  }`}
                  onClick={() => {
                    this.openLanguageSelectorFunc()
                  }}
                >
                  <div>Language</div>
                  <span className="dropdown-label">
                    {languageToSave
                      ? languageToSave.toUpperCase()
                      : userLanguage.trim().toUpperCase()}
                  </span>
                  {openLanguageSelector ? (
                    <FontAwesomeIcon size="sm" icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon size="sm" icon={faCaretDown} />
                  )}
                </button>
                {openLanguageSelector && (
                  <div className="languageList">
                    <button
                      type="button"
                      className="dropDownButton"
                      onClick={() => {
                        this.changeLanguage('en')
                      }}
                    >
                      English <span className="abbreviation">(EN)</span>
                    </button>
                    <button
                      type="button"
                      className="dropDownButton"
                      onClick={() => {
                        this.changeLanguage('fi')
                      }}
                    >
                      Suomi <span className="abbreviation">(FI)</span>
                    </button>
                    <button
                      type="button"
                      className="dropDownButton"
                      onClick={() => {
                        this.changeLanguage('fr')
                      }}
                    >
                      Français <span className="abbreviation">(FR)</span>
                    </button>
                    <button
                      type="button"
                      className="dropDownButton"
                      onClick={() => {
                        this.changeLanguage('zh')
                      }}
                    >
                      简体中文 <span className="abbreviation">(ZH)</span>
                    </button>
                    <button
                      type="button"
                      className="dropDownButton"
                      onClick={() => {
                        this.changeLanguage('pl')
                      }}
                    >
                      Polski <span className="abbreviation">(PL)</span>
                    </button>
                  </div>
                )}
              </div>

              <div className="user">
                <div className="userBlock">
                  <div>{user.name}</div>
                  <div className="userGroup">{user.group}</div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={this.handleshowNewModal}
                    className="pointer"
                  >
                    <span>
                      <FontAwesomeIcon color="white" icon={faSignOutAlt} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {newModal}
        </div>
      </header>
    )
  }
}

Header.defaultProps = {
  userLanguage: null,
  languageToSave: null,
}

Header.propTypes = {
  userName: PropTypes.string.isRequired,
  userLevel: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  manageMachines: PropTypes.func.isRequired,
  switchLanguageToSave: PropTypes.func.isRequired,
  userLanguage: PropTypes.string,
  languageToSave: PropTypes.string,
  onSignOut: PropTypes.func.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  userName: state.AppUser.userName,
  userLevel: state.AppUser.userLevel,
  userLanguage: state.AppUser.userLanguage,
  languageToSave: state.MachineManagement.languageToSave,
  showMachineManagement: state.MachineManagement.showMachineManagement,
})
export default withTranslation(['header', 'common'])(
  connect(mapStateToProps, {
    manageMachines: manageMachinesAction,
    switchLanguageToSave,
    fetchUserRole,
  })(Header)
)
