import { connect } from 'react-redux'

import CoatingMachine from '../components'

import {
  fetchFactories,
  fetchMachines,
  fetchBlades,
  fetchBladeCoils,
  selectMachine,
  selectFactory,
} from '../coatingMachineAction'

const mapStateToProps = state => ({
  factoriesList: state.coatingMachine.factoriesList,
  machineList: state.coatingMachine.machineList,
  machineListWithUnarchived: state.coatingMachine.machineListWithUnarchived,
  blades: state.coatingMachine.blades,
  showLoading: state.coatingMachine.showLoading,
  showLoadingBlades: state.coatingMachine.showLoadingBlades,
  showMachineManagement: state.MachineManagement.showMachineManagement,
  selectedFactory: state.coatingMachine.selectedFactory,
  userLevel: state.AppUser.userLevel,
  userFactory: state.AppUser.userFactory,
  userMachine: state.AppUser.userMachine,
  selectedUserMachine: state.coatingMachine.selectedUserMachine,
  duplicates:
    state.coatingMachine.bladesWithCoils &&
    state.coatingMachine.bladesWithCoils.duplicates,
})

export default connect(
  mapStateToProps,
  {
    fetchFactories,
    fetchMachines,
    fetchBlades,
    fetchBladeCoils,
    selectMachine,
    selectFactory,
  }
)(CoatingMachine)
