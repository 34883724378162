/* eslint-disable consistent-return */
import { change } from 'redux-form'

import {
  setMachine,
  getFactories,
  getMachines,
  getBlades,
  getBladeWithCoils,
} from '../../Api'

export const FETCH_FACTORIES_START = 'FETCH_FACTORIES_START'
export const FETCH_FACTORIES_SUCCESS = 'FETCH_FACTORIES_SUCCESS'
export const FETCH_FACTORIES_FAILED = 'FETCH_FACTORIES_FAILED'

export const SELECT_MACHINE_START = 'SET_MACHINE_START'
export const SELECT_MACHINE_SUCCESS = 'SET_MACHINE_SUCCESS'
export const SELECT_MACHINE_FAILED = 'SET_MACHINE_FAILED'

export const SELECT_FACTORY = 'SELECT_FACTORY'

export const FETCH_MACHINE_START = 'FETCH_MACHINE_START'
export const FETCH_MACHINE_SUCCESS = 'FETCH_MACHINE_SUCCESS'
export const FETCH_MACHINE_FAILED = 'FETCH_MACHINE_FAILED'

export const SELECT_COATING_MACHINE = 'SELECT_COATING_MACHINE'

export const FETCH_BLADES_START = 'FETCH_BLADES_START'
export const FETCH_BLADES_SUCCESS = 'FETCH_BLADES_SUCCESS'
export const FETCH_BLADES_FAILED = 'FETCH_BLADES_FAILED'

export const FETCH_BLADECOIL_START = 'FETCH_BLADECOIL_START'
export const FETCH_BLADECOIL_SUCCESS = 'FETCH_BLADECOIL_SUCCESS'
export const FETCH_BLADECOIL_FAILED = 'FETCH_BLADECOIL_FAILED'

export const fetchFactories = () => async dispatch => {
  dispatch({
    type: FETCH_FACTORIES_START,
  })
  try {
    const results = await getFactories()
    dispatch({ type: FETCH_FACTORIES_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: FETCH_FACTORIES_FAILED, payload: error })
  }
}

export const fetchMachines = factoryId => async dispatch => {
  dispatch({
    type: FETCH_MACHINE_START,
  })
  try {
    const results = await getMachines(factoryId)
    dispatch({ type: FETCH_MACHINE_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: FETCH_MACHINE_FAILED, payload: error })
  }
}

export const fetchBlades = id => async (dispatch, getState) => {
  const appState = getState()
  const unitOfMeasurements =
    appState.coatingMachine.selectedUserMachine.unitOfMeasurement
  dispatch({
    type: FETCH_BLADES_START,
  })
  try {
    // As result we are getting 5 decimal coils width even with mm,
    // if mm machine is selected trim after decimal, or don't for inches
    const results = await getBlades(id)
    const trimedCoils =
      unitOfMeasurements === 0
        ? results.map(blade => {
            return {
              ...blade,
              coils: blade.coils.map(coil => coil.split('.')[0]),
            }
          })
        : results

    return dispatch({ type: FETCH_BLADES_SUCCESS, payload: trimedCoils })
  } catch (error) {
    return dispatch({ type: FETCH_BLADES_FAILED, payload: error })
  }
}

export const fetchBladeCoils = id => async dispatch => {
  dispatch({
    type: FETCH_BLADECOIL_START,
    showLoading: false,
  })
  try {
    const content = []
    const results = await getBladeWithCoils(id)
    results.coilWithStripes.forEach(element => {
      element.stripes = element.coilStripes.map(stripe => stripe.width)
      content.push(element)
    })

    results.coilWithStripes = content
    return dispatch({ type: FETCH_BLADECOIL_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: FETCH_BLADECOIL_FAILED, payload: error })
  }
}

export const selectMachine = machine => async dispatch => {
  dispatch({
    type: SELECT_MACHINE_START,
  })
  try {
    await setMachine(machine.recid)
    // Change field in previewUoM form to match UoM from machine, this will be initial value for that form as well
    dispatch(
      change(
        'previewUoMDropDown',
        'previewUoMDropDown.unitOfMeasurement',
        machine.unitOfMeasurement
      )
    )
    dispatch({ type: SELECT_MACHINE_SUCCESS, payload: machine })
  } catch (error) {
    return dispatch({ type: SELECT_MACHINE_FAILED, payload: error })
  }
}

export const selectFactory = factory => {
  return {
    type: SELECT_FACTORY,
    payload: factory,
  }
}
