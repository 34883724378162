import { getUserRole } from './Api'

export const GET_USEROLE_START = 'GET_USEROLE_START'
export const GET_USEROLE_SUCCESS = 'GET_USEROLE_SUCCESS'
export const GET_USEROLE_FAILED = 'GET_USEROLE_FAILED'

export const DISPATCH_USER_ACCESS_SUCCESS = 'DISPATCH_USER_ACCESS_SUCCESS'
// TODO for start and failed

export const fetchUserRole = () => async dispatch => {
  dispatch({
    type: GET_USEROLE_START,
  })
  try {
    const results = await getUserRole()
    return dispatch({ type: GET_USEROLE_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: GET_USEROLE_FAILED, payload: error })
  }
}

export const dispatchUserAccess = value => dispatch => {
  dispatch({
    type: DISPATCH_USER_ACCESS_SUCCESS,
    payload: value,
  })
}
