import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { withRouter } from 'react-router-dom'
import { ai } from './TelemetryService'

// Different instrumentation keys for different environments
const hostname = window && window.location && window.location.hostname
let instrumentationKey = '412ddebf-481b-45d4-8ec1-b55119a83e4c' // DEV environment

if (
  hostname === 'labelpga-qa-front.azurewebsites.net' ||
  hostname === 'pga-qa.upmraflatac.com'
) {
  instrumentationKey = '9c0084e3-483a-4ea6-b4b4-82825af8e9a5'
} else if (
  hostname === 'labelpga-prod-front.azurewebsites.net' ||
  hostname === 'pga.upmraflatac.com'
) {
  instrumentationKey = 'a8c5fcd3-8a61-4dbc-857d-f59b9e1fa6bb'
}

class TelemetryProvider extends Component {
  state = {
    initialized: false,
  }

  componentDidMount() {
    const { history } = this.props
    const { initialized } = this.state
    const AppInsightsInstrumentationKey = instrumentationKey
    if (
      !initialized &&
      Boolean(AppInsightsInstrumentationKey) &&
      Boolean(history)
    ) {
      ai.initialize(AppInsightsInstrumentationKey, history)
      this.setState({ initialized: true })
    }

    const { after } = this.props
    after()
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props
    return <>{children}</>
  }
}

TelemetryProvider.defaultProps = {
  after: undefined,
}

TelemetryProvider.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  after: PropTypes.func,
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider))
