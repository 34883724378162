import {
  INITIAL_BLADE_FORM_SUCCESS,
  FETCH_EANCODES_START,
  FETCH_EANCODES_SUCCESS,
  FETCH_EANCODES_FAIL,
} from './BladeTableAction'

import { CLEAR_DATA_TRUE } from '../../../machineManagmentForm/MachineManagementFormAction'

const initialState = {
  blade: null,
  showLoading: false,
  eanCodes: null,
  error: null,
  formValue: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_BLADE_FORM_SUCCESS:
      return {
        ...state,
        formValue: action.data,
      }
    case CLEAR_DATA_TRUE:
      return {
        ...state,
        formValue: null,
      }
    case FETCH_EANCODES_START:
      return {
        ...state,
        showLoading: true,
      }
    case FETCH_EANCODES_SUCCESS:
      return {
        ...state,
        eanCodes: action.payload,
        showLoading: false,
      }
    case FETCH_EANCODES_FAIL:
      return {
        ...state,
        showLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
