/* eslint-disable consistent-return */
import {
  getCustomers,
  updateData,
  saveData,
  archiveBlade,
  restoreBlade,
  addFiles,
  getFile,
  removeFile,
} from '../../Api'
import {
  fetchBlades,
  fetchBladeCoils,
} from '../coatingMachine/coatingMachineAction'

export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START'
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS'
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED'

export const CLEAR_DATA_TRUE = 'CLEAR_DATA_TRUE'
export const CLEAR_DATA_FALSE = 'CLEAR_DATA_FALSE'

export const UPDATE_BLADE_DATA_START = 'UPDATE_BLADE_DATA_START'
export const UPDATE_BLADE_DATA_SUCCESS = 'UPDATE_BLADE_DATA_SUCCESS'
export const UPDATE_BLADE_DATA_FAIL = 'UPDATE_BLADE_DATA_FAIL'

export const SAVE_BLADE_DATA_START = 'SAVE_BLADE_DATA_START'
export const SAVE_BLADE_DATA_SUCCESS = 'SAVE_BLADE_DATA_SUCCESS'
export const SAVE_BLADE_DATA_FAIL = 'SAVE_BLADE_DATA_FAIL'

export const ARCHIVE_BLADE_START = 'ARCHIVE_BLADE_START'
export const ARCHIVE_BLADE_SUCCESS = 'ARCHIVE_BLADE_SUCCESS'
export const ARCHIVE_BLADE_FAIL = 'ARCHIVE_BLADE_FAIL'

export const RESTORE_BLADE_START = 'RESTORE_BLADE_START'
export const RESTORE_BLADE_SUCCESS = 'RESTORE_BLADE_SUCCESS'
export const RESTORE_BLADE_FAIL = 'RESTORE_BLADE_FAIL'

export const CHANGES_STRIPES = 'CHANGES_STRIPES'

export const SAVE_FILE_START = 'SAVE_FILE_START'
export const SAVE_FILE_SUCCESS = 'SAVE_FILE_SUCCESS'
export const SAVE_FILE_FAIL = 'SAVE_FILE_FAIL'

export const GET_FILE_START = 'GET_FILE_START'
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS'
export const GET_FILE_FAIL = 'GET_FILE_FAIL'

export const REMOVE_FILE_START = 'REMOVE_FILE_START'
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS'
export const REMOVE_FILE_FAIL = 'REMOVE_FILE_FAIL'

export const IS_INSTRUCTIONS_MODAL_OPEN = 'IS_INSTRUCTIONS_MODAL_OPEN'

export const changeStripes = array => dispatch => {
  dispatch({
    type: CHANGES_STRIPES,
    array,
  })
}

export const fetchCustomers = () => async dispatch => {
  dispatch({
    type: FETCH_CUSTOMERS_START,
  })
  try {
    const results = await getCustomers()
    return dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: results })
  } catch (error) {
    return dispatch({ type: FETCH_CUSTOMERS_FAILED, payload: error })
  }
}

export const clearDataTrue = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_TRUE,
  })
}

export const clearDataFalse = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_FALSE,
  })
}

export const updateBladeData = (bladeId, data) => async dispatch => {
  dispatch({
    type: UPDATE_BLADE_DATA_START,
  })

  try {
    const res = await updateData(bladeId, data)
    const bladeToUpdate = res.blade
    bladeToUpdate.customers = res.customers
    const machineId = res.blade.machineid
    dispatch(fetchBlades(machineId))
    dispatch(fetchBladeCoils(bladeId))
    dispatch({
      type: UPDATE_BLADE_DATA_SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_BLADE_DATA_FAIL,
      payload: error,
    })
  }
}

export const saveBladeData = data => async dispatch => {
  dispatch({
    type: SAVE_BLADE_DATA_START,
  })

  try {
    const res = await saveData(data)
    const machineId = res.machineid
    dispatch(fetchBlades(machineId))
    dispatch(clearDataFalse())
    dispatch(fetchBladeCoils(res.skid))
    dispatch({
      type: SAVE_BLADE_DATA_SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: SAVE_BLADE_DATA_FAIL,
      payload: error,
    })
  }
}

export const archiveSelectedBlade = id => async dispatch => {
  dispatch({
    type: ARCHIVE_BLADE_START,
  })
  try {
    const results = await archiveBlade(id)
    const machineId = results.blade.machineid
    dispatch({ type: ARCHIVE_BLADE_SUCCESS })
    dispatch(fetchBlades(machineId))
    dispatch(fetchBladeCoils(id))
    // dispatch(updateBlade(results.blade))
  } catch (error) {
    return dispatch({ type: ARCHIVE_BLADE_FAIL, payload: error })
  }
}

export const restoreSelectedBlade = id => async dispatch => {
  dispatch({
    type: RESTORE_BLADE_START,
  })
  try {
    const results = await restoreBlade(id)
    const machineId = results.blade.machineid
    dispatch({ type: RESTORE_BLADE_SUCCESS })
    dispatch(fetchBlades(machineId))
    dispatch(fetchBladeCoils(id))
  } catch (error) {
    return dispatch({ type: RESTORE_BLADE_FAIL, payload: error })
  }
}

export const saveFileToServer = (
  fileContents,
  fileName,
  bladeId
) => async dispatch => {
  dispatch({
    type: SAVE_FILE_START,
  })
  try {
    const values = {
      id: 0,
      skid: bladeId,
      fileName,
      fileContents,
      timeStamp: '2019-10-11T07:06:41.364Z', // random timestamp, this will be set up on backend
    }
    const result = await addFiles(bladeId, values)
    dispatch(fetchBladeCoils(bladeId))
    dispatch({
      type: SAVE_FILE_SUCCESS,
      payload: result,
    })
  } catch (error) {
    return dispatch({ type: SAVE_FILE_FAIL, payload: error })
  }
}

export const getFileAction = id => async dispatch => {
  dispatch({
    type: GET_FILE_START,
  })
  try {
    const result = await getFile(id)
    dispatch({
      type: GET_FILE_SUCCESS,
    })
    return result
  } catch (error) {
    return dispatch({ type: GET_FILE_FAIL, payload: error })
  }
}

export const removeFileAction = (bladeId, fileId) => async dispatch => {
  dispatch({
    type: REMOVE_FILE_START,
  })
  try {
    const result = await removeFile(bladeId, fileId)
    dispatch(fetchBladeCoils(bladeId))
    dispatch({
      type: REMOVE_FILE_SUCCESS,
    })
    dispatch({
      type: SAVE_FILE_SUCCESS,
      payload: result,
    })
  } catch (error) {
    return dispatch({ type: REMOVE_FILE_FAIL, payload: error })
  }
}

export const isInstructionsModalOpen = value => dispatch => {
  dispatch({
    type: IS_INSTRUCTIONS_MODAL_OPEN,
    payload: value,
  })
}
