import { reset } from 'redux-form'
/* eslint-disable consistent-return */
import {
  archiveMachine,
  restoreMachine,
  createMachine,
  updateMachine,
  removeMachineCoatingLayers,
  updateMaterialsPost,
} from '../../Api'
import { updateBlade } from '../coatingMachine/components/bladeTable/BladeTableAction'
import { fetchMachines } from '../coatingMachine/coatingMachineAction'

export const CLEAR_DATA_TRUE = 'CLEAR_DATA_TRUE'
export const CLEAR_DATA_FALSE = 'CLEAR_DATA_FALSE'

export const ARCHIVE_MACHINE_START = 'ARCHIVE_MACHINE_START'
export const ARCHIVE_MACHINE_SUCCESS = 'ARCHIVE_MACHINE_SUCCESS'
export const ARCHIVE_MACHINE_FAIL = 'ARCHIVE_MACHINE_FAIL'

export const RESTORE_MACHINE_START = 'RESTORE_MACHINE_START'
export const RESTORE_MACHINE_SUCCESS = 'RESTORE_MACHINE_SUCCESS'
export const RESTORE_MACHINE_FAIL = 'RESTORE_MACHINE_FAIL'

export const CREATE_MACHINE_START = 'CREATE_MACHINE_START'
export const CREATE_MACHINE_FAIL = 'CREATE_MACHINE_FAIL'
export const CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS'

export const UPDATE_MACHINE_START = 'UPDATE_MACHINE_START'
export const UPDATE_MACHINE_FAIL = 'UPDATE_MACHINE_FAIL'
export const UPDATE_MACHINE_SUCCESS = 'UPDATE_MACHINE_SUCCESS'

export const REMOVE_MACHINE_LAYERS_START = 'REMOVE_MACHINE_LAYERS_START'
export const REMOVE_MACHINE_LAYERS_SUCCESS = 'REMOVE_MACHINE_LAYERS_SUCCESS'
export const REMOVE_MACHINE_LAYERS_FAIL = 'REMOVE_MACHINE_LAYERS_FAIL'

export const UPDATE_MATERIALS_START = 'UPDATE_MATERIALS_START'
export const UPDATE_MATERIALS_SUCCESS = 'UPDATE_MATERIALS_SUCCESS'
export const UPDATE_MATERIALS_FAIL = 'UPDATE_MATERIALS_FAIL'

export const clearDataTrue = () => dispatch => {
  dispatch(reset('MachineManagementForm'))

  dispatch({
    type: CLEAR_DATA_TRUE,
  })
}

export const clearDataFalse = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_FALSE,
  })
}
export const archiveSelectedMachine = (id, factoryId) => async dispatch => {
  dispatch({
    type: ARCHIVE_MACHINE_START,
  })
  try {
    const results = await archiveMachine(id)
    dispatch({ type: ARCHIVE_MACHINE_SUCCESS, payload: results })
    dispatch(fetchMachines(factoryId))
    dispatch(updateBlade(results))
  } catch (error) {
    return dispatch({ type: ARCHIVE_MACHINE_FAIL })
  }
}

export const restoreSelectedMachine = (id, factoryId) => async dispatch => {
  dispatch({
    type: RESTORE_MACHINE_START,
  })
  try {
    const results = await restoreMachine(id)
    dispatch({ type: RESTORE_MACHINE_SUCCESS })
    dispatch(fetchMachines(factoryId))
    dispatch(updateBlade(results))
  } catch (error) {
    return dispatch({ type: RESTORE_MACHINE_FAIL })
  }
}

export const createNewMachine = (
  values,
  factoryId,
  machineMaterials
) => async dispatch => {
  dispatch({
    type: CREATE_MACHINE_START,
  })
  values.machineMaterials = []
  try {
    const machineObj = await createMachine(values)
    if (machineObj) {
      await dispatch(updateMaterialsAction(machineObj.recid, machineMaterials))
      dispatch({ type: CREATE_MACHINE_SUCCESS, payload: machineObj.recid })
      await dispatch(fetchMachines(factoryId))
      await dispatch(updateBlade(machineObj))
    }
  } catch (error) {
    return dispatch({ type: CREATE_MACHINE_FAIL })
  }
}
export const removeMachineLayers = values => async dispatch => {
  dispatch({
    type: REMOVE_MACHINE_LAYERS_START,
  })
  try {
    await removeMachineCoatingLayers(values)
    return dispatch({
      type: REMOVE_MACHINE_LAYERS_SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: REMOVE_MACHINE_LAYERS_FAIL,
      payload: error,
    })
  }
}

export const updateMaterialsAction = (id, values) => async dispatch => {
  dispatch({
    type: UPDATE_MATERIALS_START,
  })
  const materialsArray = []
  values.forEach(element => {
    if (!materialsArray.includes(element)) {
      materialsArray.push(element)
    }
  })
  try {
    await updateMaterialsPost(id, materialsArray)
    return dispatch({
      type: UPDATE_MATERIALS_SUCCESS,
      payload: id,
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_MATERIALS_FAIL,
      payload: error,
    })
  }
}
export const updateMachineData = (
  id,
  values,
  factoryId,
  coatingLayersRemoving,
  machineMaterials
) => async dispatch => {
  dispatch({
    type: UPDATE_MACHINE_START,
  })

  values.machineMaterials = []
  if (values.netWidth === '') {
    values.netWidth = null
  }
  try {
    const result = await updateMachine(id, values)
    dispatch(removeMachineLayers(coatingLayersRemoving))
    dispatch(updateMaterialsAction(id, machineMaterials))
    dispatch({ type: UPDATE_MACHINE_SUCCESS, payload: result })
    dispatch(fetchMachines(factoryId))
  } catch (error) {
    return dispatch({ type: UPDATE_MACHINE_FAIL })
  }
}
