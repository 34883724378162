import { connect } from 'react-redux'
import { getFormValues, formValueSelector } from 'redux-form'
import BladeVisualization from '../components'
import { changeStripes as changeStripesAction } from '../../newBladeForm/newBladeFormAction'

// const formatDate = str => new Date(str).toLocaleDateString('fi-FI');
const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  coilData: getFormValues('coilForm')(state),
  netWidth:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.netWidth,
  machineUoM:
    state.coatingMachine.selectedUserMachine &&
    state.coatingMachine.selectedUserMachine.unitOfMeasurement,
  uomPreview: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
  isModalOpen:
    state.newBladeFormReducer && state.newBladeFormReducer.isModalOpen,
})
export default connect(mapStateToProps, { changeStripes: changeStripesAction })(
  BladeVisualization
)
