import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { formValueSelector } from 'redux-form'

const ModalTable = ({ historyLogs, t, previewUoM }) => {
  historyLogs
    .sort((a, b) => Date.parse(a.changeTime) - Date.parse(b.changeTime))
    .reverse()
  const localTimeFunc = time => {
    let convertedTime
    const localTime = moment.utc(time).toDate()
    const local = moment(localTime).local()

    if (previewUoM === 1) {
      convertedTime = local.format('MM.DD.YYYY HH:mm')
    } else {
      convertedTime = local.format('DD.MM.YYYY HH:mm')
    }

    return convertedTime
  }
  return (
    <div className="modalTable">
      <table>
        <thead>
          <tr>
            <th>{t('user')}</th>
            <th>{t('date_and_time')}</th>
            <th>{t('changed_item')}</th>
          </tr>
        </thead>
        <tbody>
          {historyLogs &&
            historyLogs.map(log => (
              <tr key={log.id}>
                <td>{log.userName}</td>
                <td>{localTimeFunc(log.changeTime)}</td>
                <td>
                  <Link to={`?history=${log.id}`} target="_blank">
                    {log.changeItem &&
                      log.changeItem.map((item, index) => {
                        let term = ''
                        switch (item) {
                          case 'Blade':
                            term = t('common:blade')
                            break
                          case 'EanCodes':
                            term = t('common:eanCodes')
                            break
                          case 'CoatingLayers':
                            term = t('bladeForm:coatingLayers')
                            break
                          case 'Coils':
                            term = t('common:coil_plural')
                            break
                          case 'Customers':
                            term = t('common:customer_plural')
                            break
                          case 'FileAdded':
                            term = t('changedItemHistory:file_added')
                            break
                          case 'FileRemoved':
                            term = t('changedItemHistory:file_removed')
                            break
                          case 'BladeCreated':
                            term = t('changedItemHistory:blade_created')
                            break
                          case 'Archived':
                            term = t('changedItemHistory:archived')
                            break
                          case 'UnArchived':
                            term = t('changedItemHistory:unarchived')
                            break

                          default:
                            term = ''
                            break
                        }
                        return (
                          <strong key={item}>{`${
                            index ? ',' : ''
                          } ${term}`}</strong>
                        )
                      })}
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const uomPreviewSelector = formValueSelector('previewUoMDropDown')

const mapStateToProps = state => ({
  historyLogs:
    state.coatingMachine && state.coatingMachine.bladesWithCoils.changeLogs,
  previewUoM: uomPreviewSelector(state, 'previewUoMDropDown.unitOfMeasurement'),
})

ModalTable.defaultProps = {
  previewUoM: null,
}

ModalTable.propTypes = {
  historyLogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      skid: PropTypes.number,
      userName: PropTypes.string,
      changeTime: PropTypes.string,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
  previewUoM: PropTypes.number,
}

export default withTranslation([
  'historyView',
  'changedItemHistory',
  'common',
  'bladeForm',
])(connect(mapStateToProps, null)(ModalTable))
