import React from 'react'
import PropTypes from 'prop-types'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CheckboxNoReduxForm = props => {
  const { disabled, label, onChange, name, checked } = props
  return (
    <div className="custom-checkboxContainer">
      <input
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="checkbox"
      />
      <span className="custom-checkbox">
        {checked && (
          <FontAwesomeIcon
            icon={faCheck}
            size="xs"
            style={{ color: '#ffffff', padding: '0.1rem' }}
          />
        )}
      </span>
      <span className="custom-checkboxText">{label}</span>
    </div>
  )
}

CheckboxNoReduxForm.defaultProps = {
  checked: false,
  name: '',
  onChange: null,
  input: null,
  label: '',
  disabled: false,
}

CheckboxNoReduxForm.propTypes = {
  input: PropTypes.shape({}),
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export default CheckboxNoReduxForm
