/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Filters from './bladeTableFilters/BladeTableFilters'
import BladeTable from './bladeTable/BladeTable'
import DropDownOptions from './bladeTableDropdownOptions/BladeTableDropdownOptions'
import DuplicateDetection from '../../duplicateDetection/DuplicateDetection'
import Spinner from '../../common/components/spinner/Spinner'
import AppWideSpinner from '../../common/components/spinner/appWideSpinner/AppWideSpinner'

class CoatingMachine extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textFilterVal: '',
      archivedFilterVal: false,
      bladesWithRole: [],
      duplicateDetection: true,
    }
  }

  componentDidMount() {
    const { fetchFactories, showMachineManagement } = this.props

    if (showMachineManagement) {
      return null
    }
    fetchFactories()
  }

  componentWillUpdate(nextProps) {
    const { showLoadingBlades, blades } = this.props
    if (showLoadingBlades) {
      if (blades !== nextProps.blades) {
        this.filterBladesWithRole()
        this.filterMachines()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      factoriesList,
      selectedFactory,
      selectMachine,
      selectFactory,
      fetchMachines,
      machineListWithUnarchived,
      fetchBlades,
      selectedUserMachine,
      showMachineManagement,
      blades,
      userFactory,
      userMachine,
      showLoading,
    } = this.props
    const { textFilterVal, archivedFilterVal } = this.state

    if (prevProps.factoriesList !== factoriesList) {
      userFactory === -1
        ? selectFactory(factoriesList[0])
        : selectFactory(factoriesList.find(fac => fac.id === userFactory))
    }
    if (
      prevProps.machineListWithUnarchived !== machineListWithUnarchived &&
      showMachineManagement === false
    ) {
      const machine = machineListWithUnarchived.find(
        mac => selectedUserMachine && mac.recid === selectedUserMachine.recid
      )

      const defaultMachine = machineListWithUnarchived.find(
        mac => userMachine && mac.recid === userMachine
      )
      if (machine) {
        selectMachine(machine)
      } else {
        defaultMachine
          ? selectMachine(defaultMachine)
          : machineListWithUnarchived.length > 0 &&
            selectMachine(machineListWithUnarchived[0])
      }
    }

    if (prevProps.selectedFactory !== selectedFactory) {
      fetchMachines(selectedFactory.id)
    }
    if (
      prevProps.selectedUserMachine !== selectedUserMachine &&
      showMachineManagement === false
    ) {
      selectedUserMachine
        ? fetchBlades(selectedUserMachine.recid)
        : fetchBlades(0)
    }
    if (
      prevProps.blades !== blades ||
      prevState.textFilterVal !== textFilterVal ||
      prevState.archivedFilterVal !== archivedFilterVal
    ) {
      this.filterBladesWithRole()
    }

    if (prevProps.showLoading !== showLoading) {
      this.openDuplicateDetection()
    }
  }

  archivedFilterFunc = val => {
    this.setState({
      archivedFilterVal: val,
    })
  }

  textFilterFunc = val => {
    this.setState({
      textFilterVal: val,
    })
  }

  selectFactoryToLoad = e => {
    const { factoriesList, selectFactory } = this.props
    const option = factoriesList.find(
      element => element.id === Number(e.target.value)
    )
    selectFactory(option)
  }

  selectCoatingMachine = e => {
    const { machineListWithUnarchived, selectMachine } = this.props
    const option = machineListWithUnarchived.find(
      element => element.recid === Number(e.target.value)
    )
    selectMachine(option)
  }

  filterBladesWithRole = () => {
    const { textFilterVal, archivedFilterVal } = this.state
    const { userLevel, blades } = this.props

    // Hide archived blades from operator
    const roleFilterBlades =
      userLevel === 1 ? blades.filter(blade => !blade.archived) : blades

    // Filter blades from archived checkbox
    const archivedFilterWithRoleBlades = archivedFilterVal
      ? roleFilterBlades
      : roleFilterBlades.filter(blade => !blade.archived)

    const matchingBladeNumber = []
    const matchingItems = []
    const exactMatch = (compareTo, value) => {
      const result = compareTo.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      const startsWithRegExp = new RegExp(`^${result}$`)
      return startsWithRegExp.test(value)
    }

    const eanMatch = (compareTo, value) => {
      const result = compareTo.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      const startsWithRegExp = new RegExp(`^${result}|${result}$`)
      return startsWithRegExp.test(value)
    }

    const letterStartingMatch = value => {
      const result = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      const startsWithRegExp = new RegExp(/^[A-Za-z]+/, 'i')
      return startsWithRegExp.test(result)
    }

    if (textFilterVal.length > 0) {
      const customerMatchRegex = letterStartingMatch(textFilterVal)
      if (customerMatchRegex) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < archivedFilterWithRoleBlades.length; j++) {
          const customerMatchVal =
            archivedFilterWithRoleBlades[j].customers.length > 0 &&
            archivedFilterWithRoleBlades[j].customers.find(customer =>
              eanMatch(textFilterVal.toLowerCase(), customer.toLowerCase())
            )
          // eslint-disable-next-line no-unused-expressions
          customerMatchVal &&
            matchingItems.push(archivedFilterWithRoleBlades[j])
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < archivedFilterWithRoleBlades.length; j++) {
          const exactMatchVal = exactMatch(
            textFilterVal,
            archivedFilterWithRoleBlades[j].sknro.toString()
          )
          if (exactMatchVal) {
            matchingBladeNumber.push(archivedFilterWithRoleBlades[j])
            break
          }
        }
      }

      // eslint-disable-next-line no-plusplus
      if (matchingBladeNumber.length !== 1) {
        let i = 0
        while (i < archivedFilterWithRoleBlades.length) {
          const eanMatchVal =
            archivedFilterWithRoleBlades[i].eanCodes.length > 0 &&
            archivedFilterWithRoleBlades[i].eanCodes.find(ean =>
              eanMatch(textFilterVal, ean.toString())
            )

          if (eanMatchVal) {
            matchingItems.push(archivedFilterWithRoleBlades[i])
          }
          i += 1
          // eslint-disable-next-line no-plusplus
        }
      }
      this.setState({
        bladesWithRole:
          matchingBladeNumber.length === 1
            ? matchingBladeNumber
            : matchingItems,
      })
    } else {
      this.setState({ bladesWithRole: archivedFilterWithRoleBlades })
    }
  }

  filterMachines = () => {
    const { machineList } = this.props
    const { textFilterVal } = this.state
    if (machineList) {
      return machineList.filter(machine => {
        return (
          machine.recid.toString().includes(textFilterVal) ||
          (machine.name &&
            machine.name.toLowerCase().includes(textFilterVal.toLowerCase()))
        )
      })
    }
  }

  closeDuplicateDetection = () => {
    this.setState({ duplicateDetection: false })
  }

  openDuplicateDetection = () => {
    this.setState({ duplicateDetection: true })
  }

  render() {
    const {
      t,
      showLoadingBlades,
      showLoading,
      machineListWithUnarchived,
      fetchBladeCoils,
      showMachineManagement,
      userLevel,
      duplicates,
    } = this.props
    const {
      archivedFilterVal,
      textFilterVal,
      bladesWithRole,
      duplicateDetection,
    } = this.state

    const loadingStripes = showLoading ? <AppWideSpinner /> : null
    return (
      <div className="blade-list">
        <DropDownOptions
          selectFactoryToLoad={this.selectFactoryToLoad}
          selectCoatingMachine={this.selectCoatingMachine}
          coatingmachines={machineListWithUnarchived}
        />

        <div className="blade-container">
          <div className="blade-container__header">
            <div className="title-container">
              <h2 className="header-title">
                <span>
                  {showMachineManagement ? t('machines') : t('blade_plural')}
                </span>
              </h2>
            </div>
            <Filters
              archivedFilterVal={archivedFilterVal}
              archivedFilterFunc={this.archivedFilterFunc}
              textFilterVal={textFilterVal}
              textFilterFunc={this.textFilterFunc}
              userLevel={userLevel}
            >
              <p style={{ margin: 0 }}>
                {showMachineManagement
                  ? this.filterMachines().length
                  : bladesWithRole && bladesWithRole.length}{' '}
                {t('bladeList:found')}
              </p>
            </Filters>
          </div>
          {showLoadingBlades ? (
            <Spinner />
          ) : (
            <>
              <BladeTable
                filterBladesWithRole={bladesWithRole}
                filterMachines={this.filterMachines()} // this.filterMachines()
                filterText={textFilterVal}
                fetchBladeCoils={fetchBladeCoils}
              />

              {duplicateDetection &&
                duplicates &&
                duplicates.length > 0 &&
                userLevel > 1 && (
                  <DuplicateDetection
                    closeDuplicateDetection={this.closeDuplicateDetection}
                  />
                )}
            </>
          )}
          {loadingStripes}
        </div>
      </div>
    )
  }
}
CoatingMachine.defaultProps = {
  blades: [],
  machineList: [],
  machineListWithUnarchived: [],
  selectMachine: null,
  selectFactory: null,
  selectedFactory: null,
  selectedUserMachine: null,
  factoriesList: null,
  duplicates: null,
}

CoatingMachine.propTypes = {
  blades: PropTypes.arrayOf(
    PropTypes.shape({
      sknro: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  machineList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  machineListWithUnarchived: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  fetchBlades: PropTypes.func.isRequired,
  fetchFactories: PropTypes.func.isRequired,
  fetchMachines: PropTypes.func.isRequired,
  fetchBladeCoils: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showLoadingBlades: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
  showMachineManagement: PropTypes.bool.isRequired,
  selectMachine: PropTypes.func,
  selectFactory: PropTypes.func,
  selectedFactory: PropTypes.object,
  userLevel: PropTypes.number.isRequired,
  userFactory: PropTypes.number.isRequired,
  userMachine: PropTypes.number.isRequired,
  selectedUserMachine: PropTypes.object,
  factoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      country: PropTypes.string,
      city: PropTypes.string,
      siteType: PropTypes.string,
      factorySiteCode: PropTypes.string,
      plantCodeInSAP: PropTypes.string,
    })
  ),
  duplicates: PropTypes.arrayOf(PropTypes.number),
}

export default withTranslation(['common', 'bladeList'])(CoatingMachine)
