import { TOTAL_WIDTH_SLITTING_START } from './bladeDrawingAction'

const initialState = {
  totalWidthWithSlitting: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_WIDTH_SLITTING_START:
      return {
        ...state,
        totalWidthWithSlitting: action.payload,
      }
    default:
      return state
  }
}
