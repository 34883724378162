import { toast } from 'react-toastify'
import {
  CLEAR_DATA_TRUE,
  CLEAR_DATA_FALSE,
  ARCHIVE_MACHINE_START,
  ARCHIVE_MACHINE_SUCCESS,
  ARCHIVE_MACHINE_FAIL,
  RESTORE_MACHINE_START,
  RESTORE_MACHINE_SUCCESS,
  RESTORE_MACHINE_FAIL,
  CREATE_MACHINE_START,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_FAIL,
  UPDATE_MACHINE_START,
  UPDATE_MACHINE_SUCCESS,
  UPDATE_MACHINE_FAIL,
  UPDATE_MATERIALS_START,
  UPDATE_MATERIALS_SUCCESS,
  UPDATE_MATERIALS_FAIL,
} from './MachineManagementFormAction'
import { INITIAL_BLADE_FORM_SUCCESS } from '../coatingMachine/components/bladeTable/BladeTableAction'
import i18n from './../../i18n'

const initialState = {
  showLoading: false,
  showError: false,
  clearData: false,
  machineNmbrRedu: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MACHINE_START:
    case UPDATE_MACHINE_START:
    case ARCHIVE_MACHINE_START:
    case RESTORE_MACHINE_START:
    case UPDATE_MATERIALS_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case CREATE_MACHINE_SUCCESS:
      toast.success(i18n.t('toaster:create_machine_success'))
      return {
        ...state,
        showLoading: false,
        machineNmbrRedu: action.payload,
      }
    case UPDATE_MATERIALS_SUCCESS:
      return {
        ...state,
        showLoading: false,
        machineNmbrRedu: action.payload,
      }
    case UPDATE_MACHINE_SUCCESS:
      toast.success(i18n.t('toaster:update_machine_success'))
      return {
        ...state,
        machineNmbrRedu: action.payload.recid,
        showLoading: false,
      }
    case ARCHIVE_MACHINE_SUCCESS:
      toast.success(i18n.t('toaster:archive_machine_success'))
      return {
        ...state,
        showLoading: false,
      }
    case RESTORE_MACHINE_SUCCESS:
      toast.success(i18n.t('toaster:restore_machine_success'))
      return {
        ...state,
        showLoading: false,
      }
    case CREATE_MACHINE_FAIL:
    case UPDATE_MACHINE_FAIL:
    case RESTORE_MACHINE_FAIL:
    case ARCHIVE_MACHINE_FAIL:
    case UPDATE_MATERIALS_FAIL:
      toast.warn(i18n.t('toaster:error_toaster'))
      return {
        ...state,
        showLoading: false,
      }
    case INITIAL_BLADE_FORM_SUCCESS:
      return {
        ...state,
        showLoading: false,
      }
    case CLEAR_DATA_TRUE:
      return {
        ...state,
        clearData: true,
        machineNmbrRedu: 0,
      }
    case CLEAR_DATA_FALSE:
      return {
        ...state,
        clearData: false,
      }

    default:
      return state
  }
}
