import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable jsx-a11y/label-has-for */
const RadioButtonField = ({
  input,
  disabled,
  className,
  type = 'radio',
  label,
  right,
  meta: { error, touched },
}) => {
  return (
    <div className="radio">
      <input
        {...input}
        type={type}
        name={input.name}
        className={className}
        id={input.id}
        checked={className && 'checked'}
        disabled={disabled}
      />
      <span className="radio-fill" />
      <div className={right ? 'label radioLabelRight' : 'label radioLabelLeft'}>
        {label}
      </div>
      <div className="red-text">{touched && error}</div>
    </div>
  )
}

RadioButtonField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node, PropTypes.string),
    ]),
    active: PropTypes.bool,
  }),
}

RadioButtonField.defaultProps = {
  meta: {
    touched: false,
    error: '',
    active: false,
  },
  className: '',
  disabled: false,
}
export default RadioButtonField
