import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable jsx-a11y/label-has-for */
const RenderCoilField = ({
  input,
  readOnlyValue,
  type,
  label,
  disabled,
  meta: { error, touched },
}) => {
  return (
    <div>
      <input
        {...input}
        type={type}
        name={input.name}
        id={input.name}
        readOnly={readOnlyValue}
        disabled={disabled}
        onPaste={e => e.preventDefault()}
      />
      <div className="coilFieldLabel">
        <label htmlFor={input.name}>{label}</label>
      </div>
      <div className="red-text">{touched && error}</div>
    </div>
  )
}

RenderCoilField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node, PropTypes.string),
    ]),
    active: PropTypes.bool,
  }),
  readOnlyValue: PropTypes.bool,
  disabled: PropTypes.bool,
}

RenderCoilField.defaultProps = {
  meta: {
    touched: false,
    error: '',
    active: false,
  },
  readOnlyValue: false,
  disabled: false,
}
export default RenderCoilField
