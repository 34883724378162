import {
  MANAGE_MACHINE_VIEW,
  SWITCH_LANGUAGE_START,
  SWITCH_LANGUAGE_SUCCESS,
  SWITCH_LANGUAGE_FAILED,
} from './HeaderAction'

const initialState = {
  showMachineManagement: false,
  languageToSave: null,
  showLoading: false,
  showError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_MACHINE_VIEW:
      return {
        ...state,
        showMachineManagement: action.payload === 'machineManagement' && true,
      }
    case SWITCH_LANGUAGE_START:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case SWITCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageToSave: action.payload.language,
      }
    case SWITCH_LANGUAGE_FAILED:
      return {
        ...state,
        showLoading: false,
        showError: action.payload,
      }
    default:
      return state
  }
}
