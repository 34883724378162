import React from 'react'
import PropTypes from 'prop-types'

const ButtonWrapper = ({ children }) => {
  return <div className="buttonWrapper">{children}</div>
}

ButtonWrapper.propTypes = {
  children: PropTypes.node,
}

ButtonWrapper.defaultProps = {
  children: null,
}
export default ButtonWrapper
